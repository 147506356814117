import {
  IonContent,
  IonSelect,
  IonItem,
  IonSelectOption,
  IonPage,
  IonButton,
  IonItemDivider,
  IonRow,
  IonCol,
  IonInput,
  IonAlert,
  IonLabel,
  useIonRouter,
  IonLoading,
  IonImg,
  useIonAlert,
  IonIcon,
} from "@ionic/react";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import Foot from "./Foot";
import { Capacitor } from "@capacitor/core"
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css'
import { parsePhoneNumberFromString } from 'libphonenumber-js';


import UserHead from "./UserHead";
import axios from "axios";
import Authservice from "../Services/auth.service";
import "./Login.css";
import { useHistory } from "react-router";
import validator from 'validator'
import { AppContext } from "../components/AppContext";
import { login_image, logo, termsandconditions } from "../theme/imagePath";
import { Link } from "react-router-dom";
import HeadFoot from "./Head";
import { eye, eyeOff, home } from "ionicons/icons";
import UserService from "../Services/user.service";
import Select, { components } from 'react-select';
import CommonModal from "../components/popipInfo";


interface State {
  id: number;
  name: string;
}



const Signup: React.FC = () => {
  const { handleSubmit } = useForm({
    mode: "onTouched",
    reValidateMode: "onChange",
  });
  const history = useHistory();
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [iserror, setIserror] = useState(false);
  const [issuccess, setIssuccess] = useState(false);
  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const [phone, setPhone] = useState("");
  const [area, setArea] = useState("");
  const [city, setCity] = useState("");
  const [country, setCountry] = useState("");
  const [address, setAddress] = useState("");
  const [aadhar, setAadhar] = useState("");
  const [password, setPassword] = useState("");
  const [user, setUser]: any = useState("ROLE_USER");
  const [selecteOption, setSelectedOption]: any = useState([])
  const [state, setState] = useState("");
  const [confirmpassword, setConfirmpassword] = useState("");
  const [product, setproduct] = useState("");
  const [TName, setTName]: any = useState("");
  const [TAdd, setTAdd]: any = useState("");
  const [vendor, setvendor]: any = useState("");
  const [pincode, setPincode]: any = useState("");
  const [showLoading, setShowLoading] = useState(false);
  const [isValidPhoneNumber, setIsValidPhoneNumber] = useState(false);
  const widthSize: any = window.innerWidth;
  const [Alert] = useIonAlert()
  const [districtValue, setdistrictValue]: any = useState("")
  const [districtOptions, setdistrictOptions]: any = useState([])
  const [panchayatOption, setdPanOptions]: any = useState([])
  const [panchayatValue, setpanchayatValue]: any = useState("")
  const [otherOrganisations, setOtherOrganisations] = useState("")

  const [Commonmodal, setCommonmodal] = useState(false)
  const [CommonModalMesg, setCommonModalMesg] = useState("")
  const [Commmonmodaltitle, setCommonmodaltitle] = useState("")
  const [acceptTerms, setAcceptTerms]: any = useState(false)

  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const { userdata } = useContext(AppContext)!;

  useEffect(() => {
    if (userdata != null && userdata != undefined && userdata != '') {
      history.replace("/")
    } else {
      getAllDistricts()
    }
  }, []);
  const mailpat = /[a-zA-Z0-9]+[\.]?([a-zA-Z0-9]+)?[\@][a-z]{3,9}[\.][a-z]{2,5}/g;
  const namecheck = /^[a-zA-Z\s]*$/

  const [formErrors, setFormErrors]: any = useState({
    user: '',
    firstname: '',
    phone: '',
    email: '',
    password: '',
    confirmpassword: '',
    vendor: '',
    product: '',
    TName: '',
    TAdd: '',
  });


  const customStyles = {
    control: (provided: any) => ({
      ...provided,
      border: "none",
      boxShadow: 'none',
      fontSize: '15px',
      // height: '44px',
      width: '100%',
      outline: 'none',
      textAlign: "left"
    }),
  };

  const getAllDistricts = () => {
    setShowLoading(true);
    UserService.getAdmAllDistricts(null, null, null).then((response) => {
      const data = response.data.map((e: any) => ({ label: e.districtName, value: e.districtCode }));
      setdistrictOptions(data)
      setShowLoading(false);
    })

      .catch((error) => {
        alert(error)
        setShowLoading(false);
      });
  }


  const getAllPanchayats = (e: any, num: any) => {
    setShowLoading(true);
    UserService.getAllPanchayats(null, null, null, e).then((response) => {
      console.log(response.data);
      const data = response.data.map((e: any) => ({ label: e.panchayatName, value: e.panchayatCode }))
      setdPanOptions(data);
      console.log(data, num)
      if (num) {
        setpanchayatValue({ label: num ? data.filter((item: any) => item.value === num)[0].label : null, value: num })
      } else {
        setpanchayatValue(null)
      }
      setShowLoading(false);
    })

      .catch((error) => {
        alert(error)
        setShowLoading(false);
      });
  }


  const Validation = (label: any) => {
    if (label === "user") {
      if (user === "") {
        setFormErrors((prev: any) => {
          return { ...prev, user: "Choose a Role" }
        })
      }
      else {
        setFormErrors((prev: any) => {
          return { ...prev, user: "" }
        })
      }
    }
    if (label === "firstname") {
      if (firstname == "") {
        setFormErrors((prev: any) => {
          return { ...prev, firstname: "Enter The Name" }
        })
      }
      else if (namecheck.test(firstname) == false) {
        setFormErrors((prev: any) => {
          return { ...prev, firstname: "Alphabets only Allowed in Name" }
        })
      }
      else if (firstname.charAt(0) == " ") {
        setFormErrors((prev: any) => {
          return { ...prev, firstname: "Empty Space at Name" }
        })
      }
      else {
        setFormErrors((prev: any) => {
          return { ...prev, firstname: "" }
        })
      }
    }

    if (label === "phone") {
      if (phone === undefined || phone === "") {
        setFormErrors((prev: any) => {
          return { ...prev, phone: "Mobile Number is required" }
        })
      } else if (!isValidPhoneNumber) {
        setFormErrors((prev: any) => {
          return { ...prev, phone: "Enter valid Mobile Number" }
        })
      }
      else {
        setFormErrors((prev: any) => {
          return { ...prev, phone: "" }
        })
      }
    }
    if (label === "email") {
      if (email.trim() === '') {
        setFormErrors((prev: any) => {
          return { ...prev, email: 'Email is required' }
        })
      }
      else if (validator.isEmail(email) === false) {
        setFormErrors((prev: any) => {
          return { ...prev, email: "Enter Valid Email Address" }
        })
      }
      else {
        setFormErrors((prev: any) => {
          return { ...prev, email: "" }
        })
      }
    }
    if (label === "password") {
      if (confirmpassword !== "") {
        if (password === confirmpassword) {
          setFormErrors((prev: any) => {
            return { ...prev, confirmpassword: "" }
          })
        }
      }
      else if (password.trim() === '') {
        setFormErrors((prev: any) => {
          return { ...prev, password: "Password is required" }
        })
      }
      else {
        setFormErrors((prev: any) => {
          return { ...prev, password: "" }
        })
      }
    }
    if (label === "confirmpassword") {
      if (password.trim() === '') {
        setFormErrors((prev: any) => {
          return { ...prev, confirmpassword: "Confirm Password is required" }
        })
      }
      else if (password != confirmpassword) {
        setFormErrors((prev: any) => {
          return { ...prev, confirmpassword: "Password and confirm password are not same" }
        })
      }
      else {
        setFormErrors((prev: any) => {
          return { ...prev, confirmpassword: "" }
        })
      }
    }

    if (label === "VName") {
      if (user === "3" && vendor === "") {
        setFormErrors((prev: any) => {
          return { ...prev, vendor: "Enter Vendor Name" }
        })
      }
      else if (user === "3" && namecheck.test(vendor) === false) {
        setFormErrors((prev: any) => {
          return { ...prev, vendor: "Alphabets only Allowed in Vendor Name" }
        })
      }
      else {
        setFormErrors((prev: any) => {
          return { ...prev, vendor: "" }
        })
      }
    }
    if (label === "product") {
      if (user === "3" && product === "") {
        setFormErrors((prev: any) => {
          return { ...prev, product: "Enter Product Type" }
        })
      }
      else {
        setFormErrors((prev: any) => {
          return { ...prev, product: "" }
        })
      }
    }
    if (label === "TName") {
      if (user === "4" && TName === "") {
        setFormErrors((prev: any) => {
          return { ...prev, TName: "Enter Temple Name" }
        })
      }
      else if (user === "4" && namecheck.test(TName) === false) {
        setFormErrors((prev: any) => {
          return { ...prev, TName: "Alphabets only Allowed in Temple Name" }
        })
      }
      else {
        setFormErrors((prev: any) => {
          return { ...prev, TName: "" }
        })
      }
    }
    if (label === "TAdd") {
      if (user === "4" && TAdd === "") {
        setFormErrors((prev: any) => {
          return { ...prev, TAdd: "Enter Temple Address" }
        })
      }
      else {
        setFormErrors((prev: any) => {
          return { ...prev, TAdd: "" }
        })
      }
    }
    if (label === "address") {
      if (address === "") {
        setFormErrors((prev: any) => {
          return { ...prev, address: "Enter the Address" }
        })
      }
      else {
        setFormErrors((prev: any) => {
          return { ...prev, address: "" }
        })
      }
    }
    if (label === "pincode") {
      if (pincode === "") {
        setFormErrors((prev: any) => {
          return { ...prev, pincode: "Enter the pincode" }
        })
      }
      else {
        setFormErrors((prev: any) => {
          return { ...prev, pincode: "" }
        })
      }
    }
  }



  const handleOpenTerms = () => {
    const baseUrl = window.location.origin; // Gets current domain
    const url = `${baseUrl}/#/termsandconditions`;
    window.open(url, "_blank");
  };

  const handleDistrictChange = (e: any) => {
    console.log(e)
    if (e) {
      setdistrictValue(e);
      getAllPanchayats(e.value, undefined)
    } else {
      setdistrictValue({})
    }
  }


  const handlepanChange = (e: any) => {
    console.log(e)
    if (e) {
      setpanchayatValue(e);
      // setState(e.value);
    } else {
      setpanchayatValue({})
      // setState("")
    }
  }

  const ChangeValidation = (label: any, e: any) => {
    if (label === "user") {
      const user = e.target.value;
      if (user === "") {
        setFormErrors((prev: any) => {
          return { ...prev, user: "Choose a Role" }
        })
      }
      else {
        setFormErrors((prev: any) => {
          return { ...prev, user: "" }
        })
      }
    }
    if (label === "firstname") {
      const firstname = e.target.value;
      if (firstname == "") {
        setFormErrors((prev: any) => {
          return { ...prev, firstname: "Enter The Name" }
        })
      }
      else if (namecheck.test(firstname) == false) {
        setFormErrors((prev: any) => {
          return { ...prev, firstname: "Alphabets only Allowed in Name" }
        })
      }
      else if (firstname.charAt(0) == " ") {
        setFormErrors((prev: any) => {
          return { ...prev, firstname: "Empty Space at Name" }
        })
      }
      else {
        setFormErrors((prev: any) => {
          return { ...prev, firstname: "" }
        })
      }
    }

    if (label === "phone") {
      const phone = e;

      let valid: any = false
      try {
        const phoneNumberObject: any = parsePhoneNumberFromString(e);
        valid = phoneNumberObject ? phoneNumberObject.isValid() : false;
      } catch (error) {
        valid = false;
        console.log(error)
      }

      if (phone === undefined || phone === "") {
        setFormErrors((prev: any) => {
          return { ...prev, phone: "Mobile Number is required" }
        })
      } else if (!valid) {
        setFormErrors((prev: any) => {
          return { ...prev, phone: "Enter valid Mobile Number" }
        })
      }
      else {
        setFormErrors((prev: any) => {
          return { ...prev, phone: "" }
        })
      }
    }
    if (label === "email") {
      const email = e.target.value;

      if (email.trim() === '') {
        setFormErrors((prev: any) => {
          return { ...prev, email: 'Email is required' }
        })
      }
      else if (validator.isEmail(email) === false) {
        setFormErrors((prev: any) => {
          return { ...prev, email: "Enter Valid Email Address" }
        })
      }
      else {
        setFormErrors((prev: any) => {
          return { ...prev, email: "" }
        })
      }
    }
    if (label === "address") {
      const add = e.target.value;
      if (add.trim() === '') {
        setFormErrors((prev: any) => {
          return { ...prev, address: 'Address is required' }
        })
      }
      else {
        setFormErrors((prev: any) => {
          return { ...prev, address: "" }
        })
      }
    }
    if (label === "pincode") {
      const pincode = e.target.value;
      if (pincode.trim() === '') {
        setFormErrors((prev: any) => {
          return { ...prev, pincode: 'Pincode is required' }
        })
      }
      else {
        setFormErrors((prev: any) => {
          return { ...prev, pincode: "" }
        })
      }
    }
    if (label === "password") {
      const password = e.target.value;
      if (confirmpassword !== "") {
        if (password === confirmpassword) {
          setFormErrors((prev: any) => {
            return { ...prev, confirmpassword: "" }
          })
        }
      }
      if (password.trim() === '') {
        setFormErrors((prev: any) => {
          return { ...prev, password: "Password is required" }
        })
      }
      else {
        setFormErrors((prev: any) => {
          return { ...prev, password: "" }
        })
      }
    }
    if (label === "confirmpassword") {
      const confirmpassword = e.target.value;

      if (confirmpassword.trim() === '') {
        setFormErrors((prev: any) => {
          return { ...prev, confirmpassword: "Confirm Password is required" }
        })
      }
      else if (password != confirmpassword) {
        setFormErrors((prev: any) => {
          return { ...prev, confirmpassword: "Password and confirm password are not same" }
        })
      }
      else {
        setFormErrors((prev: any) => {
          return { ...prev, confirmpassword: "" }
        })
      }
    }
    if (label === "Panchayat") {
      const Panchayat = e.value;

      if (!Panchayat) {
        setFormErrors((prev: any) => {
          return { ...prev, Panchayat: "Panchayat is required" }
        })
      }
      else {
        setFormErrors((prev: any) => {
          return { ...prev, Panchayat: "" }
        })
      }
    }
    if (label === "district") {
      const district = e.value;

      if (!district) {
        setFormErrors((prev: any) => {
          return { ...prev, district: "Panchayat is required" }
        })
      }
      else {
        setFormErrors((prev: any) => {
          return { ...prev, district: "" }
        })
      }
    }

    if (label === "VName") {
      const vendor = e.target.value;

      if (user === "3" && vendor === "") {
        setFormErrors((prev: any) => {
          return { ...prev, vendor: "Enter Vendor Name" }
        })
      }
      else if (user === "3" && namecheck.test(vendor) === false) {
        setFormErrors((prev: any) => {
          return { ...prev, vendor: "Alphabets only Allowed in Vendor Name" }
        })
      }
      else {
        setFormErrors((prev: any) => {
          return { ...prev, vendor: "" }
        })
      }
    }
    if (label === "product") {
      const product = e.target.value;

      if (user === "3" && product === "") {
        setFormErrors((prev: any) => {
          return { ...prev, product: "Enter Product Type" }
        })
      }
      else {
        setFormErrors((prev: any) => {
          return { ...prev, product: "" }
        })
      }
    }
    if (label === "TName") {
      const TName = e.target.value;

      if (user === "4" && TName === "") {
        setFormErrors((prev: any) => {
          return { ...prev, TName: "Enter Temple Name" }
        })
      }
      else if (user === "4" && namecheck.test(TName) === false) {
        setFormErrors((prev: any) => {
          return { ...prev, TName: "Alphabets only Allowed in Temple Name" }
        })
      }
      else {
        setFormErrors((prev: any) => {
          return { ...prev, TName: "" }
        })
      }
    }
    if (label === "TAdd") {
      const TAdd = e.target.value;

      if (user === "4" && TAdd === "") {
        setFormErrors((prev: any) => {
          return { ...prev, TAdd: "Enter Temple Address" }
        })
      }
      else {
        setFormErrors((prev: any) => {
          return { ...prev, TAdd: "" }
        })
      }
    }
  }

  const submit = () => {
    const errors: any = {};

    if (password.trim() === '') {
      errors.password = 'Password is required';
    }

    if (user === "") {
      errors.user = "Choose a Role"
    }
    if (firstname == "") {
      errors.firstname = "Enter the Name"
    }
    else if (namecheck.test(firstname) == false) {
      errors.firstname = 'Alphabets only Allowed in Name'
    }
    else if (firstname.charAt(0) == " ") {
      errors.firstname = 'Empty Space at Name'
    }
    if (phone === undefined || phone === "") {
      errors.phone = "Mobile Number is required"
    } else if (!isValidPhoneNumber) {
      errors.phone = "Enter valid Mobile Number"
    }
    // else if (pincode.length !== 6) {
    //   alert("Enter Valid Pincode")
    // }
    if (email.trim() === '') {
      errors.email = 'Email is required';
    }
    else if (validator.isEmail(email) === false) {
      errors.email = "Enter Valid Email Address";
    }
    // else if (aadhar.length !== 12) {
    //   alert("Enter Valid Aadhar Number")
    // }
    if (password.trim() === '') {
      errors.password = 'Password is required';
    }
    if (password.trim() === '') {
      errors.confirmpassword = 'Confirm Password is required';
    }
    else if (password != confirmpassword) {
      errors.confirmpassword = "Password and confirm password are not same ";
    }
    if (user === "3" && vendor === "") {
      errors.vendor = "Enter Vendor Name"
    }
    else if (user === "3" && namecheck.test(vendor) === false) {
      errors.vendor = "Alphabets only Allowed in Vendor Name"
    }
    if (user === "3" && product === "") {
      errors.product = "Enter Product Type";
    }
    if (user === "4" && TName === "") {
      errors.TName = "Enter Temple Name";
    }
    else if (user === "4" && namecheck.test(TName) === false) {
      errors.TName = "Alphabets only Allowed in Temple Name"
    }
    if (user === "4" && TAdd === "") {
      errors.TAdd = "Enter Temple Address"
    }
    setFormErrors({});
    if (Object.keys(errors).length === 0) {

      setShowLoading(true);

      Authservice.Register(
        aadhar,
        address,
        pincode,
        // area,
        // city,
        // state,
        // country,
        email,
        firstname,
        // lastname,
        phone,
        password,
        user,
        product,
        TName,
        TAdd,
        vendor,
        user, districtValue?.value, panchayatValue?.value,
        otherOrganisations
      )
        .then((response) => {
          console.log("res", response);
          setShowLoading(false);
          if (response.responseText.startsWith("SUCCESS")) {
            window.location.hash = "/login"
            Alert({
              header: "Atmagram",
              message: "Register Successfully",
              backdropDismiss: false,
              buttons: ['OK']
            })
            setConfirmpassword("");
            setFirstname("");
            setEmail("");
            setPassword("");
            setPhone("");
            // setUser("");
            setTAdd("");
            setTName("");
            setproduct("");
            setvendor("");
          } else if (response.responseText == "ERROR:Email Id already exist") {
            setCommonModalMesg("Email Id Already Exists, Try Any Other Email Id");
            setCommonmodaltitle("Error")
            setCommonmodal(true);
          } else if (response.responseText == "ERROR:MobileNumber already exist") {
            setCommonModalMesg("Mobile Number Already Exists, Try Any Other Mobile Number");
            setCommonmodaltitle("Error")
            setCommonmodal(true);
          } else {
            setCommonModalMesg("User Not Register Successfully")
            setCommonmodaltitle("Error")
            setCommonmodal(true);
          }
        })
        .catch((error) => {
          setCommonModalMesg("Something went wrong, Please try again later");
          setCommonmodaltitle("Error")
          setCommonmodal(true);
          setShowLoading(false);
        });
    }
  };

  const phoneInputRef = useRef(null)
  const moveCursorToEnd = (value: any) => {
    const input: any = phoneInputRef.current;
    if (widthSize <= 500) {
      if (input) {
        setTimeout(() => {
          input.focus();
          input.setSelectionRange(input.value.length, input.value.length);
        }, 0);
      }
    }
    try {
      const phoneNumberObject: any = parsePhoneNumberFromString(value);
      setIsValidPhoneNumber(phoneNumberObject ? phoneNumberObject.isValid() : false);
    } catch (error) {
      setIsValidPhoneNumber(false);
      console.log(error)
    }
    setPhone(value)
  };

  const modalClose = () => {
    setCommonmodal(false)
  }

  return (
    <IonPage>
      <IonContent>
        <div className="head_login">
          <HeadFoot User={"login"} Role={'Role'} Name='Home,Sign Up' /></div>
        <IonLoading message="Loading..." spinner={null}
          isOpen={showLoading}
        />
        <IonAlert
          isOpen={iserror}
          onDidDismiss={() => setIserror(false)}
          cssClass="my-custom-class"
          header={"Atmagram"}
          message={message}
          buttons={["Ok"]}
        />
        <IonAlert
          isOpen={issuccess}
          onDidDismiss={() => setIssuccess(false)}
          cssClass="my-custom-class"
          header={"Atmagram"}
          message={message}
          buttons={["Ok"]}
        />

        <IonRow className="page_container">
          <IonCol className="img_container" sizeLg="6" sizeMd="0" sizeXl="6" sizeSm="0" size="6" sizeXs="0" >
            <div className="tool_img" >
              <div className="logo_login">
                <Link to="/" style={{ textDecoration: "none" }}>
                  <IonImg className="logo_class" src={logo} ></IonImg>
                </Link></div>
            </div>
          </IonCol>
          <IonCol sizeLg="6" sizeMd="12" sizeXl="6" sizeSm="12" size="6" sizeXs="12">
            <IonRow>
              <IonCol>
                <div className="signup_container">
                  <form onSubmit={handleSubmit(submit)} style={{ width: "100%" }}>
                    <div style={{ width: "100%" }}>
                      <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", width: "100%" }}>
                        <span className="login_head">Sign Up</span>
                        <div className="register_login_title">
                          <Link to="/">
                            <IonIcon src={home} className="home_icon">
                            </IonIcon>
                          </Link>
                        </div>
                      </div>
                      <IonRow>
                        <IonCol size="12" sizeLg="5" >
                          <IonLabel className="label_align">
                            Name
                            <span style={{ color: "red" }}>*</span>
                          </IonLabel>
                        </IonCol>
                        <IonCol size="12" sizeLg="7">
                          <IonInput
                            type="text" className="select_user"
                            value={firstname}
                            // required
                            onBlur={() => Validation("firstname")}
                            onIonChange={(e) => { setFirstname(e.detail.value!); ChangeValidation("firstname", e) }}
                          ></IonInput>
                          {formErrors.firstname && <span className="alert_input_bottom ">{formErrors.firstname}</span>}
                        </IonCol>
                      </IonRow>
                      <IonRow>
                        <IonCol size="12" sizeLg="5">
                          <IonLabel className="label_align" >
                            E-mail <span style={{ color: "red" }}>*</span>
                          </IonLabel>
                        </IonCol>
                        <IonCol size="12" sizeLg="7">
                          <IonInput
                            type="email"
                            value={email} className="select_user"
                            style={{ textTransform: "none" }}
                            // required
                            onBlur={() => Validation("email")}
                            onIonChange={(e) => { setEmail(e.detail.value!); ChangeValidation("email", e) }}
                          ></IonInput>
                          {formErrors.email && <span className="alert_input_bottom ">{formErrors.email}</span>}
                        </IonCol>
                      </IonRow>
                      <IonRow >
                        <IonCol size="12" size-lg="5" >
                          <IonLabel className="label_align">
                            Mobile Number <span style={{ color: "red" }}>*</span>
                          </IonLabel>
                        </IonCol>
                        <IonCol size="12" size-lg="7">
                          <div >
                            <PhoneInput
                              className="select_user"
                              international
                              defaultCountry="IN"
                              value={phone}
                              ref={phoneInputRef}
                              onBlur={() => Validation("phone")}
                              // flags=false
                              onChange={(value: any) => { moveCursorToEnd(value); ChangeValidation("phone", value) }}
                            /></div>
                          <div style={{}} className={Capacitor.getPlatform() === "ios" ? "space-for-iOS" : ""}>
                            {formErrors.phone && <span className="alert_input_bottom ">{formErrors.phone}</span>}
                          </div>
                        </IonCol>
                      </IonRow>
                      <IonRow >
                        <IonCol size="12" size-lg="5" >
                          <IonLabel className="label_align">
                            District
                          </IonLabel>
                        </IonCol>
                        <IonCol size="12" size-lg="7">
                          <div >
                            <Select
                              options={districtOptions}
                              value={districtValue}
                              className="signup-autoSelect"
                              placeholder="Select a District"
                              isClearable
                              styles={customStyles}
                              onBlur={() => Validation("district")}
                              onChange={(e) => { handleDistrictChange(e); ChangeValidation("district", e) }}
                            />
                          </div>
                          {formErrors.district && <span className="profile-errors">{formErrors.district}</span>}
                        </IonCol>
                      </IonRow>
                      <IonRow >
                        <IonCol size="12" size-lg="5" >
                          <IonLabel className="label_align">
                            Panchayat
                          </IonLabel>
                        </IonCol>
                        <IonCol size="12" size-lg="7">
                          <div className="select_user">
                            <Select
                              options={panchayatOption}
                              value={panchayatValue}
                              className="signup-autoSelect"
                              placeholder="Select a Panchayat"
                              isClearable
                              styles={customStyles}
                              onBlur={() => Validation("Panchayat")}
                              onChange={(e) => { handlepanChange(e); ChangeValidation("Panchayat", e) }}
                            />
                          </div>
                          {formErrors.Panchayat && <span className="profile-errors">{formErrors.Panchayat}</span>}
                        </IonCol>
                      </IonRow>
                      <IonRow>
                        <IonCol size="12" size-lg="5">
                          <IonLabel className="label_align">
                            Address
                          </IonLabel>
                        </IonCol>
                        <IonCol size="12" size-lg="7">
                          <IonInput
                            value={address} className="select_user"
                            onBlur={() => Validation("address")}
                            onIonChange={(e) => { setAddress(e.detail.value!); ChangeValidation("address", e) }}
                          ></IonInput>
                        </IonCol>
                      </IonRow>
                      <IonRow>
                        <IonCol size="12" size-lg="5">
                          <IonLabel className="label_align">
                            Pincode
                          </IonLabel>
                        </IonCol>
                        <IonCol size="12" size-lg="7">
                          <IonInput
                            value={pincode} className="select_user"
                            onBlur={() => Validation("pincode")}
                            onIonChange={(e) => { setPincode(e.detail.value!); ChangeValidation("pincode", e) }}
                          ></IonInput>
                        </IonCol>
                      </IonRow>
                      <IonRow>
                        <IonCol size="12" size-lg="5">
                          <IonLabel className="label_align">
                            Aadhar number
                          </IonLabel>
                        </IonCol>
                        <IonCol size="12" size-lg="7">
                          <IonInput
                            value={aadhar} className="select_user"
                            onIonChange={(e) => { setAadhar(e.detail.value!); }}
                          ></IonInput>
                        </IonCol>
                      </IonRow>
                      <IonRow>
                        <IonCol size="12" size-lg="5">
                          <IonLabel className="label_align">
                            Other organizations
                          </IonLabel>
                        </IonCol>
                        <IonCol size="12" size-lg="7">
                          <IonInput
                            type="text"
                            value={otherOrganisations} className="select_user"
                            autocomplete="off"
                            onIonChange={(e) => setOtherOrganisations(e.detail.value!)}
                          ></IonInput>
                        </IonCol>
                      </IonRow>
                      <IonRow>
                        <IonCol size="12" size-lg="5">
                          <IonLabel className="label_align">
                            Password <span style={{ color: "red" }}>*</span>
                          </IonLabel>
                        </IonCol>
                        <IonCol size="12" size-lg="7"  >
                          <div style={{ position: "relative", width: "100%" }}>
                            <IonInput
                              type={showPassword ? "text" : "password"}
                              className="select_user"
                              value={password}
                              style={{ textTransform: "none" }}
                              clearOnEdit={false}
                              onBlur={() => Validation("password")}
                              onIonChange={(e) => {
                                setPassword(e.detail.value!);
                                ChangeValidation("password", e);
                              }}
                            />
                            <IonIcon
                              icon={showPassword ? eyeOff : eye}
                              onClick={() => setShowPassword(!showPassword)}
                              style={{ position: "absolute", right: "20px", top: "25px", transform: "translateY(-50%)" }}
                            />
                            {formErrors.password && <span className="alert_input_bottom">{formErrors.password}</span>}
                          </div>
                        </IonCol>
                      </IonRow>

                      <IonRow>
                        <IonCol size="12" size-lg="5">
                          <IonLabel className="label_align">
                            Confirm Password <span style={{ color: "red" }}>*</span>
                          </IonLabel>
                        </IonCol>
                        <IonCol size="12" size-lg="7" >
                          <div style={{ position: "relative", width: "100%" }}>
                            <IonInput
                              type={showConfirmPassword ? "text" : "password"}
                              className="select_user"
                              style={{ textTransform: "none" }}
                              value={confirmpassword}
                              clearOnEdit={false}
                              onBlur={() => Validation("confirmpassword")}
                              onIonChange={(e) => {
                                setConfirmpassword(e.detail.value!);
                                ChangeValidation("confirmpassword", e);
                              }}
                            />
                            <IonIcon
                              icon={showConfirmPassword ? eyeOff : eye}
                              onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                              style={{ position: "absolute", right: "20px", top: "25px", transform: "translateY(-50%)" }}
                            />
                            {formErrors.confirmpassword && (
                              <span className="alert_input_bottom">{formErrors.confirmpassword}</span>
                            )}
                          </div>
                        </IonCol>
                      </IonRow>
                      <IonRow>
                        <IonCol size="12" size-lg="12" className="signup_btn_container_parent" >
                          <div>
                            <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
                              <input type="checkbox" id="terms" value={acceptTerms} onChange={(e) => setAcceptTerms(e.target.checked)} />
                              <label className="terms_label">I accept the <a style={{ color: "#007aff", cursor: "pointer" }}
                                onClick={() => handleOpenTerms()}
                              >Terms and Conditions</a></label>
                            </div>
                            <div>
                              <Link to="/login" className="forgot_pass_css">Already have an account?</Link>
                            </div>
                          </div>
                          <div className={["signup_btn_container", firstname.length === 0 ||
                            phone === undefined || phone === "" || !isValidPhoneNumber ||
                            email.length === 0 ||
                            password.length === 0 ||
                            confirmpassword.length === 0 ||
                            !acceptTerms ||
                            user == "" ? "disableCursor" : ""].join(" ")} style={{ width: "fit-content" }}>
                            <IonButton
                              type="submit"
                              disabled={
                                firstname.length === 0 ||
                                phone === undefined || phone === "" || !isValidPhoneNumber ||
                                email.length === 0 ||
                                password.length === 0 ||
                                confirmpassword.length === 0 ||
                                user == "" || !acceptTerms
                              }
                            >
                              <span className="button_padding"> Sign up</span>
                            </IonButton>
                          </div>
                        </IonCol>
                      </IonRow>

                    </div>
                  </form>
                </div></IonCol></IonRow>
          </IonCol>
        </IonRow>
        <CommonModal showModal={Commonmodal} closeModal={modalClose} modaltitle={Commmonmodaltitle} AlertModalMesg={CommonModalMesg} />
        {/* <Foot /> */}
      </IonContent>
    </IonPage>
  );
};

export default Signup;