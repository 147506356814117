import {
  IonContent,
  IonText,
  IonItem,
  IonPage,
  IonButton,
  IonRow,
  IonCol,
  IonItemDivider,
} from "@ionic/react";
import React, { useState } from "react";
import "./Home.css";
import "./PrivacyPolicy.css";
import { termsandconditions } from "../theme/imagePath";

const TermsConditions: React.FC = () => {
  return (
    <IonPage>
      <IonContent>
        <iframe
          src={termsandconditions}
          width="100%"
          height="100%"
          style={{ border: "none" }}
        />
      </IonContent>
    </IonPage>
  );
};

export default TermsConditions;