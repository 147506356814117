import {
  IonContent,
  IonItemDivider,
  IonRow,
  IonCol,
  IonImg,
  IonPage,
  IonIcon,
  IonText,
  IonButton,
  IonCheckbox,
  IonGrid,
  IonInput,
  IonLabel,
  IonRadio,
  IonRadioGroup,
  IonSelect,
  IonSelectOption,
  IonTextarea,
  useIonAlert,
  IonLoading,
  IonItem,
  IonAlert,
  IonHeader,
  IonCard,
  IonBadge,
  IonSearchbar
} from "@ionic/react";
import React, { useEffect, useState, useRef, useContext, useMemo } from "react";
import { addCircleOutline, addOutline, arrowBack, arrowForward, calendarOutline, cart, checkmarkCircleSharp, close, flashlightOutline, gridOutline, informationCircle, informationCircleSharp, pin, removeCircleOutline, removeOutline, searchOutline } from "ionicons/icons";
import { useHistory, useLocation } from "react-router";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import moment from "moment";
import { Button, Modal, ModalBody, ModalHeader } from "react-bootstrap";
import "bootstrap";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/js/bootstrap.js";
import "bootstrap/dist/js/bootstrap.min.js";
import UserHead from "./UserHead";
import Foot from "./Foot";
import "jquery/dist/jquery.min.js";
import UserService from "../Services/auth.service";
import Authservice from "../Services/user.service";
import "./sam-temp.css";
import { useForm } from "react-hook-form";
import { Capacitor } from "@capacitor/core";
import validator from 'validator'
import CustomSpin from "../components/customSpin";
import useRazorpay from "react-razorpay";
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css'
import { AppContext } from "../components/AppContext";
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import Select, { components } from 'react-select';
import { tick, infoicon, closeicon, temple } from "../theme/imagePath";
import "./TempleSearch.css"
import HeadFoot from "./Head";
import { Link } from "react-router-dom";
import { RecaptchaVerifier, signInWithPhoneNumber } from "firebase/auth";
import { auth } from "../Services/firebase-config";
import { totalmem } from "os";
import { v4 as uuidv4 } from 'uuid';
import DataTable from "react-data-table-component";
import customTableStyle from '../components/CustomTableStyle';
import CommonDataTable from "../components/datatable";
import Breadcrumb from "../components/Breadcrumb";
import CommonModal from "../components/popipInfo";



interface State {
  id: number;
  name: string;
}
const Poojaofferings: React.FC = () => {

  const [formErrors, setFormErrors]: any = useState({});
  const [counErrors, setcountErrors]: any = useState({});

  const API_KEY = "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"

  const [userdata, setUserdata]: any = useState(sessionStorage.getItem("UserData"));
  const [errorText, setErrorText] = useState("")
  const [templeList, settempleList] = useState([]);
  const history = useHistory();
  const [image, setImage] = useState("");
  const [deityname, setDeityname] = useState("");
  const [deityId, setDeityId] = useState("")
  const [description, setDescription] = useState("");
  const [isShow, setIsshow] = useState(false);
  const [index1, setIndex1]: any = useState(0);
  const [id, setId] = useState("");
  const [islogin, Islogin] = useState(false);
  const [offerKey, setOfferKey] = useState(0)

  const [showLoading, setShowLoading] = useState(false);
  const [dprice, setDprice]: any = useState("");
  const location = useLocation();
  const [itemDivider, setItemDivider] = useState(true);
  const [userAddress, setUserAddress]: any = useState(sessionStorage.getItem("Address"));
  const [usertoken, setUsertoken] = useState(sessionStorage.getItem("token"));
  const [cartparams]: any = useState(location.state)
  const formatter = new Intl.NumberFormat('en-IN')
  const [cartId, setCartId] = useState("")
  const buttonClickedRef = useRef(index1);
  const [prasadamAvailable, setprasadamAvailable] = useState(false)

  const [actionFlag, setActionFlag] = useState(false);
  const actionClickedRef = useRef(actionFlag);
  const { setUFlag } = useContext(AppContext)!;
  const { setToken } = useContext(AppContext)!;
  const { setUserData } = useContext(AppContext)!;
  const [showAlert, setShowAlert] = useState(false);
  const [returnPage, setRtnPage] = useState("");
  const [Alert] = useIonAlert();
  const [stateOption, setStateOption]: any = useState([]);
  const [countryOption, setCountryOption]: any = useState({ value: "India", label: "India" });
  const [states, setStates]: any = useState([])
  const [districts, setDistricts]: any = useState([])
  const [districtMenu, setDistrictMenu]: any = useState(false)
  const [stateMenu, setStateMenu]: any = useState(false)
  const [countries, setCountries] = useState(["India"])
  const [payFlag, setPayFlag] = useState(0)
  const [cartCount, setCartCount] = useState(0)
  const userID: any = useState(sessionStorage.getItem("UserId"));
  const token: any = useState(sessionStorage.getItem("token"));
  const stateLiistOptions = states.map((e: any, index: any) => ({
    value: e,
    label: e
  }));

  const countryLiistOptions = countries.map((e: any, index: any) => ({
    value: e,
    label: e
  }));
  const [poojaOfferings, setPoojaOfferings]: any = useState([])
  const uFlag: any = useState(sessionStorage.getItem("uFlag"))

  const [headname, setHeadname] = useState("")
  const [hideContent, setHideContent] = useState(false)
  const [bookDetailsModal, setBookDetailsModal] = useState(false)
  const [facilitationCharge, setFacilitationCharge]: any = useState(0)
  const [actualfacilitationCharge, setActualFacilitationCharge]: any = useState(0)
  const [tantriAmt, settantriAmt] = useState(0)
  const [templeAmt, setTempleAmt] = useState(0)
  const [selectedPoojaIds, setSelectedPoojaIds]: any = useState([])
  const [currentPooja, setCurrentPooja]: any = useState("")
  const [selectedPoojas, setSelectedPoojas]: any = useState([])
  const [totalPoojaAmt, setTotalPoojaAmt] = useState(0)
  const [totalOrderAmt, setTotalOrderAmt] = useState(0)
  const [confirmPoojaOffering, setConfirmPoojaOfferings]: any = useState([])
  const [bookingId, setBookingId]: any = useState("")
  const [orderId, setOrderId] = useState(0)
  const [conTotalPoojaAmt, setConTotalPoojaAmt] = useState(0)
  const [conTotalOrderAmt, setConTotalOrderAmt] = useState(0)
  const [conTantriAmt, setConTantriAmt] = useState(0)
  const [conTempleAmt, setConTempleAmt] = useState(0)
  const [bookingDate, setBookingDate] = useState("")
  const [noOfDays, setNoOfDays] = useState(0)
  const [deliveryCharge, setDeliveryCharge]: any = useState(0)
  const [poojari, setPoojaRi]: any = useState(0)

  let debounceTimeout: any;

  const [filterText, setFilterText] = React.useState("");
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);

  const [starOptions, setStarOptions] = useState([])
  const [modalIndex, setmodalIndex] = useState(0)
  const [datearray, setdatearray]: any = useState([])
  const [selectedDates, setSelectedDates]: any = useState([])
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [Commonmodal, setCommonmodal] = useState(false)
  const [CommonModalMesg, setCommonModalMesg] = useState("")
  const [Commmonmodaltitle, setCommonmodaltitle] = useState("")
  const mobile = screenWidth <= 768;
  const [poojaSearchText, setpoojaSearchText] = useState("")
  const [searchTerm, setSearchTerm] = useState("");

  const [mailVerifyFlag, setMailVerifyFlag] = useState(true)

  const [districtOption, setDistrictOption]: any = useState({ value: "ALL", label: "ALL" });
  const [districtListOptions, setDistrictlistoptions] = useState([])
  const [panchayatListOptions, setPanchayatListoptions] = useState([])
  const [panchayatoption, setPanchayatOption]: any = useState({ value: "ALL", label: "ALL" })
  const [showDeleviryChargeText, setShowDeleviryChargeText] = useState(false)
  const [additionalOption, setAdditionalOption]: any = useState({ value: "ALL", label: "ALL" })
  const [additionalOptions, setadditionalOptions] = useState([])


  const options = [
    { value: 20, label: '20 Days' },
    { value: 30, label: '30 Days' },
    { value: 60, label: '60 Days' },
  ];

  useEffect(() => {
    if (!userID[0]) {
      getMessagesByCategory()
    } else {
      getAppConfigs()
    }
  }, []);


  const checkEmailVerify = () => {
    Authservice.isEmailVerified(userID[0], null)
      .then((response) => {
        if (response.data === true) {
          getdatas()
        } else {
          setCommonModalMesg("Your email has not been verified. Please verify it to proceed further.")
          setCommonmodal(true)
          setCommonmodaltitle("Information")
          setMailVerifyFlag(false)
        }

      })
      .catch((error) => {
        setShowLoading(false)
      });
  }


  const getAllDistricts = () => {
    Authservice.getAdmAllDistricts(userID[0], usertoken, usertoken).then((response) => {
      console.log(response);
      setShowLoading(true)
      const districtLiistOptions: any = [
        { value: "ALL", label: "ALL" },
        ...response.data.map((e: any) => ({
          value: e.districtCode,
          label: e.districtName
        }))]
      setDistrictlistoptions(districtLiistOptions)
      setShowLoading(false)
    })

      .catch((error) => {
        alert(error)
        setShowLoading(false);
      });
  }


  const getAllPanchayats = (e: any) => {
    Authservice.getAllPanchayats(userID[0], usertoken, usertoken, e).then((response) => {
      console.log(response.data);
      const districtLiistOptions: any = [
        { value: "ALL", label: "ALL" },
        ...response.data.map((e: any) => ({
          value: e.panchayatCode,
          label: e.panchayatName
        }))]
      setPanchayatListoptions(districtLiistOptions);
      setShowLoading(false);
    })

      .catch((error) => {
        alert(error)
        setShowLoading(false);
      });
  }



  const handleDistrictChange = (e: any) => {
    console.log(e)
    setDistrictOption(e);
    setDistrict(e.value);
    if (e) {
      getAllPanchayats(e.value)
      Getdatausingsearch(`[${e.label},ALL,${additionalOption.label}] ${searchTerm}`)
    }
    setPanchayatListoptions([])
    setPanchayatOption({ value: "ALL", label: "ALL" })
  }

  const hanldePanchayatChange = (e: any) => {
    console.log(e)
    if (e) {
      setPanchayatOption(e);
      Getdatausingsearch(`[${districtOption.label},${e.label},${additionalOption.label}] ${searchTerm}`)
    } else {
      setPanchayatOption({ value: "ALL", label: "ALL" })
    }
  }

  const hanldeAdditionalChange = (e: any) => {
    console.log(e)
    if (e) {
      setAdditionalOption(e);
      Getdatausingsearch(`[${districtOption.label},${panchayatoption.label},${e.label}] ${searchTerm}`)
    } else {
      setAdditionalOption({ value: "ALL", label: "ALL" })
    }
  }



  const getAllTempleSearchOf = () => {
    Authservice.getAllTempleSearchOf(usertoken, userID[0]).then((response) => {
      console.log(response);
      setShowLoading(true)
      const options: any = [
        { value: "ALL", label: "ALL" },
        ...response.data.map((e: any) => ({
          value: e.id,
          label: e.name
        }))]
      setadditionalOptions(options)
      setShowLoading(false)
    })

      .catch((error) => {
        alert(error)
        setShowLoading(false);
      });
  }

  const getdatas = () => {
    loadCart();
    getAllTempleSearchOf()
    var tempData: any = location.state
    getallgstars()
    getMessagesByCategory()
    if (tempData !== null && tempData !== undefined && tempData.templeCode !== "" && tempData.templeCode !== undefined) {
      offeringDetails(tempData, poojaSearchText, 0)
    }
    else if (tempData !== null && tempData !== undefined && tempData.deityName) {
      const value = `[${districtOption.label},${panchayatoption.label},${additionalOption.label}] ${tempData.deityName}`
      Getdatausingsearch(value)
      setFilterText(tempData.deityName)
    }
    else {
      const value = `[${districtOption.label},${panchayatoption.label},${additionalOption.label}] `
      Getdatausingsearch(value)
      getAllDistricts()
    }
  }


  const loadCart = () => {
    Authservice.Addcart(usertoken, userID[0]).then((response: any) => {
      console.log(response.data)
      setcart(response.data)
      setShowLoading(false);
      setCartCount(response.data.length)
      // var list = response.data;
      // amount = 0;
      // if (token != null && token != undefined && token != "") {
      //   sessionStorage.removeItem("guestTkn")
      // }

      // for (let obj of list) {
      //   if (obj.dakshinaAmountToTemple != null) {
      //     amount = amount + obj.dakshinaAmountToTemple;
      //   }
      //   if (obj.dakshinaAmountForPriest != null) {
      //     amount = amount + obj.dakshinaAmountForPriest;
      //   }
      //   amount = amount + obj.totalAmount;
      //   setTotalAmount(amount)
      // }


    }).catch((error) => {
      setcart([])
      setShowLoading(false);
    });
  }


  const getalldates = (option: any,) => {
    setShowLoading(true);
    setdateLimitPooja(option);
    const today = new Date();
    const futureDate = new Date(today.getTime() + option.value * 24 * 60 * 60 * 1000);
    const formattedDate = `${futureDate.getFullYear()}-${String(futureDate.getMonth() + 1).padStart(2, '0')}-${String(
      futureDate.getDate()
    ).padStart(2, '0')}`;

    Authservice.getcalender(userID[0], usertoken, usertoken, formattedDate).then((response: any) => {
      console.log(response)
      const data = response.data.map((item: any, i: any) => {
        const dateObj = new Date(item.date); // Convert milliseconds to Date object
        const formattedDate = `${String(dateObj.getDate()).padStart(2, '0')}/${String(dateObj.getMonth() + 1).padStart(2, '0')
          }/${dateObj.getFullYear()}`;
        const actualDay = dateObj.toLocaleDateString('en-US', { weekday: 'long' });
        return { ...item, date: formattedDate, actualDay: actualDay, selected: false, uniqueId: `id-${Date.now()}-${i}` }; // Return updated item
      });
      console.log(data)
      setdatearray(data)
      setShowLoading(false)
    })
      .catch((error: any) => {
        console.log(error);
        setErrorText("Sorry, No data available")
        setShowLoading(false);
      });
  }

  const getallgstars = () => {
    setShowLoading(true);
    Authservice.getAllStars(usertoken, userID[0], usertoken).then((response: any) => {
      console.log(response)
      const data = response.data.map((e: any, i: any) => {
        return { label: e.starName, value: e.id }
      })
      setStarOptions(data)
      setShowLoading(false)
    })
      .catch((error: any) => {
        console.log(error);
        setErrorText("Sorry, No data available")
        setShowLoading(false);
      });
  }

  const getallgods = (sizeOfList: any, pageNumber: any) => {
    console.log(islogin)
    setShowLoading(true);
    Authservice.getAllTemplesForUsers(usertoken, pageNumber, userID[0], sizeOfList).then((response: any) => {
      if (response.data.templeDtos !== undefined || response.data.templeDtos.length !== 0) {
        const data = response.data.templeDtos.map((e: any, i: any) => {
          return { ...e, serial: i + 1 }
        })
        settempleList(data);
      } else {
        settempleList([]);
        setErrorText("Sorry, No data available")
      }
      setShowLoading(false)
    })
      .catch((error: any) => {
        console.log(error);
        setErrorText("Sorry, No data available")
        setShowLoading(false);
      });
  }

  const handleLeave = () => {
    // Code to execute when the user confirms leaving

    console.log('Leaving MyPage', returnPage);
    // Additional actions can be performed here

    setShowAlert(false);

    // Update the flag to indicate that the action was clicked
    history.push(returnPage); // Example: Navigate to the new page
  };
  useEffect(() => {
    const unlisten = history.block((location, action) => {

      if (!actionClickedRef.current && (buttonClickedRef.current === 4 || buttonClickedRef.current === 5)) {
        setRtnPage(location.pathname)
        setActionFlag(true)
        // return "Are you sure want to Leave? If you leave, your order details will be lost.";
        setShowAlert(true);
        return false;

      }
      // Code to execute when the page is about to leave
      console.log('Leaving MyPage');
      // Additional actions can be performed here

    });

    return () => {
      unlisten();
    };

  }, [history, actionClickedRef.current, buttonClickedRef.current]);

  useEffect(() => {
    buttonClickedRef.current = index1;
    actionClickedRef.current = actionFlag;
  }, [index1, actionFlag]);
  const [maindeityname, setMaindeityname] = useState("")
  const [maindeityid, setMaindeityid] = useState("")
  const Details = (item: any) => {
    if (Capacitor.getPlatform() !== "web" && gods.length === 0) {
      setHideContent(true)
    }
    else {
      setHideContent(false)
    }
    setGods([])
    setId(item.id);
    setMaindeityid(item.id)
    setMaindeityname(item.deityName);
    setImage(item.image)
    getAllState()
    // setIndex1(2)
    setHeadname("Home,Select Area")
  }

  const backtodeity = () => {
    setHeadname("Home,Select Deity")
    setIndex1(0)
  }

  const templebacktodeity = () => {
    if (Capacitor.getPlatform() !== "web") {
      setHideContent(true)
    }
    else {
      setHideContent(false)
    }
    setIndex1(0)
    setShowgod(false)
    setHeadname("Home,Select Deity")
  }

  const Viewinfo = (item: any) => {
    setImage(item.image);
    setMaindeityname(item.deityName);
    setDescription(item.deityDescription);
    setIndex1(1)
    setHeadname("Home,Biography")
  };

  const [state, setState]: any = useState();
  const [country, setCountry] = useState("India");
  const [district, setDistrict]: any = useState()
  const [town, setTown]: any = useState()
  const [gods, setGods] = useState([])
  const ref: any = useRef(null);
  const [scrl, setScrl]: any = useState();
  const [offerings, setOfferings]: any = useState([])
  const [offerName, setOffername] = useState("")
  const [offerId, setOfferId] = useState("")
  const [tname, setTname] = useState("")
  const [tempCode, setTempCode] = useState("")
  const [temName, setTemName]: any = useState("");
  const [showgod, setShowgod] = useState(false);
  const widthSize = window.innerWidth
  const [slidesToShow, setSlidesToShow]: any = useState(widthSize > 900 ? 3 : widthSize > 600 ? 2 : widthSize < 600 ? 1 : 0);
  const SearchF = (value: any) => {
    if (value == "Enter") {
      Submit()
    }
  }
  const submitSign = (value: any) => {
    if (value == "Enter") {
      Searchsign()
    }
  }

  const { handleSubmit } = useForm({
    mode: "onTouched",
    reValidateMode: "onChange",
  });

  const targetRef: any = useRef(null);

  const Submit = () => {
    const errors: any = {};
    if (country.length == 0) {
      errors.country = "Please select the country"
    }
    setcountErrors(errors);
    if (Object.keys(errors).length == 0) {
      setShowLoading(true)
      UserService.SearchTemple(usertoken, country, state, district, town, id, temName)
        .then((response: any) => {
          console.log("Res=>", response)
          setGods(response.data)
          setShowgod(true);
          if (Capacitor.getPlatform() !== "web") {
            setHideContent(false)
            setTimeout(() => {
              if (targetRef.current) {
                targetRef.current.scrollIntoView({ behavior: "smooth" });
              }
            }, 1000)
          }
          setShowLoading(false)
        })
        .catch((error) => {
          console.log(error);
          setShowLoading(false)
        });
    }
  };

  const Razorpay = useRazorpay();

  const [deityOffer, setDeityOffer]: any = useState([])

  const NextArrow = (props: any) => {
    console.log(props, "nextt")
    const { className, onClick } = props;
    const currentSlide = props.currentSlide
    const slideCount = props.slideCount
    return (
      <div className="slick_nxt" onClick={onClick} style={{ display: currentSlide === slideCount - slidesToShow ? 'none' : 'block' }} >
        <IonIcon icon={arrowForward} />
      </div>
    );
  };

  const PrevArrow = (props: any) => {
    console.log(props, "preee")
    const { className, onClick } = props;
    const currentSlide = props.currentSlide
    return (
      <div className="slick_prev" onClick={onClick} style={{ display: currentSlide === 0 ? 'none' : 'block' }}>
        <IonIcon icon={arrowBack} />
      </div>
    );
  };
  const breakpoints = [
    {
      breakpoint: 400, settings: {
        slidesToShow: 1, slidesToScroll: 1, dots: false, nextArrow: <NextArrow />, prevArrow: <PrevArrow />, infinite: false,
      }
    },
    { breakpoint: 600, settings: { slidesToShow: 1, slidesToScroll: 1, dots: false, nextArrow: <NextArrow />, prevArrow: <PrevArrow />, infinite: false, } },
    { breakpoint: 900, settings: { slidesToShow: 2, slidesToScroll: 2, dots: false, nextArrow: <NextArrow />, prevArrow: <PrevArrow />, infinite: false, } },
    { breakpoint: 1024, settings: { slidesToShow: 3, slidesToScroll: 3, dots: false, nextArrow: <NextArrow />, prevArrow: <PrevArrow />, infinite: false, } },
  ];

  const initialSettings = {
    dots: false,
    horizontal: true,
    speed: 500,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    infinite: false,
    slidesToShow: 3,
    slidesToScroll: 3,
    responsive: breakpoints,
  };



  const confirmDates = () => {
    setFilterText("")
    setmodalIndex(0)
  }
  const backtotemple = () => {
    setDeityId("")
    setpoojaSearchText("")
    setOfferings([])
    setDeityOffer([])
    setSelectedPoojaIds([])
    setSelectedPoojas([])
    setConfirmPoojaOfferings([])
    setBookingId("")
    setIndex1(0)
    setHeadname("Home,Select Area")
  }
  const [android, setAndroid] = useState(false)
  const shows = (item: any) => {
    setItemDivider(true)
    var aa = Capacitor.getPlatform()
    console.log(aa, "aaaa")
    if (Capacitor.getPlatform() === "android") {
      setAndroid(true)
    }
    else {
      setAndroid(false)
    }
    setIndex1(4);
    setHeadname("Home,Booking Details")
    setId(item.poojaCode);
    setOfferId(item.poojaCode)
    console.log("id", offerId);
    setOffername(item.poojaName);
    setDprice(item.cost);
  }
  const scroll = (scrollOffset: any) => {
    ref.current.scrollLeft += scrollOffset;
  };
  const inputArr = [
    {

      value: ""
    }
  ];


  const [userid] = useState(sessionStorage.getItem("UserId"));
  const [radio, setRadio] = useState("")
  const [month, setMonth]: any = useState(0)
  const [fdate, setFdate] = useState("")
  const [from, setFrom] = useState("")
  const [to, setTo] = useState("")
  const [tdate, setTdate] = useState("")
  const [username, setUsername]: any = useState("");
  const [star, setStar] = useState("");
  const [radiodel, setRadiodel]: any = useState("false");
  const [delivery, setDelivery] = useState("");
  const [remarks, setRemarks] = useState("");
  const [dates, setDates]: any = useState(inputArr)
  const [dakshinaamt, setDakshiamt]: any = useState("")
  const [paydakshinapreist, setPaydakshinapreist]: any = useState(false)
  const [paydakshinatemple, setPaydakshinatemple]: any = useState(false)
  const [donationamt, setDonationamt]: any = useState("")
  const [cartList, setcart]: any = useState([])
  const [email, setEmail]: any = useState(sessionStorage.getItem("Email"));
  const [pincode, setPin]: any = useState("");
  const [phone, setPhone]: any = useState(sessionStorage.getItem("Phone"));
  const [listdates, setListdates]: any = useState([]);
  const [confirmdates, setConfirmdates]: any = useState([]);
  const [tabId, setTabId]: any = useState("")
  const [sortdates, setSortDates]: any = useState([])
  const [deladd, setDeladd] = useState(false);
  const [orderList, setOrderList]: any = useState([]);
  const [payData, setPayData]: any = useState([])
  const [onedate, setOnedate] = useState("");
  const [paymentdata, setPaymentdata]: any = useState([]);
  const [add, setAdd]: any = useState(sessionStorage.getItem("Address"));
  const [che, setChe] = useState(false);
  const [modalopen, setModalopen] = useState(false);
  const [modalopen1, setModalopen1] = useState(false);
  const [myOrder, setMyOrder] = useState([]);
  const [dak, setDak]: any = useState(false);
  const [don, setDon] = useState(false);
  const [delCharge, setDelCharge]: any = useState("");
  const [delChargePerDay, setDelChargePerDay]: any = useState("");

  const [signupmodal, setSignupmodal] = useState(false)
  const [user, setUser]: any = useState("2");
  const [confirmpassword, setConfirmpassword] = useState("");
  const [password1, setPassword1]: any = useState("");
  const [state1, setState1]: any = useState("");
  const [TName, setTName]: any = useState("");
  const [TAdd, setTAdd]: any = useState("");
  const [aadhar1, setAadhar1]: any = useState("");
  const [addr, setAddr] = useState("");
  const [area1, setArea1] = useState("")
  const [firstname1, setFirstname1] = useState("");
  const [lastname1, setLastname1] = useState("");
  const [phone1, setPhone1]: any = useState("");
  const [city1, setCity1] = useState("");
  const [country1, setCountry1] = useState("India");
  const [email1, setEmail1] = useState("");
  const [issuccess, setIssuccess] = useState(false);
  const [pincode1, setPincode1]: any = useState("");
  const checkboxRef = useRef(null);
  const [isValidPhoneNumberSign, setIsValidPhoneNumberSign] = useState(false);
  const sliderRef: any = useRef()
  const [sizeOfList, setSizeOfList]: any = useState(10)
  const [pageNumber, setpageNumber]: any = useState(0)


  var details: any = {}
  if (radio === "1") {
    if (radiodel === "false") {
      var delAdd = "N/A"
    }
    else {
      var delAdd = delivery + "," + pincode;
    }
    details = {
      "id": cartId,
      "firstName": username,
      "fromDate": from,
      "toDate": to,
      "star": star,
      "mailId": email,
      "mobileNumber": phone,
      "deliveryAddress": delAdd,
      "prasadhamDelFlag": radiodel,
      "poojaOfferings": { "id": offerId },
      //  "listOfDates":dates,
      "payDakshinaToPriestFlag": paydakshinapreist,
      "payDakshinaToTempleFlag": paydakshinatemple,
      // "dakshinaAmountForPriest": dakshinaamt,
      // "dakshinaAmountToTemple": donationamt

    }
  } else if (radio === "2") {
    if (radiodel === "false") {
      var delAdd = "N/A"
    }
    else {
      var delAdd = delivery + "," + pincode;
    }
    details = {
      "id": cartId,
      "firstName": username,
      "star": star,
      "mailId": email,
      "mobileNumber": phone,
      "deliveryAddress": delAdd,
      "prasadhamDelFlag": radiodel,
      "poojaOfferings": { "id": offerId },
      "payDakshinaToPriestFlag": paydakshinapreist,
      "payDakshinaToTempleFlag": paydakshinatemple,
      "noOfMonths": month,
      // "dakshinaAmountForPriest": dakshinaamt,
      // "dakshinaAmountToTemple": donationamt
    }
  } else if (radio === "3") {
    if (radiodel === "false") {
      var delAdd = "N/A"
    }
    else {
      var delAdd = delivery + "," + pincode;
    }
    // const datesList = listdates.map((e: any) => new Date(e))
    //   .sort((a: any, b: any) => a - b)
    // const listofdates = datesList.map((e: any) => moment(e).format("YYYY-MM-DDThh:mm:ss"))
    details = {
      "id": cartId,
      "firstName": username,
      "star": star,
      "mailId": email,
      "mobileNumber": phone,
      "deliveryAddress": delAdd,
      "prasadhamDelFlag": radiodel,
      "poojaOfferings": { "id": offerId },
      "listOfDates": listdates,
      "payDakshinaToPriestFlag": paydakshinapreist,
      "payDakshinaToTempleFlag": paydakshinatemple,
      // "dakshinaAmountForPriest": dakshinaamt,
      // "dakshinaAmountToTemple": donationamt
    }
  }

  if (paydakshinatemple) {
    details["dakshinaAmountToTemple"] = donationamt;
  }
  if (paydakshinapreist) {
    details["dakshinaAmountForPriest"] = dakshinaamt;
  }

  const addInput = () => {
    const tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate() + 1);
    const val = tomorrow.toISOString().split('T')[0]
    var valSet = isFound('');
    var past = pastDate(val)
    const errors: any = {}

    if (!valSet && !past) {
      setDates((e: any) => {
        return [
          ...e,
          {
            value: ""
          }
        ];
      });
      return;

    } else if (past) {
      errors.dates = "Please select future date"
    } else {
      errors.dates = "Enter Date before adding a new Date"
    }
    setFormErrors(errors)

  };
  const getAllState = () => {
    setStateOption(null)
    setShowLoading(true);
    Authservice.getAllstates().then((response) => {
      console.log(response.data);
      setStates(response.data)
      setShowLoading(false);
    })
      .catch((error) => {
        alert(error)
        setShowLoading(false);
      });
  }

  const isFound = (val: any) => {
    return dates.some((item: any) => val === item.value);
  }
  const pastDate = (val: any) => {
    return dates.some((item: any) => val > new Date(item.value).toISOString().split('T')[0]);
  }
  const removeInputFields = (index: any) => {
    const rows = [...dates];
    const lists = [...listdates]
    const confirm = [...confirmdates]
    const error: any = {}

    if (rows.length >= 1) {
      rows.splice(index, 1);
    }
    if (lists.length >= 1) {
      lists.splice(index, 1);
    }
    if (confirm.length >= 1) {
      confirm.splice(index, 1);
    }
    error.dates = ""
    setFormErrors(error)
    setDates(rows);
    setListdates(lists)
    setConfirmdates(confirm)
  }
  const sliderback = () => {
    setItemDivider(false)
    setOffername("")
    setIndex1(1)
    setHeadname("Home,Offerings List")
  }
  const handleChange = (e: any, i: any) => {
    e.preventDefault();
    dates[i].value = e.target.value
  };
  const dates_changes = (e: any, i: any) => {
    // console.log(date,"lojo")
    //   Alert({
    //     header: "Atmagram",
    //     message: "Please select a future date",
    //     backdropDismiss: false,
    //     buttons: [
    //       {
    //         text: "ok",
    //       }
    //     ]
    //   })
    // }
    // else {
    dates[i].value = e.target.value
    var ii = e.detail.value;
    var str = moment(ii).format('YYYY-MM-DDThh:mm:ss')
    listdates[i] = str;
    var confirm = moment(ii).format('DD-MM-YYYY')
    confirmdates[i] = confirm
    listdates.map((e: any) => moment(e).format('YYYY-MM-DDThh:mm:ss'))
      .sort((a: any, b: any) => a - b)
    // }
  }

  const handleIconClick = (event: any) => {
    console.log('Icon clicked!');
    event.stopPropagation();  // Stop the event from propagating to the parent div
  };

  const fromDateInputRef: any = useRef(null);
  const toDateInputRef: any = useRef(null)
  const listDateInputRef: any = useRef(null)
  const [datelimitpooja, setdateLimitPooja] = useState({ label: "20 days", value: 20 })

  const datelimit = () => {
    const today = new Date();
    const dd = String(today.getDate() + 1).padStart(2, "0");
    const mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
    const yyyy = today.getFullYear();
    // return yyyy + "-" + mm + "-" + dd;
    const today1 = new Date();
    const tomorrow = new Date(today1);
    tomorrow.setDate(tomorrow.getDate() + noOfDays);
    return moment(tomorrow.toISOString().split('T')[0]).format("YYYY-MM-DD");
  };
  const todatelimit = () => {
    const today = new Date(fdate);
    const dd = String(today.getDate()).padStart(2, "0");
    const mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
    const yyyy = today.getFullYear();
    // return yyyy + "-" + mm + "-" + dd;
    const today1 = new Date();
    if (fdate !== "") {
      const tomorrow = new Date(fdate);
      tomorrow.setDate(tomorrow.getDate());
      return tomorrow.toISOString().split('T')[0];
    }
  };
  const namepat = /^[a-zA-Z\s]*$/
  const starpat = /^[a-zA-Z\s]*$/
  const twoDigitsOnly = /^\d{0,2}$/;
  const [ffdate, setffdate]: any = useState("");
  const [ttdate, setttdate] = useState("")
  const { amountLimit }: any = useContext(AppContext)!;

  const Validation = (label: any) => {
    const tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate() + 1);
    if (label === "address") {
      if (delivery == "" && deladd == true) {
        setFormErrors((prev: any) => {
          return { ...prev, address: "Please Enter delivery address" }
        })
      }
      else {
        setFormErrors((prev: any) => {
          return { ...prev, address: "" }
        })
      }
    }

    if (label === "pincode") {
      if (pincode.length === "" && deladd == true) {
        setFormErrors((prev: any) => {
          return { ...prev, pincode: "Please Enter valid pincode" }
        })
      }
      else if (pincode.charAt(0) === "0" && deladd == true) {
        setFormErrors((prev: any) => {
          return { ...prev, pincode: "Pincode is invalid" }
        })
      }
      else if (pincode.length != 6 && deladd == true) {
        setFormErrors((prev: any) => {
          return { ...prev, pincode: "Please Enter valid pincode" }
        })
      }
      else {
        setFormErrors((prev: any) => {
          return { ...prev, pincode: "" }
        })
      }
    }

    if (label === "radio") {
      if (radio == "") {
        setFormErrors((prev: any) => {
          return { ...prev, radio: "Please choose atleast any one of the fields" }
        })
      }
      else {
        setFormErrors((prev: any) => {
          return { ...prev, radio: "" }
        })
      }
    }

    if (label === "fromDate") {
      if (fdate === "" && radio === "1") {
        setFormErrors((prev: any) => {
          return { ...prev, fromDate: "Please Select From Date" }
        })
      }
      else if (tomorrow.toISOString().split('T')[0] > new Date(fdate).toISOString().split('T')[0] && radio === "1") {
        setFormErrors((prev: any) => {
          return { ...prev, fromDate: "Please select future date" }
        })
      }
      else {
        setFormErrors((prev: any) => {
          return { ...prev, fromDate: "" }
        })
      }
    }

    if (label === "toDate") {
      if (tdate === "" && radio === "1") {
        setFormErrors((prev: any) => {
          return { ...prev, toDate: "Please Select To Date" }
        })
      }
      else if (fdate === tdate && radio === "1") {
        setFormErrors((prev: any) => {
          return { ...prev, toDate: "From date and To date are same" }
        })
      }
      else if (tomorrow.toISOString().split('T')[0] > new Date(tdate).toISOString().split('T')[0] && radio === "1") {
        setFormErrors((prev: any) => {
          return { ...prev, toDate: "Please select future date" }
        })
      }
      else {
        setFormErrors((prev: any) => {
          return { ...prev, toDate: "" }
        })
      }
    }

    if (label === "monthly") {
      const check = month as string;
      if (month == 0 && radio === "2") {
        setFormErrors((prev: any) => {
          return { ...prev, monthly: "Please Enter the Month" }
        })
      }
      else if (radio === "2" && twoDigitsOnly.test(check) === false) {
        setFormErrors((prev: any) => {
          return { ...prev, monthly: "Please Enter valid Month" }
        })
      }
      else {
        setFormErrors((prev: any) => {
          return { ...prev, monthly: "" }
        })
      }
    }

    if (label === "dates") {
      var flag;
      for (let i = 0; i < dates.length; i++) {
        if (dates[i].value == "") {
          flag = true
        }
      }
      var flag1;
      for (let ii = 0; ii < dates.length; ii++) {
        for (let j = ii + 1; j < dates.length; j++) {
          if (dates[ii].value == dates[j].value) {
            flag1 = true
          }
        }
      }
      var flag2;
      for (let i = 0; i < dates.length; i++) {
        if (tomorrow.toISOString().split('T')[0] > new Date(dates[i].value).toISOString().split('T')[0]) {
          flag2 = true
        }
      }
      if (flag && radio === "3") {
        setFormErrors((prev: any) => {
          return { ...prev, dates: "Please Enter the Date" }
        })
      }
      else if (flag1 && radio === "3") {
        setFormErrors((prev: any) => {
          return { ...prev, dates: "Please Check the Date you Entered" }
        })
      }
      else if (flag2 && radio === "3") {
        setFormErrors((prev: any) => {
          return { ...prev, dates: "Please select future date" }
        })
      }
      else {
        setFormErrors((prev: any) => {
          return { ...prev, dates: "" }
        })
      }
    }

    if (label === "priestAmount") {
      if (dakshinaamt == "" && dakamt1 === true) {
        setFormErrors((prev: any) => {
          return { ...prev, priestAmount: "Please Enter the dakshina amount to priest" }
        })
      }
      else if (dakshinaamt <= 0 && dakamt1 === true) {
        setFormErrors((prev: any) => {
          return { ...prev, priestAmount: "Please Enter valid dakshina amount" }
        })
      }
      else if (dakshinaamt > amountLimit && dakamt1 === true) {
        setFormErrors((prev: any) => {
          return { ...prev, priestAmount: "Maximum Transaction limit is 500000" }
        })
      }
      else {
        setFormErrors((prev: any) => {
          return { ...prev, priestAmount: "" }
        })
      }
    }

    if (label === "donationamt") {
      if (donationamt == "" && donamt1 === true) {
        setFormErrors((prev: any) => {
          return { ...prev, donationamt: "Please Enter the donation amount to temple" }
        })
      }
      else if (donationamt <= 0 && donamt1 === true) {
        setFormErrors((prev: any) => {
          return { ...prev, donationamt: "Please Enter valid donation amount" }
        })
      }
      else if (donationamt > amountLimit && donamt1 === true) {
        setFormErrors((prev: any) => {
          return { ...prev, donationamt: "Maximum Transaction limit is 500000" }
        })
      }
      else {
        setFormErrors((prev: any) => {
          return { ...prev, donationamt: "" }
        })
      }
    }

    if (label === "login email") {
      if (logemail.trim() === '') {
        setFormErrors((prev: any) => {
          return { ...prev, logemail: "Email is required" }
        })
      }
      else if (validator.isEmail(logemail) === false) {
        setFormErrors((prev: any) => {
          return { ...prev, logemail: "Enter Valid Email Address" }
        })
      }
      else {
        setFormErrors((prev: any) => {
          return { ...prev, logemail: "" }
        })
      }
    }

    if (label === "login password") {
      if (password.trim() === '') {
        setFormErrors((prev: any) => {
          return { ...prev, password: "Password is required" }
        })
      }
      else {
        setFormErrors((prev: any) => {
          return { ...prev, password: "" }
        })
      }
    }


    if (label === "firstname") {
      if (firstname1 == "") {
        setFormErrors((prev: any) => {
          return { ...prev, firstname1: "Enter First Name" }
        })
      }
      else if (namecheck.test(firstname1) == false) {
        setFormErrors((prev: any) => {
          return { ...prev, firstname1: "Alphabets only Allowed in First Name" }
        })
      }
      else if (firstname1.charAt(0) == " ") {
        setFormErrors((prev: any) => {
          return { ...prev, firstname1: "Empty Space at First Name" }
        })
      }
      else {
        setFormErrors((prev: any) => {
          return { ...prev, firstname1: "" }
        })
      }
    }

    if (label === "mobile") {
      if (phone1 === '' || phone1 === undefined) {
        setFormErrors((prev: any) => {
          return { ...prev, phone1: "Mobile Number is required" }
        })
      } else if (!isValidPhoneNumberSign) {
        setFormErrors((prev: any) => {
          return { ...prev, phone1: "Please Enter Valid Mobile Number" }
        })
      }
      else {
        setFormErrors((prev: any) => {
          return { ...prev, phone1: "" }
        })
      }
    }

    if (label === "loginphone") {
      if (loginphone === '' || loginphone === undefined) {
        setFormErrors((prev: any) => {
          return { ...prev, loginPhone: "Mobile Number is required" }
        })
      } else if (!isValidLoginNumber) {
        setFormErrors((prev: any) => {
          return { ...prev, loginPhone: "Please Enter Valid Mobile Number" }
        })
      }
      else {
        setFormErrors((prev: any) => {
          return { ...prev, loginPhone: "" }
        })
      }
    }

    // else if (pincode.length !== 6) {
    //   alert("Enter Valid Pincode")
    // }
    if (label === "email") {
      if (email1.trim() === '') {
        setFormErrors((prev: any) => {
          return { ...prev, email1: "Email is required" }
        })
      }
      else if (validator.isEmail(email1) === false) {
        setFormErrors((prev: any) => {
          return { ...prev, email1: "Enter Valid Email Address" }
        })
      }
      else {
        setFormErrors((prev: any) => {
          return { ...prev, email1: "" }
        })
      }
    }
    // else if (aadhar.length !== 12) {
    //   alert("Enter Valid Aadhar Number")
    // }

    if (label === "password") {
      if (password1.trim() === '') {
        setFormErrors((prev: any) => {
          return { ...prev, password1: "Password is required" }
        })
      }
      else {
        setFormErrors((prev: any) => {
          return { ...prev, password1: "" }
        })
      }
    }

    if (label === "confirmpassword") {
      if (confirmpassword.trim() === '') {
        setFormErrors((prev: any) => {
          return { ...prev, confirmpassword: "Confirm Password is required" }
        })
      }
      else if (password1 != confirmpassword) {
        setFormErrors((prev: any) => {
          return { ...prev, confirmpassword: "Password and confirm password are not same" }
        })
      }
      else {
        setFormErrors((prev: any) => {
          return { ...prev, confirmpassword: "" }
        })
      }
    }
  }

  const ChangeValidation = (label: any, e: any) => {
    const tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate() + 1);
    if (label === "address") {
      const delivery = e.target.value;
      if (delivery == "" && deladd == true) {
        setFormErrors((prev: any) => {
          return { ...prev, address: "Please Enter delivery address" }
        })
      }
      else {
        setFormErrors((prev: any) => {
          return { ...prev, address: "" }
        })
      }
    }

    if (label === "pincode") {
      const pincode = e.target.value;

      if (pincode.length === "" && deladd == true) {
        setFormErrors((prev: any) => {
          return { ...prev, pincode: "Please Enter valid pincode" }
        })
      }
      else if (pincode.charAt(0) === "0" && deladd == true) {
        setFormErrors((prev: any) => {
          return { ...prev, pincode: "Pincode is invalid" }
        })
      }
      else if (pincode.length != 6 && deladd == true) {
        setFormErrors((prev: any) => {
          return { ...prev, pincode: "Please Enter valid pincode" }
        })
      }
      else {
        setFormErrors((prev: any) => {
          return { ...prev, pincode: "" }
        })
      }
    }

    if (label === "radio") {
      const radio = e.target.value;

      if (radio == "") {
        setFormErrors((prev: any) => {
          return { ...prev, radio: "Please choose atleast any one of the fields" }
        })
      }
      else {
        setFormErrors((prev: any) => {
          return { ...prev, radio: "" }
        })
      }
    }

    if (label === "fromDate") {
      const fdate = e.target.value;

      if (fdate === "" && radio === "1") {
        setFormErrors((prev: any) => {
          return { ...prev, fromDate: "Please Select From Date" }
        })
      }
      else if (tomorrow.toISOString().split('T')[0] > new Date(fdate).toISOString().split('T')[0] && radio === "1") {
        setFormErrors((prev: any) => {
          return { ...prev, fromDate: "Please select future date" }
        })
      }
      else {
        setFormErrors((prev: any) => {
          return { ...prev, fromDate: "" }
        })
      }
    }

    if (label === "toDate") {
      const tdate = e.target.value;
      if (tdate === "" && radio === "1") {
        setFormErrors((prev: any) => {
          return { ...prev, toDate: "Please Select To Date" }
        })
      }
      else if (fdate === tdate && radio === "1") {
        setFormErrors((prev: any) => {
          return { ...prev, toDate: "From date and To date are same" }
        })
      }
      else if (tomorrow.toISOString().split('T')[0] > new Date(tdate).toISOString().split('T')[0] && radio === "1") {
        setFormErrors((prev: any) => {
          return { ...prev, toDate: "Please select future date" }
        })
      }
      else {
        setFormErrors((prev: any) => {
          return { ...prev, toDate: "" }
        })
      }
    }

    if (label === "monthly") {
      const month = e.target.value;
      const check = e.target.value as string;
      if (month == 0 && radio === "2") {
        setFormErrors((prev: any) => {
          return { ...prev, monthly: "Please Enter the Month" }
        })
      }
      else if (radio === "2" && twoDigitsOnly.test(check) === false) {
        setFormErrors((prev: any) => {
          return { ...prev, monthly: "Please Enter valid Month" }
        })
      }
      else {
        setFormErrors((prev: any) => {
          return { ...prev, monthly: "" }
        })
      }
    }

    if (label === "dates") {
      var flag;
      for (let i = 0; i < dates.length; i++) {
        if (dates[i].value == "") {
          flag = true
        }
      }
      var flag1;
      for (let ii = 0; ii < dates.length; ii++) {
        for (let j = ii + 1; j < dates.length; j++) {
          if (dates[ii].value == dates[j].value) {
            flag1 = true
          }
        }
      }
      var flag2;
      for (let i = 0; i < dates.length; i++) {
        if (tomorrow.toISOString().split('T')[0] > new Date(dates[i].value).toISOString().split('T')[0]) {
          flag2 = true
        }
      }


      if (flag && radio === "3") {
        setFormErrors((prev: any) => {
          return { ...prev, dates: "Please Enter the Date" }
        })
      }
      else if (flag1 && radio === "3") {
        setFormErrors((prev: any) => {
          return { ...prev, dates: "Please Check the Date you Entered" }
        })
      }
      else if (flag2 && radio === "3") {
        setFormErrors((prev: any) => {
          return { ...prev, dates: "Please select future date" }
        })
      }
      else {
        setFormErrors((prev: any) => {
          return { ...prev, dates: "" }
        })
      }
    }

    if (label === "priestAmount") {
      const dakshinaamt = e.target.value;

      if (dakshinaamt == "" && dakamt1 === true) {
        setFormErrors((prev: any) => {
          return { ...prev, priestAmount: "Please Enter the dakshina amount to priest" }
        })
      }
      else if (dakshinaamt <= 0 && dakamt1 === true) {
        setFormErrors((prev: any) => {
          return { ...prev, priestAmount: "Please Enter valid dakshina amount" }
        })
      }
      else if (dakshinaamt > amountLimit && dakamt1 === true) {
        setFormErrors((prev: any) => {
          return { ...prev, priestAmount: "Maximum Transaction limit is 500000" }
        })
      }
      else {
        setFormErrors((prev: any) => {
          return { ...prev, priestAmount: "" }
        })
      }
    }

    if (label === "donationamt") {

      const donationamt = e.target.value;

      if (donationamt == "" && donamt1 === true) {
        setFormErrors((prev: any) => {
          return { ...prev, donationamt: "Please Enter the donation amount to temple" }
        })
      }
      else if (donationamt <= 0 && donamt1 === true) {
        setFormErrors((prev: any) => {
          return { ...prev, donationamt: "Please Enter valid donation amount" }
        })
      }
      else if (donationamt > amountLimit && donamt1 === true) {
        setFormErrors((prev: any) => {
          return { ...prev, donationamt: "Maximum Transaction limit is 500000" }
        })
      }
      else {
        setFormErrors((prev: any) => {
          return { ...prev, donationamt: "" }
        })
      }
    }

    if (label === "login email") {
      const logemail = e.target.value;

      if (logemail.trim() === '') {
        setFormErrors((prev: any) => {
          return { ...prev, logemail: "Email is required" }
        })
      }
      else if (validator.isEmail(logemail) === false) {
        setFormErrors((prev: any) => {
          return { ...prev, logemail: "Enter Valid Email Address" }
        })
      }
      else {
        setFormErrors((prev: any) => {
          return { ...prev, logemail: "" }
        })
      }
    }

    if (label === "login password") {
      const password = e.target.value;

      if (password.trim() === '') {
        setFormErrors((prev: any) => {
          return { ...prev, password: "Password is required" }
        })
      }
      else {
        setFormErrors((prev: any) => {
          return { ...prev, password: "" }
        })
      }
    }

    if (label === "phone") {
      if (phone === undefined || phone === "") {
        setFormErrors((prev: any) => {
          return { ...prev, phone: "Mobile Number is required" }
        })
      } else if (!isValidPhoneNumber) {
        setFormErrors((prev: any) => {
          return { ...prev, phone: "Enter valid Mobile Number" }
        })
      }
      else {
        setFormErrors((prev: any) => {
          return { ...prev, phone: "" }
        })
      }
    }


    if (label === "firstname") {
      const firstname1 = e.target.value;

      if (firstname1 == "") {
        setFormErrors((prev: any) => {
          return { ...prev, firstname1: "Enter First Name" }
        })
      }
      else if (namecheck.test(firstname1) == false) {
        setFormErrors((prev: any) => {
          return { ...prev, firstname1: "Alphabets only Allowed in First Name" }
        })
      }
      else if (firstname1.charAt(0) == " ") {
        setFormErrors((prev: any) => {
          return { ...prev, firstname1: "Empty Space at First Name" }
        })
      }
      else {
        setFormErrors((prev: any) => {
          return { ...prev, firstname1: "" }
        })
      }
    }

    if (label === "mobile") {

      const phone1 = e;

      let valid: any = false
      try {
        const phoneNumberObject: any = parsePhoneNumberFromString(e);
        valid = phoneNumberObject ? phoneNumberObject.isValid() : false;
      } catch (error) {
        valid = false;
        console.log(error)
      }

      if (phone1 === '' || phone1 === undefined) {
        setFormErrors((prev: any) => {
          return { ...prev, phone1: "Mobile Number is required" }
        })
      } else if (!valid) {
        setFormErrors((prev: any) => {
          return { ...prev, phone1: "Please Enter Valid Mobile Number" }
        })
      }
      else {
        setFormErrors((prev: any) => {
          return { ...prev, phone1: "" }
        })
      }
    }
    // else if (pincode.length !== 6) {
    //   alert("Enter Valid Pincode")
    // }
    if (label === "email") {

      const email1 = e.target.value;

      if (email1.trim() === '') {
        setFormErrors((prev: any) => {
          return { ...prev, email1: "Email is required" }
        })
      }
      else if (validator.isEmail(email1) === false) {
        setFormErrors((prev: any) => {
          return { ...prev, email1: "Enter Valid Email Address" }
        })
      }
      else {
        setFormErrors((prev: any) => {
          return { ...prev, email1: "" }
        })
      }
    }
    // else if (aadhar.length !== 12) {
    //   alert("Enter Valid Aadhar Number")
    // }

    if (label === "password") {
      const password1 = e.target.value;

      if (password1.trim() === '') {
        setFormErrors((prev: any) => {
          return { ...prev, password1: "Password is required" }
        })
      }
      else {
        setFormErrors((prev: any) => {
          return { ...prev, password1: "" }
        })
      }
    }

    if (label === "confirmpassword") {
      const confirmpassword = e.target.value;

      if (confirmpassword.trim() === '') {
        setFormErrors((prev: any) => {
          return { ...prev, confirmpassword: "Confirm Password is required" }
        })
      }
      else if (password1 != confirmpassword) {
        setFormErrors((prev: any) => {
          return { ...prev, confirmpassword: "Password and confirm password are not same" }
        })
      }
      else {
        setFormErrors((prev: any) => {
          return { ...prev, confirmpassword: "" }
        })
      }
    }
  }

  const show1 = () => {
    const tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate() + 1);
    var flag;
    var flag2;
    for (let i = 0; i < dates.length; i++) {
      if (dates[i].value == "") {
        flag = true
      }
      else if (tomorrow.toISOString().split('T')[0] > new Date(dates[i].value).toISOString().split('T')[0]) {
        flag2 = true
      }
    }
    var flag1;
    for (let ii = 0; ii < dates.length; ii++) {
      for (let j = ii + 1; j < dates.length; j++) {
        if (dates[ii].value == dates[j].value) {
          flag1 = true
        }
      }
    }

    // if (username == "") {
    //   Alert({
    //     header: 'Alert',
    //     message: 'Enter name',
    //     buttons: ['OK'],
    //   })
    // }
    // else if (namepat.test(username) == false) {
    //   Alert({
    //     header: 'Alert',
    //     message: 'Alphabets only Allowed in Name',
    //     buttons: ['OK'],
    //   })
    // }
    // else if (username.charAt(0) == " ") {
    //   Alert({
    //     header: 'Alert',
    //     message: 'Empty Space at Name',
    //     buttons: ['OK'],
    //   })
    // }
    // else if (star == "") {
    //   Alert({
    //     header: 'Alert',
    //     message: 'Enter Star',
    //     buttons: ['OK'],
    //   })
    // }
    // else if (starpat.test(star) == false) {
    //   Alert({
    //     header: 'Alert',
    //     message: 'Alphabets only Allowed in Star',
    //     buttons: ['OK'],
    //   })
    // }
    // else if (star.charAt(0) == " ") {
    //   Alert({
    //     header: 'Alert',
    //     message: 'Empty Space at Star',
    //     buttons: ['OK'],
    //   })
    // }
    const errors: any = {};
    const check = month as string;
    const dontNumber = parseInt(donationamt, 10)
    const dakNumber = parseInt(dakshinaamt, 10)
    var amountCheck = dontNumber + dakNumber
    if (radiodel == "") {
      Alert({
        header: 'Alert',
        message: 'Choose pradasam delivery',
        buttons: ['OK'],
      })
    }
    if (delivery == "" && deladd == true) {
      errors.address = "Please Enter delivery address"
      // Alert({
      //   header: 'Alert',
      //   message: 'Enter delivery address',
      //   buttons: ['OK'],
      // })
    }
    if (pincode.length === "" && deladd == true) {
      errors.pincode = "Please Enter valid pincode"
      // Alert({
      //   header: 'Alert',
      //   message: 'Enter valid pincode',
      //   buttons: ['OK'],
      // })
    }
    else if (pincode.charAt(0) === "0" && deladd == true) {
      errors.pincode = "Pincode is invalid"
      // Alert({
      //   header: 'Alert',
      //   message: 'pincode is invalid',
      //   buttons: ['OK'],
      // })
    }
    else if (pincode.length != 6 && deladd == true) {
      errors.pincode = "Please Enter valid pincode"
      // Alert({
      //   header: 'Alert',
      //   message: 'Enter valid pincode',
      //   buttons: ['OK'],
      // })
    }
    if (radio == "") {
      errors.radio = "Please choose atleast any one of the fields"
      // Alert({
      //   header: 'Alert',
      //   message: 'Choose Dates',
      //   buttons: ['OK'],
      // })
    }
    if (fdate === "" && radio === "1") {
      errors.fromDate = "Please Select From Date"

      // Alert({
      //   header: 'Alert',
      //   message: 'Select from Date',
      //   buttons: ['OK'],
      // })
    } else if (radio === "1") {
      if (tomorrow.toISOString().split('T')[0] > new Date(fdate).toISOString().split('T')[0]) {
        errors.fromDate = "Please select future date"
      }
    }
    else if (tdate === "" && radio === "1") {
      errors.toDate = "Please Select To Date"
      // Alert({
      //   header: 'Alert',
      //   message: 'Select to Date',
      //   buttons: ['OK'],
      // })
    }
    else if (radio === "1") {
      if (tomorrow.toISOString().split('T')[0] > new Date(tdate).toISOString().split('T')[0]) {
        errors.toDate = "Please select future date"
      }
    }
    else if (fdate === tdate && radio === "1") {
      errors.toDate = "From date and To date are same"
      // Alert({
      //   header: 'Alert',
      //   message: 'From date and To date are same',
      //   buttons: ['OK'],
      // })
    }
    else if (month == 0 && radio === "2") {
      errors.monthly = "Please Enter the Month"
      // Alert({
      //   header: 'Alert',
      //   message: 'Enter the Month',
      //   buttons: ['OK'],
      // })
    }
    else if (radio === "2" && twoDigitsOnly.test(check) === false) {
      errors.monthly = "Please Enter valid Month"
    }
    else if (flag && radio === "3") {
      errors.dates = "Please Enter the Date"
      // Alert({
      //   header: 'Alert',
      //   message: 'Enter the Dates',
      //   buttons: ['OK'],
      // })
    }
    else if (flag1 && radio === "3") {
      errors.dates = "Please Check the Date you Entered"
      // Alert({
      //   header: 'Alert',
      //   message: 'Same Dates',
      //   buttons: ['OK'],
      // })
    } else if (flag2 && radio === "3") {
      errors.dates = "Please select future date"
    }
    if (dakshinaamt == "" && dakamt1 === true) {
      errors.priestAmount = "Please Enter the dakshina amount to priest"
      // Alert({
      //   header: 'Alert',
      //   message: 'Enter the dakshina amount to priest',
      //   buttons: ['OK'],
      // })
    }
    else if (dakshinaamt <= 0 && dakamt1 === true) {
      errors.priestAmount = "Please Enter valid dakshina amount"
      // Alert({
      //   header: 'Alert',
      //   message: 'Enter valid dakshina amount',
      //   buttons: ['OK'],
      // })
    }
    if (donationamt == "" && donamt1 === true) {
      errors.donationamt = "Please Enter the donation amount to temple"
      // Alert({
      //   header: 'Alert',
      //   message: 'Enter the donation amount to temple',
      //   buttons: ['OK'],
      // })
    }
    else if (donationamt <= 0 && donamt1 === true) {
      errors.donationamt = "Please Enter valid donation amount"
      // Alert({
      //   header: 'Alert',
      //   message: 'Enter valid donation amount',
      //   buttons: ['OK'],
      // })
    }
    if (amountCheck > amountLimit) {
      Alert({
        header: "Atmagram",
        message: "Maximum Transaction limit is 500000",
        backdropDismiss: false,
        buttons: [
          {
            text: "ok",
          }
        ]
      })
    }
    else {
      if (Object.keys(errors).length === 0) {
        console.log(dates)
        const sortedDates = dates
          .map((e: any) => new Date(e.value))
          .sort((a: any, b: any) => a - b);
        setSortDates(sortedDates)
        var ff = moment(fdate).format('DD-MM-YYYY')
        setffdate(ff);
        var tt = moment(tdate).format('DD-MM-YYYY')
        setttdate(tt);
        // setIndex1(4);
        setId(id)
        console.log("id", id)
        // calDelCharge(usertoken);
        pinvalid()
      }

    }
    setFormErrors(errors);
  }
  const [totalpaidamt, setTotalpaidamt] = useState("")
  const [disableCnfrmbtn, setDisableCnfrmbtn] = useState(false)

  const hanldeFacilation = (e: any) => {
    var num = e.detail.value || 0;
    if (parseInt(num) >= actualfacilitationCharge) {
      setDisableCnfrmbtn(false)
    } else {
      setDisableCnfrmbtn(true)
    }
    setFacilitationCharge(parseInt(num));
    setTotalOrderAmt(totalPoojaAmt + templeAmt + parseInt(num) + poojari + tantriAmt)
  }

  const pinvalid = () => {
    if (deladd) {
      Authservice.pinValid(pincode).then((response: any) => {
        console.log(response.data, "gcf");
        if (response.data.startsWith("SUCCESS")) {
          // if (usertoken == null) {
          //   setLoginmodal(true)
          // } else {
          calDelCharge(usertoken);
          //}
        } else {
          Alert("Delivery not available for this Pincode")
        }
      })
        .catch((error: any) => {
          console.log(error);
          setShowLoading(false);
        });
    } else {
      // if (usertoken == null) {
      //   setLoginmodal(true)
      // } else {
      calDelCharge(usertoken);
      setIndex1(5);
      setHeadname("Home,Booking Details")
      setActionFlag(false)
      //}
    }
  }

  const calDelCharge = (token: any) => {
    setShowLoading(true);
    Authservice.calDelChargeForOffer(token, details).then((response: any) => {
      console.log(response.data, "gcf");
      var data = response.data;
      if (data.deliveryError === null || data.deliveryError === "") {
        setDelCharge(data.pdDelCharge)
        setTotalpaidamt(data.totalPaidAmount)
        setDelChargePerDay(data.delChargePerDay)
        setShowLoading(false);
        setIndex1(5);
        setHeadname("Home,Booking Details")
        setActionFlag(false)
      }
      else {
        Alert("Delivery service not available to this pincode. sorry for the inconvenience.")
        setShowLoading(false);
      }
    })
      .catch((error: any) => {
        console.log(error);
        setShowLoading(false);
      });
  }
  const [dakamt1, setDakamt1] = useState(false)
  const inputRef = useRef<any>(null);
  const inputRefPooja = useRef<any>(null);

  const [donamt1, setDonamt1] = useState(false)
  const inputRef1 = useRef<any>(null);
  const donamt = (e: any) => {
    if (e.detail.checked) {
      setDon(true)
      setDonamt1(true)
      setPaydakshinatemple(true)
      setTimeout(() => {
        inputRef1.current?.setFocus();
      }, 1000);
    }
    else {
      setDon(false)
      setDonamt1(false)
      setPaydakshinatemple(false)
      setDonationamt("");
      setFormErrors((prev: any) => {
        return { ...prev, donationamt: "" }
      })
    }
  }

  const goBackToPoojas = () => {
    setIndex1(1);
    settantriAmt(0);
    setTempleAmt(0)
    setTotalOrderAmt(0)
    setFacilitationCharge(actualfacilitationCharge)
    setPoojaRi(0)
  }
  const handleBreadcrumbClick = (index: any) => {
    console.log(index, "index")
    setIndex1(index);
  };
  const handlechangeMonth = (e: any) => {
    var round = 0
    round = Math.round(e.target.value)
    setMonth(round)
  }
  const Decrement = () => {
    if (month > 0) {
      setMonth(month - 1);
    }
  }
  const Orders = () => {
    setIndex(2)
    Authservice.MyOrder(usertoken).then((respone: any) => {
      console.log(respone)
      setMyOrder(respone.data);
    })
  }
  // const Cart = () => {
  //   setIndex(3)
  //   Authservice.Addcart(usertoken).then((response: any) => {
  //     setcart(response.data)
  //     console.log(response.data)
  //     setPaymentData(response.data);
  //   })
  // }
  const savebook = (e: any) => {

    var guestTkn: any = "";
    var token: any = sessionStorage.getItem("guestTkn");

    if (e == null) {

      if (token == null || token == undefined || token == "") {
        token = uuidv4();
        sessionStorage.setItem("guestTkn", token);
      }

      guestTkn = token;
    }
    setActionFlag(true);
    console.log(details)
    Authservice.AddToCartInit(details, e, guestTkn).then((response) => {
      if (response.data === "Success") {
        if (e != null) {
          history.push("/mycart")
        } else {
          Alert("Item added to cart")
          history.push("/offerings")
        }

      }

    })
      .catch((error) => {
        Alert(error)

      });
  }

  var paymentdetails: any = {}
  if (radio === "1") {
    if (radiodel === "false") {
      var delAdd = "N/A"
    }
    else {
      var delAdd = delivery + "," + pincode;
    }
    paymentdetails = {
      "firstName": username,
      "fromDate": from,
      "toDate": to,
      "star": star,
      "mailId": email,
      "mobileNumber": phone,
      "deliveryAddress": delAdd,
      "pdDelCharge": delCharge,
      "prasadhamDelFlag": radiodel,
      "poojaOfferings": { "id": offerId, "price": dprice },
      "totalPaidAmount": totalpaidamt,
      //  "listOfDates":dates,
      "payDakshinaToPriestFlag": paydakshinapreist,
      "payDakshinaToTempleFlag": paydakshinatemple,
      // "dakshinaAmountForPriest": dakshinaamt,
      // "dakshinaAmountToTemple": donationamt

    }
  } else if (radio === "2") {
    if (radiodel === "false") {
      var delAdd = "N/A"
    }
    else {
      var delAdd = delivery + "," + pincode;
    }
    paymentdetails = {
      "firstName": username,
      "star": star,
      "mailId": email,
      "mobileNumber": phone,
      "deliveryAddress": delAdd,
      "pdDelCharge": delCharge,
      "prasadhamDelFlag": radiodel,
      "poojaOfferings": { "id": offerId, "price": dprice },
      "payDakshinaToPriestFlag": paydakshinapreist,
      "payDakshinaToTempleFlag": paydakshinatemple,
      "noOfMonths": month,
      "totalPaidAmount": totalpaidamt,
      // "dakshinaAmountForPriest": dakshinaamt,
      // "dakshinaAmountToTemple": donationamt
    }
  } else if (radio === "3") {
    if (radiodel === "false") {
      var delAdd = "N/A"
    }
    else {
      var delAdd = delivery + "," + pincode;
    }
    const datesList = listdates.map((e: any) => new Date(e))
      .sort((a: any, b: any) => a - b)
    const listofdates = datesList.map((e: any) => moment(e).format("YYYY-MM-DDThh:mm:ss"))
    paymentdetails = {
      "firstName": username,
      "star": star,
      "mailId": email,
      "mobileNumber": phone,
      "deliveryAddress": delAdd,
      "prasadhamDelFlag": radiodel,
      "pdDelCharge": delCharge,
      "poojaOfferings": { "id": offerId, "price": dprice },
      "listOfDates": listofdates,
      "payDakshinaToPriestFlag": paydakshinapreist,
      "payDakshinaToTempleFlag": paydakshinatemple,
      "totalPaidAmount": totalpaidamt,
      // "dakshinaAmountForPriest": dakshinaamt,
      // "dakshinaAmountToTemple": donationamt
    }
  }

  if (paydakshinatemple) {
    paymentdetails["dakshinaAmountToTemple"] = donationamt;
  }
  if (paydakshinapreist) {
    paymentdetails["dakshinaAmountForPriest"] = dakshinaamt;
  }



  const proceedtopayment = (e: any) => {
    setActionFlag(true)
    console.log(paymentdetails, "sdfsdf")
    setShowLoading(true);
    Authservice.Payinitoffering(paymentdetails, e).then((response: any) => {
      console.log(response.data, "dataa")
      if (response.data.errors === null) {

        var options: any = {
          key: response.data.pgOption.key,
          key_secret: response.data.secretKey,
          amount: response.data.pgOption.amount,
          currency: response.data.pgOption.currency,
          name: response.data.pgOption.name,
          order_id: response.data.pgOption.order_id,
          description: response.data.pgOption.description,
          modal: {
            escape: false, ondismiss: function () {
              Alert("Payment Failed")
            }
          },
          handler: function (res: any) {
            // alert(res.razorpay_payment_id+"/"+res.razorpay_order_id+res.razorpay_signature);
            var resData: any = {
              "amount": response.data.pgOption.amount,
              "orderId": res.razorpay_order_id,
              "cartPayRefId": response.data.cartPayRefId,
              "signature": res.razorpay_signature,
              "paymentId": res.razorpay_payment_id
            }
            console.log("resData", resData);
            Authservice.Payresoffering(resData, e)
              .then((response) => {
                console.log("payresponse" + response);
                Alert({
                  header: "Atmagram",
                  message: "Order placed successfully, Please visit My Offerings",
                  backdropDismiss: false,
                  buttons: [
                    {
                      text: "ok",
                      handler: () => {
                        window.location.reload()
                      }
                    }
                  ]
                })
              })
              .catch((error) => {
                Alert({
                  header: "Atmagram",
                  message: "Something went wrong, please try again later.",
                  backdropDismiss: false,
                  buttons: [
                    {
                      text: "ok",
                    }
                  ]
                })
              });

          },
          prefill: {
            name: response.data.pgOption.name,
            email: response.data.email,
            contact: response.data.contact

          },

          theme: {
            color: response.data.theme
          }
        };
        var pay: any = new Razorpay(options)
        pay.on("payment.failed", function (data: any) {
          Alert({
            header: "Atmagram",
            message: data.error.reason,
            backdropDismiss: false,
            buttons: [
              {
                text: "ok",
              }
            ]
          })

        });
        pay.on("payment.cancelled", function (data: any) {
          Alert(data.error.reason);
          // Additional handling or redirection logic can be added here if needed.
        });
        pay.open()
        setShowLoading(false);
      }
      else {
        setShowLoading(false);
        Alert({
          header: "Atmagram",
          message: response.data.errors[0],
          backdropDismiss: false,
          buttons: ["Ok"]
        })
      }
    })
      .catch((error) => {
        Alert(error)
        setShowLoading(true);

      });
  }
  const getBtnId = (e: any) => {
    console.log(e.target.value, "deityselect")
    if (offerings.length > 0) {
      setDeityOffer([])
      setDeityId(e.target.value)
      for (let i = 0; i < offerings.length; i++) {
        console.log(e.target.value === offerings[i].deityDto.id, "checkkkk")
        console.log(offerings[i].deityDto.id, "offfff")
        if (e.target.value === offerings[i].deityDto.id) {
          console.log(offerings[i].deityDto, "dtoooo")
          setDeityname(offerings[i].deityDto.deityName)
          setDeityOffer(offerings[i].poojaOfferings)
        }
      }
    }
  };

  const [offtiming, setOfftiming] = useState("")
  const [offdescription, setOffdecription] = useState("")
  const [infomodal, setInfomodal] = useState(false)

  const informationview = (item: any) => {
    console.log(item, "itemmm")
    setOffername(item.poojaName)
    setOfftiming(item.timeOfPooja)
    setOffdecription(item.poojaDescription)
    setInfomodal(true)
  }

  const fromdate = (e: any) => {
    setFdate(e.detail.value)
    setTdate(e.detail.value)
    var ii = e.detail.value;
    var str = moment(ii).format('YYYY-MM-DDThh:mm:ss')
    setFrom(str)
    setTo(str)
  }
  const delCart = (e: any) => {
    Authservice.removeCart(usertoken, e.id).then((response) => {
      // Cart();
      if (response.data.resposeSuccess) {
        Alert('Item removed from cart')
        // Cart();
      }

    })
      .catch((error) => {
        Alert(error)

      });

  }
  const todate = (e: any) => {
    setTdate(e.detail.value)
    var ii = e.detail.value;
    var str = moment(ii).utcOffset('+05:30').format('YYYY-MM-DDThh:mm:ss')
    setTo(str)
  }
  const setPaymentData = (list: any) => {
    setOrderList([]);
    for (let order of list) {
      var data: any = {
        "id": order.id,
        "userModel": {
          "id": order.userModel.id
        },
        "poojaOfferings": {

          "id": order.poojaOfferings.id,
          "price": order.poojaOfferings.price
        },
        "firstName": order.firstName,
        "mailId": order.mailId,
        "mobileNumber": order.mobileNumber,
        "deliveryAddress": order.deliveryAddress,
        "prasadhamDelFlag": order.prasadhamDelFlag,
        "fromDate": order.fromDate,
        "toDate": order.toDate,
        "payDakshinaToPriestFlag": order.payDakshinaToPriestFlag,
        "payDakshinaToTempleFlag": order.payDakshinaToTempleFlag,
        // "dakshinaAmountForPriest": order.dakshinaAmountForPriest,
        // "dakshinaAmountToTemple": order.dakshinaAmountToTemple,
        "delFlag": order.delFlag,
        "noOfMonths": order.noOfMonths
      }
      console.log(data)
      if (order.payDakshinaToTempleFlag) {
        data["dakshinaAmountToTemple"] = order.dakshinaAmountToTemple;
      }
      if (order.payDakshinaToPriestFlag) {
        data["dakshinaAmountForPriest"] = order.dakshinaAmountForPriest;
      }

      // setOrderList([...orderList,data])
      //     var finalData=  {
      //         "userCartDetailDtoLst":data
      // }

      // setPayData(finalData);
      //  setPayData(finalData) //
      // setPayData([...payData,data])

      // console.log("p;ay",paymentData);
      payData.push(data)


    }


  }
  const proceedPayment = () => {

    console.log(payData)
    var obj = { "userCartDetailDtoLst": payData }

    console.log(paymentdata)

    Authservice.payInit(usertoken, obj).then((response: any) => {


      console.log(response.data)
      window.location.reload()
    })

  }


  const [index, setIndex] = useState(0);
  const preventdata = (e: any) => {
    e.target.addEventListener("wheel", function (s: any) { s.preventDefault() }, { passive: false })
    e.target.addEventListener('keydown', function (u: any) {
      if (u.which === 38 || u.which === 40) {
        u.preventDefault();
      }
    })
  }
  const sameas = (e: any) => {
    const checkboxElement: any = checkboxRef.current;

    if (e.detail.checked) {
      console.log(e, "eeeee")
      setChe(true)
      console.log(userAddress, "useraddress")
      if (userAddress != null && userAddress != "") {
        var pin = add.split(',');
        var arrLlen = pin.length - 1;
        var address: any = "";
        for (var a = 0; a < arrLlen; a++) {
          if (a == 0) {
            address = address + pin[a];

          } else {
            address = address + "," + pin[a];
          }

        }
        if (checkboxElement) {
          checkboxElement.setAttribute('aria-checked', String(true));
          checkboxElement.setAttribute('checked', String(true));
        }
        setDelivery(address)
        setPin(pin[arrLlen])
        console.log(che, "ssvsfsvf")
      } else {
        setChe(false)
        if (checkboxElement) {
          checkboxElement.setAttribute('aria-checked', String(!checkboxElement.checked));
          checkboxElement.setAttribute('checked', String(!checkboxElement.checked));
        }
        console.log(che, "111")
        Alert("Enter Address Details at My Profile")

      }

    } else {
      if (checkboxElement) {
        checkboxElement.setAttribute('aria-checked', String(false));
        checkboxElement.setAttribute('checked', String(false));
      }
      setChe(false)
      setDelivery("")
      setPin('')
    }

  }
  const openmodal = () => {
    setModalopen(true)
  }
  const openmodal1 = () => {
    setModalopen1(true)
  }
  const [delcrgField, setDelcrgField] = useState(false)
  const Radio = (e: any) => {
    setRadiodel(e.detail.value)
    if (e.detail.value == "false") {
      setDeladd(false);
      setDelivery("")
      setPin('')
      setChe(false)
      setDelcrgField(false)
    }
    else {
      setDeladd(true);
      setDelcrgField(true)

    }
  }
  const numberOnlyValidation = (event: any) => {
    const pattern = /[0-9,]/;
    let inputChar = String.fromCharCode(event.charCode);

    if (!pattern.test(inputChar)) {
      // invalid character, prevent input
      event.preventDefault();
    }
  }

  const [des, setDes] = useState("")
  const [time, setTime] = useState("")
  const [offeringName, setOffeingrName] = useState("");
  const [desmodal, setdesmodal] = useState(false)
  const View = (item: any) => {
    var time: any;
    if (item.offeringTime != null) {
      if (item.offeringTime.includes("||")) {
        time = (item.offeringTime).split("||").join(",")
      }
      else if (item.offeringTime.includes("|")) {
        time = (item.offeringTime).split("|").join(",");
      }
      else {
        time = item.offeringTime
      }
    }
    setOffeingrName(item.name);
    setDes(item.description);
    setTime(time)
    setdesmodal(true);
  }

  const useOutsideAlerter = (refer: any, offref: any, tempdonref: any, donref: any, logref: any, signref: any) => {
    useEffect(() => {
      function handleClickOutside(event: any) {
        if (refer.current && !refer.current.contains(event.target)) {
          setIsshow(false)
        }
        if (offref.current && !offref.current.contains(event.target)) {
          setdesmodal(false)
        }
        if (tempdonref.current && !tempdonref.current.contains(event.target)) {
          setModalopen1(false)
        }
        if (donref.current && !donref.current.contains(event.target)) {
          setModalopen(false)
        }
        if (logref.current && !logref.current.contains(event.target)) {
          setLoginmodal(true)
        }
        if (signref.current && !signref.current.contains(event.target)) {
          setSignupmodal(true)
        }
      }
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      }
    }, [refer]);
  }
  const deitymodal: any = useRef(null);
  const offeringmodal = useRef(null)
  const templedonationmodal = useRef(null);
  const donationmodal = useRef(null)
  const loginmodalref = useRef(null);
  const signupmodalref = useRef(null)

  useOutsideAlerter(deitymodal, offeringmodal, templedonationmodal, donationmodal, loginmodalref, signupmodalref);
  const [logemail, setLogemail] = useState("")
  const [password, setPassword] = useState("");
  const [loginphone, setLoginPhone] = useState("")
  const [loginmodal, setLoginmodal] = useState(false)
  const [loginIndex, setLoginIndex] = useState(0);
  const [showOtp, setShowOtp]: any = useState(false);
  const [otp, setOtp] = useState("");
  const [confirmationResult, setconfirmationResult]: any = useState();
  const [isValidPhoneNumber, setIsValidPhoneNumber] = useState(false);
  const [loginalertmodal, setLoginalertmodal] = useState(false)
  const [loginalertdata, setLoginalertdata] = useState("")

  const phoneLogin = () => {
    setLoginIndex(1)

  }
  const emailLogin = () => {
    setLoginIndex(0)
  }

  const loginalertmodalaction = (e: any, data: any) => {
    setLoginalertmodal(e)
    setLoginalertdata(data)
  }

  const alertmodalclose = () => {
    if (loginalertdata === "Register Successfully") {
      setLoginmodal(true)
      setLoginalertmodal(false)
    }
    else {
      setLoginalertmodal(false)
    }
    setLoginalertdata("")
  }

  const checkMobile = () => {
    const errors: any = {};
    if (loginphone === undefined || loginphone === "") {
      errors.loginPhone = "Mobile Number is required"
    } else if (!isValidLoginNumber) {
      errors.loginPhone = "Enter valid Mobile Number"
    }
    setFormErrors(errors);
    console.log(formErrors)
    if (Object.keys(errors).length === 0) {
      setShowLoading(true);
      UserService.validMob(loginphone).then((res) => {
        console.log(res, "otpres")
        if (res.data === true) {
          getOTP();
          setShowLoading(false);
        } else {
          setShowLoading(false);
          // alert("Please complete registration. Then only you can login with Atmagram")
          loginalertmodalaction(true, "Please complete registration. Then only you can login with Atmagram")
          // Alert({ header: 'Atmagram', message: "Please complete registration. Then only you can login with Atmagram",buttons: ['OK'], cssClass: 'custom-alert' })
        }

      }).catch((error) => {
        console.log(error);
        setShowLoading(false);
      });
    }
  }

  const getOTP = () => {
    let verify = new RecaptchaVerifier('recaptcha-container', {
      'size': 'normal',
      'callback': (response: any) => {
        // reCAPTCHA solved, allow signInWithPhoneNumber.
        // ...
      },
      'expired-callback': () => {
        // Response expired. Ask user to solve reCAPTCHA again.
        // ...
        // alert("Response expired. Please solve reCAPTCHA again")
        loginalertmodalaction(true, "Response expired. Please solve reCAPTCHA again")
      }
    }, auth);

    signInWithPhoneNumber(auth, loginphone, verify)
      .then((confirmationResult) => {
        // SMS sent. Prompt user to type the code from the message, then sign the
        // user in with confirmationResult.confirm(code).
        console.log(confirmationResult);
        setShowOtp(true)
        setconfirmationResult(confirmationResult)
        // ...
      }).catch((error) => {
        // Error; SMS not sent
        loginalertmodalaction(true, "Something went wrong, please try again later.")
        // alert("Something went wrong, please try again later.")
        // ...
      });
  }

  const verifyOTP = () => {
    // var credential = PhoneAuthProvider.credential(confirmationResult.verificationId, otp);
    // console.log(credential);
    const errors: any = {};
    if (otp === undefined || otp === "" || otp == null) {
      errors.otp = "Enter OTP"
    }
    setFormErrors(errors);
    console.log(formErrors)
    if (Object.keys(errors).length === 0) {
      confirmationResult.confirm(otp).then((result: any) => {
        // User signed in successfully.
        const user = result.user;
        console.log(result);
        setShowLoading(true);
        UserService.mobileLogin(loginphone)
          .then((response) => {
            if (response.data.responseText.startsWith("SUCCESS")) {
              if (response.data.responseText.startsWith("SUCCESS")) {
                // if (Capacitor.getPlatform() === "web") {
                //   console.log(response, "ressss")
                if (response.data.userModel.role !== "ROLE_USER") {
                  // Alert("This Account is not a user")
                  loginalertmodalaction(true, "This Account is not a user")
                }
                else
                  if (response.data.userModel.uflag === "false") {
                    console.log(response.data.userModel.uflag, "uflag")
                    // Alert("Account Not Approved Yet")
                    loginalertmodalaction(true, "Account Not Approved Yet")
                  }
                  else {
                    sessionStorage.setItem("token", response.data.userToken);
                    sessionStorage.setItem("UserId", response.data.userModel.id);
                    sessionStorage.setItem("UserData", response.data.userModel.role);
                    sessionStorage.setItem("uFlag", response.data.userModel.uflag);
                    sessionStorage.setItem("Email", response.data.userModel.email);
                    sessionStorage.setItem("Phone", response.data.userModel.mobileNumber);
                    setUFlag(response.data.userModel.uflag)
                    setToken(response.data.userToken)
                    setUserData(response.data.userModel.role)
                    if (response.data.userModel.firstName !== null) {
                      sessionStorage.setItem("Name", response.data.userModel.firstName);
                    }
                    if (response.data.userModel.address !== null) {
                      sessionStorage.setItem("Address", response.data.userModel.address);
                    }
                    setUserdata(response.data.userModel.role)
                    setUserAddress(response.data.userModel.address);
                    setAdd(response.data.userModel.address)
                    setUsertoken(response.data.userToken)
                    setLoginmodal(false)
                    const sortedDates = dates
                      .map((e: any) => new Date(e.value))
                      .sort((a: any, b: any) => a - b);
                    setSortDates(sortedDates)
                    console.log(response.data.userToken)
                    var ff = moment(fdate).format('DD-MM-YYYY')
                    setffdate(ff);
                    var tt = moment(tdate).format('DD-MM-YYYY')
                    setttdate(tt);
                    setActionFlag(false)
                    setId(id)
                    console.log("id", id)
                    Alert({
                      header: "Atmagram",
                      message: "LoggedIn Successfully",
                      backdropDismiss: false,
                      buttons: [
                        {
                          text: "ok",
                          handler: () => {
                            if (payFlag == 1) {
                              savebook(response.data.userToken)
                            } else if (payFlag == 2) {
                              proceedtopayment(response.data.userToken)
                            }
                          }
                        }
                      ]
                    })
                    // bookpayment(response.data.userToken);
                  }
              }
              // }
              else {
                // Alert(response.data.responseText.split("ERROR:")[1]);
                loginalertmodalaction(true, response.data.responseText.split("ERROR:")[1])
              }
            } else {
              // alert(response.data.responseText.split("ERROR:")[1]);
              loginalertmodalaction(true, response.data.responseText.split("ERROR:")[1])
            }
            setShowLoading(false);
          })
          .catch((error) => {
            console.log(error);
            setShowLoading(false);
          });

        // ...
      }).catch((err: any) => {
        // User couldn't sign in (bad verification code?)
        console.log(err.error);
        // alert("Not a valid verification code or Code expired, please try again")
        loginalertmodalaction(true, "Not a valid verification code or Code expired, please try again")
        // if(error.error.message=='SESSION_EXPIRED'){
        //   alert("OTP expired");
        // }else{

        // }
        // ...
      });
    }
  }


  const goBack = () => {
    setShowOtp(false)
  }

  const loginmodalclose = () => {
    setLoginmodal(false)
    setLoginIndex(0)
    // setLogemail("")
    // setPassword("")
    // setLoginPhone("")
    // setFormErrors({})
  }
  const signupmodalclose = () => {
    setSignupmodal(false);
    setLoginmodal(true);
    // setFormErrors({})
  }
  const Loginsubmit = () => {
    const errors: any = {};

    if (logemail.trim() === '') {
      errors.logemail = 'Email is required';
    }
    else if (validator.isEmail(logemail) === false) {
      errors.logemail = "Enter Valid Email Address";
    }

    if (password.trim() === '') {
      errors.password = 'Password is required';
    }

    setFormErrors(errors);

    if (Object.keys(errors).length === 0) {
      UserService.Login(logemail, password, "")
        .then((response) => {
          setShowLoading(false);

          if (response.data.responseText.startsWith("SUCCESS")) {
            // if (Capacitor.getPlatform() === "web") {
            //   console.log(response.data)
            if (response.data.userModel.role !== "ROLE_USER") {
              loginalertmodalaction(true, "This Account is not a user")
            }
            else
              if (response.data.userModel.uflag === "false") {
                console.log(response.data.userModel.uflag, "uflag")
                loginalertmodalaction(true, "Account Not Approved Yet")
              }
              else {
                sessionStorage.setItem("token", response.data.userToken);
                sessionStorage.setItem("UserId", response.data.userModel.id);
                sessionStorage.setItem("UserData", response.data.userModel.role);
                sessionStorage.setItem("uFlag", response.data.userModel.uflag);
                sessionStorage.setItem("Email", response.data.userModel.email);
                sessionStorage.setItem("Phone", response.data.userModel.mobileNumber);
                setUFlag(response.data.userModel.uflag)
                setToken(response.data.userToken)
                setUserData(response.data.userModel.role)
                if (response.data.userModel.firstName !== null) {
                  sessionStorage.setItem("Name", response.data.userModel.firstName);
                }
                if (response.data.userModel.address !== null) {
                  sessionStorage.setItem("Address", response.data.userModel.address);
                }

                setUserdata(response.data.userModel.role)
                setUserAddress(response.data.userModel.address);
                setAdd(response.data.userModel.address)
                setUsertoken(response.data.userToken)
                setLoginmodal(false)
                const sortedDates = dates
                  .map((e: any) => new Date(e.value))
                  .sort((a: any, b: any) => a - b);
                setSortDates(sortedDates)
                console.log(response.data.userToken)
                var ff = moment(fdate).format('DD-MM-YYYY')
                setffdate(ff);
                var tt = moment(tdate).format('DD-MM-YYYY')
                setttdate(tt);
                setIndex1(5);
                setActionFlag(false)
                setId(id)
                console.log("id", id)
                //calDelCharge(response.data.userToken);
                Alert({
                  header: "Atmagram",
                  message: "LoggedIn Successfully",
                  backdropDismiss: false,
                  buttons: [
                    {
                      text: "ok",
                      handler: () => {
                        if (payFlag == 1) {
                          savebook(response.data.userToken)
                        } else if (payFlag == 2) {
                          proceedtopayment(response.data.userToken)
                        }
                      }
                    }
                  ]
                })
              }
            // sessionStorage.setItem("Address", response.data.userModel.address);
            // sessionStorage.setItem("Name", response.data.userModel.firstName + response.data.userModel.lastName);
            console.log(response.data.userModel.uflag);
          }
          //  }
          else {
            loginalertmodalaction(true, response.data.responseText.split("ERROR:")[1])
          }
        })
        .catch((error) => {
          setLoginmodal(true)
          console.log(error);
          setShowLoading(false);
        });
    }
  };
  const mailpat = /[a-zA-Z0-9]+[\.]?([a-zA-Z0-9]+)?[\@][a-z]{3,9}[\.][a-z]{2,5}/g;


  const Searchlog = (value: any) => {
    if (value == "Enter") {
      // if (logemail.length === 0) {
      //   alert("Enter Email Address")
      // }
      // else if (validator.isEmail(logemail) === false) {
      //   alert("Enter Valid Email Address")
      // }
      // else if (password.length == 0) {
      //   alert("Enter your Password")
      // }
      // else {
      Loginsubmit()
      // }
    }
  }

  const customStyles = {
    control: (provided: any) => ({
      ...provided,
      border: "1px solid black",
      boxShadow: 'none',
      fontSize: '15px',
      // height: '44px',
      width: '90%',
      outline: 'none',
      textAlign: "left"
    }),
  };

  const handleCountryChange = (e: any) => {
    console.log(e)
    if (e) {
      setCountryOption(e);
      setCountry(e.value);
    } else {
      setCountryOption(null)
      setCountry("")
    }
  }


  const phoneInputRef = useRef(null)
  const loginphoneRef = useRef(null)
  const [isValidLoginNumber, setIsValidLoginNumber] = useState(false);

  const moveCursorToEnd = (value: any) => {
    const input: any = phoneInputRef.current;
    if (window.innerWidth <= 500) {
      if (input) {
        setTimeout(() => {
          input.focus();
          input.setSelectionRange(input.value.length, input.value.length);
        }, 0);
      }
    }
    try {
      const phoneNumberObject: any = parsePhoneNumberFromString(value);
      setIsValidPhoneNumberSign(phoneNumberObject ? phoneNumberObject.isValid() : false);
    } catch (error) {
      setIsValidPhoneNumberSign(false);
      console.log(error)
    }
    setPhone1(value)
  };

  const loginmoveCursorToEnd = (value: any) => {
    const input: any = loginphoneRef.current;
    if (window.innerWidth <= 500) {
      if (input) {
        setTimeout(() => {
          input.focus();
          input.setSelectionRange(input.value.length, input.value.length);
        }, 0);
      }
    }
    try {
      const phoneNumberObject: any = parsePhoneNumberFromString(value);
      setIsValidLoginNumber(phoneNumberObject ? phoneNumberObject.isValid() : false);
      if (value === '' || value === undefined) {
        setFormErrors({ ...formErrors, loginPhone: "Mobile Number is required" })
      }
      else if (!(phoneNumberObject ? phoneNumberObject.isValid() : false)) {
        setFormErrors({ ...formErrors, loginPhone: "Please Enter Valid Mobile Number" })
      }
      else {
        setFormErrors({ ...formErrors, loginPhone: "" })
      }
    } catch (error) {
      setIsValidLoginNumber(false);
      console.log(error)
    }
    setLoginPhone(value)
  };

  const [message, setMessage] = useState("");
  const [iserror, setIserror] = useState(false);

  const namecheck = /^[a-zA-Z\s]*$/
  const submit = () => {
    const errors: any = {};

    if (firstname1 == "") {
      errors.firstname1 = "Enter First Name"
    }
    else if (namecheck.test(firstname1) == false) {
      errors.firstname1 = 'Alphabets only Allowed in First Name'
    }
    else if (firstname1.charAt(0) == " ") {
      errors.firstname1 = 'Empty Space at First Name'
    }
    if (phone1 === '' || phone1 === undefined) {
      errors.phone1 = "Mobile Number is required"
    } else if (!isValidPhoneNumberSign) {
      errors.phone1 = "Please Enter Valid Mobile Number"
    }
    // else if (pincode.length !== 6) {
    //   alert("Enter Valid Pincode")
    // }
    if (email1.trim() === '') {
      errors.email1 = 'Email is required';
    }
    else if (validator.isEmail(email1) === false) {
      errors.email1 = "Enter Valid Email Address";
    }
    // else if (aadhar.length !== 12) {
    //   alert("Enter Valid Aadhar Number")
    // }
    if (password1.trim() === '') {
      errors.password1 = 'Password is required';
    }
    if (confirmpassword.trim() === '') {
      errors.confirmpassword = 'Confirm Password is required';
    }
    else if (password1 != confirmpassword) {
      errors.confirmpassword = "Password and confirm password are not same ";
    }
    setFormErrors(errors);
    if (Object.keys(errors).length === 0) {
      setShowLoading(true);
      UserService.Registermaterials(
        // aadhar1,
        // addr,
        // pincode1,
        // area1,
        // city1,
        // state1,
        // country1,
        email1,
        firstname1,
        // lastname1,
        phone1,
        password1,
        user,
        user
      )
        .then((response) => {
          console.log("res", response);
          setShowLoading(false);
          if (response.responseText.startsWith("SUCCESS")) {
            setIssuccess(true);
            // setAadhar1("");
            // setAddr("");
            // setArea1("");
            // setCity1("");
            setConfirmpassword("");
            // setCountry1("");
            setEmail1("");
            setFirstname1("");
            // setLastname1("");
            setPassword1("");
            setPhone1("");
            setUser("");
            // setPincode1("");
            loginalertmodalaction(true, "Register Successfully")
            setSignupmodal(false)
            console.log(response, "resss =>")
          } else if (response.responseText == "ERROR:Email Id already exist") {
            loginalertmodalaction(true, "Email Id  Exists, Try Any Other Email Id")
          } else if (response.responseText == "ERROR:MobileNumber already exist") {
            loginalertmodalaction(true, "Mobile Number  Exists, Try Any Other Mobile Number")
          } else {
            loginalertmodalaction(true, "User Not Register Successfully")
          }
        })
        .catch((error) => {
          console.log(error)
          setShowLoading(false);
          loginalertmodalaction(true, "Something went wrong")
        });
      console.log(aadhar1,
        // addr,
        // pincode1,
        // area1,
        // city1,
        // state1,
        // country1,
        email1,
        // firstname1,
        // lastname1,
        phone1,
        password1,
        user,
      )
    }
  }

  const Searchsign = () => {
    submit()
  }

  const handleDateChange = (item: any, num: any) => {
    const updatedData = datearray.map((row: any, ind: any) =>
      item.uniqueId === row.uniqueId ? { ...row, selected: !item.selected } : row
    );
    setdatearray(updatedData);
    console.log(updatedData)
    if (!item.selected) {
      setSelectedDates([...selectedDates, item]); // Add selected item
    } else {
      setSelectedDates(selectedDates.filter((i: any, index: any) => item.uniqueId !== i.uniqueId)); // Remove if unchecked
    }
  };

  const dateColumn: any = [
    {
      width: "50px",
      cell: (item: any, i: any) => (
        <div >
          <IonCheckbox checked={item.selected} onIonChange={() => handleDateChange(item, i)} />
        </div>
      )
    },

    {
      name: "Date",
      width: "130px",
      selector: (item: any, i: any) => item.date,
      sortable: true,
      cell: (item: any, i: any) => {
        return (
          <div onClick={() => handleDateChange(item, i)}>
            <div style={{ cursor: "pointer" }}>{item.date !== undefined ? item.date : ""}</div>
          </div>
        )
      }
    },
    {
      name: "Day",
      width: "130px",
      selector: (item: any) => item.actualDay,
      sortable: true,
      cell: (item: any, i: any) => (
        <div onClick={() => handleDateChange(item, i)}>
          <div style={{ cursor: "pointer" }}  >{item.actualDay}</div>
        </div>
      )
    },
    {
      name: "Star",
      width: "130px",
      selector: (item: any) => item.star,
      sortable: true,
      cell: (item: any, i: any) => (
        <div onClick={() => handleDateChange(item, i)}>
          <div style={{ cursor: "pointer" }}  >{item.star}</div>
        </div>
      )
    },
    {
      name: "Thithi",
      width: "130px",
      selector: (item: any) => item.thithi,
      sortable: true,
      cell: (item: any, i: any) => (
        <div onClick={() => handleDateChange(item, i)}>
          <div style={{ cursor: "pointer" }}  >{item.thithi}</div>
        </div>
      )
    },
    {
      name: "Special Days",
      selector: (item: any) => item.specialDays,
      sortable: true,
      cell: (item: any, i: any) => (
        <div onClick={() => handleDateChange(item, i)}>
          <div style={{ cursor: "pointer" }}  >{item.specialDays}</div>
        </div>
      )
    },

  ]

  const columns: any = [
    {
      name: "Code",
      selector: (item: any, i: any) => item.templeCode,
      sortable: true,
      width: "130px",
      cell: (item: any) => (
        <div >
          <div style={{ cursor: "pointer" }} onClick={() => offeringDetails(item, poojaSearchText, 0)}>{item.templeCode}</div>
        </div>
      )
    },
    {
      name: "Name",
      selector: (item: any) => item.firstName,
      sortable: true,
      // width: "400px",
      cell: (item: any) => (
        <div >
          <div style={{ cursor: "pointer" }} onClick={() => offeringDetails(item, poojaSearchText, 0)} >{item.templeName}</div>
        </div>
      )
    },
    {
      name: "Main deity",
      selector: (item: any) => item.mainDeity,
      sortable: true,
      // width: "300px",
      cell: (item: any) => (
        <div >
          <div style={{ cursor: "pointer" }} onClick={() => offeringDetails(item, poojaSearchText, 0)} >{item.mainDeity}</div>
        </div>
      )
    },
    {
      name: "Offerings",
      selector: (item: any) => item.poojaOfferings,
      sortable: true,
      cell: (item: any) => (
        <div >
          <div style={{ cursor: "pointer" }} onClick={() => offeringDetails(item, poojaSearchText, 0)}>{item.poojaOfferings}</div>
        </div>
      )
    },
    {
      name: "Antiquity",
      selector: (item: any) => item.antiquity,
      sortable: true,
      cell: (item: any) => (
        <div >
          <div style={{ cursor: "pointer" }} onClick={() => offeringDetails(item, poojaSearchText, 0)}>{item.antiquity}</div>
        </div>
      )
    },

  ]



  const offeringsColumn: any = [
    {
      width: "50px",
      cell: (item: any, i: any) => (
        <div >
          <IonCheckbox checked={item.selected} onClick={() => handleSelectedRowsChange(item, i)} />
        </div>
      )
    },

    {
      name: "Pooja Name",
      selector: (item: any) => item.poojaName,
      sortable: true,
      // width: "400px",
      cell: (item: any, i: any) => (
        <div onClick={() => handleSelectedRowsChange(item, i)}>
          <div style={{ cursor: "pointer" }} >{item.poojaOfferings !== undefined ? item.poojaName : ""}</div>
        </div>
      )
    },
    // {
    //   name: "Pooja Description",
    //   selector: (item: any) => item.poojaOfferings.description,
    //   sortable: true,
    //   // width: "400px",
    //   cell: (item: any) => (
    //     <div >
    //       <div style={{ cursor: "pointer" }} >{item.poojaOfferings !== undefined ? item.poojaOfferings.description : ""}</div>
    //     </div>
    //   )
    // },
    {
      name: "Time Of Pooja",
      selector: (item: any) => item.timeOfPooja,
      sortable: true,
      // width: "400px",
      cell: (item: any, i: any) => (
        <div onClick={() => handleSelectedRowsChange(item, i)}>
          <div style={{ cursor: "pointer", marginLeft: "10px" }} >{item.timeOfPooja}</div>
        </div>
      )
    },
    {
      name: "Prasadam Available",
      selector: (item: any) => item.prasadamAvailable,
      sortable: true,
      // width: "400px",
      cell: (item: any, i: any) => (
        <div onClick={() => handleSelectedRowsChange(item, i)}>
          <div style={{ cursor: "pointer", marginLeft: "10px" }} >{item.prasadamAvailable ? "Yes" : "No"}</div>
        </div>
      )
    },
    {
      name: "Pooja Rate",
      selector: (item: any) => item.cost,
      sortable: true,
      // width: "400px",
      cell: (item: any, i: any) => (
        <div onClick={() => handleSelectedRowsChange(item, i)}>
          <div style={{ cursor: "pointer", marginLeft: "10px" }} >{item.cost}</div>
        </div>
      )
    },
    {
      name: "Selected Dates",
      selector: (item: any, i: any) => item.selectedDates,
      sortable: true,
      width: "300px",
      cell: (item: any, i: any) => {
        var dates
        if (selectedPoojas.length > 0) {
          dates = selectedPoojas.find((e: any) => e.poojaCode === item.poojaCode)
        }
        return (
          <div onClick={() => handleSelectedRowsChange(item, i)}>
            <div style={{ cursor: "pointer", marginLeft: "10px" }} >{dates && dates.selectedDates ? selecteddatesvalue(dates.selectedDates) : ""}</div>
          </div>
        )
      }
    },

    // {
    //   name: "Daily Perform Count",
    //   selector: (item: any) => item.dailyPerformCount,
    //   sortable: true,
    //   // width: "400px",
    //   cell: (item: any) => (
    //     <div >
    //       <div style={{ cursor: "pointer" }} >{item.dailyPerformCount}</div>
    //     </div>
    //   )
    // },
  ]

  const handleprasdhamrequired = (item: any, num: any, e: any) => {
    console.log(e.target.checked)
    const updatedData = selectedPoojas.map((row: any, ind: any) =>
      ind === num ? {
        ...row, bookingDetailsDtos: {
          ...row.bookingDetailsDtos,
          prasadamRequired: e.target.checked ? "1" : "0",
        }
      } : row
    );
    setSelectedPoojas(updatedData);
    console.log(updatedData)
  }

  const selectedOfferingsColumn: any = [
    {
      width: "50px",
      cell: (item: any, i: any) => (
        <div >
          <IonCheckbox checked={item.selected} onIonChange={() => handleSelectedpoojaColumn(item, i)} />
        </div>
      )
    },
    {
      name: "Pooja Name",
      selector: (item: any) => item.poojaName,
      sortable: true,
      // width: "400px",
      cell: (item: any, i: any) => (
        <div >
          <div style={{ cursor: "pointer" }}>{item.poojaOfferings !== undefined ? item.poojaName : ""}</div>
        </div>
      )
    },

    // {
    //   name: "Start Date",
    //   selector: (item: any, i: any) => item.bookingDetailsDtos.stPoojaFrom,
    //   sortable: true,
    //   // width: "400px",
    //   cell: (item: any, i: any) => {
    //     var formatedDate: any = ""
    //     if (item.bookingDetailsDtos !== undefined) {
    //       var date = item.bookingDetailsDtos.stPoojaFrom.split(" ");
    //       formatedDate = moment(date[0]).format("DD-MM-YYYY")
    //     }
    //     return (
    //       <div >
    //         <div style={{ cursor: "pointer" }} onClick={() => handleSelectedRowsChange(item, i)}>{item.bookingDetailsDtos !== undefined ? formatedDate : ""}</div>
    //       </div>
    //     )
    //   }
    // },
    // {
    //   name: "End Date",
    //   selector: (item: any) => item.bookingDetailsDtos.stPoojaTo,
    //   sortable: true,
    //   // width: "400px",
    //   cell: (item: any, i: any) => {
    //     var formatedDate: any = ""
    //     if (item.bookingDetailsDtos !== undefined) {
    //       var date = item.bookingDetailsDtos.stPoojaTo.split(" ");
    //       formatedDate = moment(date[0]).format("DD-MM-YYYY")
    //     }
    //     return (
    //       <div >
    //         <div style={{ cursor: "pointer" }} onClick={() => handleSelectedRowsChange(item, i)}>{item.bookingDetailsDtos !== undefined ? formatedDate : ""}</div>
    //       </div>
    //     )
    //   }
    // },
    {
      name: "Date",
      selector: (item: any) => item.selectedDate,
      sortable: true,
      // width: "400px",
      cell: (item: any, i: any) => {
        return (
          <div >
            <div style={{ cursor: "pointer" }}>{item.selectedDate !== undefined ? item.selectedDate : ""}</div>
          </div>
        )
      }
    },
    {
      name: "Prasadam Delivery",
      selector: (item: any) => item.prasadamAvailable,
      sortable: true,
      // width: "400px",
      cell: (item: any, i: any) => (
        <div >
          <div style={{ cursor: "pointer" }}>{item.prasadamAvailable !== undefined && item.prasadamAvailable ? "Yes" : "No"}</div>
        </div>
      )
    },
    {
      name: "Deliver Prasadam",
      selector: (item: any) => item.bookingDetailsDtos.prasadamRequired,
      sortable: true,
      // width: "400px",
      cell: (item: any, i: any) => {
        return (
          <div >
            <IonCheckbox style={{ cursor: "pointer" }} disabled={item.bookingDetailsDtos ? (item.prasadamAvailable === false || !item.bookingDetailsDtos.prasadamDeliveryDetails) : false}
              checked={item.bookingDetailsDtos ? item.bookingDetailsDtos.prasadamRequired == "1" : false}
              onClick={(e: any) => handleprasdhamrequired(item, i, e)}></IonCheckbox>
          </div>
        )
      },
    },
    {
      name: "Pooja Rate",
      selector: (item: any) => item.cost,
      sortable: true,
      // width: "400px",
      cell: (item: any, i: any) => (
        <div >
          <div style={{ cursor: "pointer" }}>{item.cost}</div>
        </div>
      )
    },
  ]

  const conOfferingsColumn: any = [

    {
      name: "Pooja Name",
      selector: (item: any) => item.poojaOfferingsDto.name,
      sortable: true,
      // width: "400px",
      cell: (item: any) => (
        <div >
          <div style={{ cursor: "pointer" }} >{item.poojaOfferingsDto ? item.poojaOfferingsDto.name : item.poojaName ? item.poojaName : item.poojaCode}</div>
        </div>
      )
    },

    {
      name: "Pooja Date",
      selector: (item: any) => item.poojaFrom,
      sortable: true,
      // width: "400px",
      cell: (item: any) => {
        var formatedDate: any = ""
        if (item !== undefined) {
          formatedDate = moment(new Date(item.poojaFrom)).format("DD-MM-YYYY")

        }
        return (
          <div >
            <div style={{ cursor: "pointer" }} >{item !== undefined ? formatedDate : ""}</div>
          </div>
        )
      }
    },
    // {
    //   name: "End Date",
    //   selector: (item: any) => item.poojaTo,
    //   sortable: true,
    //   // width: "400px",
    //   cell: (item: any) => {
    //     var formatedDate: any = ""
    //     if (item !== undefined) {
    //       formatedDate = moment(new Date(item.poojaTo)).format("DD-MM-YYYY")
    //     }
    //     return (
    //       <div >
    //         <div style={{ cursor: "pointer" }} >{item !== undefined ? formatedDate : ""}</div>
    //       </div>
    //     )
    //   }
    // },
    {
      name: "Prasadam Delivery",
      selector: (item: any) => item.prasadamRequired,
      sortable: true,
      // width: "400px",
      cell: (item: any) => (
        <div >
          <div style={{ cursor: "pointer" }} >{item !== undefined && item.prasadamRequired == "1" ? "Yes" : "No"}</div>
        </div>
      )
    },
    {
      name: "Pooja Rate",
      selector: (item: any) => item.poojaAmount,
      sortable: true,
      // width: "400px",
      cell: (item: any) => (
        <div >
          <div style={{ cursor: "pointer" }} >{item.poojaAmount}</div>
        </div>
      )
    },
  ]

  const customTableStyle1 = {
    ...customTableStyle,
    table: {
      style: {
        border: "0.5px solid #E5E3E3"
      },
    },
    headRow: {
      style: {
        borderWidth: "0px",
        backgroundColor: "#69B5DE"
      },
    },
    headCells: {
      style: {
        fontWeight: "bold",
        fontSize: "15px",
        paddingLeft: '16px',
        paddingRight: '16px',
        border: "none",
        justifyContent: "left",
        textalign: "left",
        backgroundColor: "#69B5DE !important"
      },
    },
    rows: {
      style: {
        borderWidth: "0.5px #E5E3E3",
        padding: "0px"
      }
    },
    cells: {
      style: {
        fontSize: "16px",
        justifyContent: "left",
        paddingLeft: '16px',
        paddingRight: '16px',
        paddingTop: '0px',
        paddingBottom: '0px',
        wordBreak: 'break-word',
        border: "none",
      }
    },
  }

  const paginationComponentOptions = {
    selectAllRowsItem: true,
    selectAllRowsItemText: "ALL"
  };


  const modalClose = () => {
    if (!mailVerifyFlag) {
      history.push("/profile")
    }
    else if (userID[0] && index1 !== 0 && index1 !== 1) {
      window.location.reload()
    }
    else if (userID[0]) {
      setCommonmodal(false)
    } else {
      history.push("/")
    }
  }


  const getMessagesByCategory = () => {
    Authservice.getMessagesByCategory(userID[0], usertoken, usertoken, userID[0] ? "Offering" : "OfferPooja").then((res: any) => {
      if (res.data !== "" && res.data !== null) {
        setCommonModalMesg(res.data)
        setCommonmodal(true)
        setCommonmodaltitle("Information")
        setShowLoading(false)
      }
    })
      .catch((error: any) => {
        console.log(error, "error")
        setShowLoading(false)
      })
  }




  const getAppConfigs = () => {
    Authservice.getAppConfigs(usertoken, userID[0], "").then((res: any) => {
      if (res.data !== "" && res.data !== null) {
        const isEmailVerified = res.data.some((item: any) => item.name === "EmailVerification" && item.value !== "False");
        if (isEmailVerified) {
          checkEmailVerify();
          return;
        }
        res.data.map((item: any) => {
          if (item.name === "NoOfDaysForPooja") {
            setNoOfDays(parseInt(item.value))
          }
        })
        getdatas()
        setShowLoading(false)
      }
    })
      .catch((error: any) => {
        console.log(error, "error")
        setShowLoading(false)
      })
  }

  const offeringDetails = (item: any, str: any, from: any) => {
    var code = from == 1 ? item : item.templeCode
    Authservice.getPoojaOfferingDetails(usertoken, userID[0], usertoken, code, str).then((response: any) => {
      console.log(response, "item")
      if (response && response.data !== "") {
        const data = response.data.poojaOfferingsDto.map((e: any, i: any) => {
          return { ...e, serial: i + 1, selected: selectedPoojas.some((row: any) => row.poojaCode === e.poojaCode) }
        })
        setPoojaOfferings(data)
        if (from == 0) {
          response.data.charge.map((item: any) => {
            if (item.serviceCode == "VDC") {
              setFacilitationCharge(item.charges)
              setActualFacilitationCharge(item.charges)
            }
            if (item.serviceCode == 'PD') {
              console.log("item")
              setDeliveryCharge(item.charges)
            }
          })
          setTname(item.templeName)
          setTempCode(item.templeCode)
          setIndex1(1)
        } else {
          setShowLoading(false)
        }

      }

    })
      .catch((error: any) => {
        console.log(error, "error")
        setShowLoading(false)
      })
  }


  const handleSelectedpoojaColumn = (item: any, i: any) => {
    const updatedSelectedRows = selectedPoojas.filter((row: any, ind: any) => row.poojaCode !== item.poojaCode);
    setTotalPoojaAmt(totalPoojaAmt - item.cost)
    setTotalOrderAmt(totalOrderAmt - item.cost)
    console.log(selectedPoojas, item, updatedSelectedRows)
    if (updatedSelectedRows.length === 0) {
      setIndex1(1)
      const data = poojaOfferings.map((e: any) => {
        return { ...e, selected: false }
      })
      setPoojaOfferings(data)
    }
    setSelectedPoojas(updatedSelectedRows)
    if (!updatedSelectedRows.some((e: any) => e.poojaCode === item.poojaCode)) {
      const updatedData = poojaOfferings.map((e: any) =>
        ({ ...e, selected: item.poojaCode === e.poojaCode ? false : e.selected })
      );
      setPoojaOfferings(updatedData)
    }
  }


  const handleSelectedRowsChange = (item: any, i: any) => {
    setmodalIndex(0)
    setprasadamAvailable(item.prasadamAvailable)
    setRadiodel("false")
    setDeladd(false)
    setDelivery("")
    setPincode1("")
    setSelectedDates([])
    const updatedDatadate = datearray.map((row: any, ind: any) => {
      return { ...row, selected: false }
    });
    setdatearray(updatedDatadate);
    const updatedData: any = poojaOfferings.map((data: any) => {
      if (data.poojaCode === item.poojaCode) {
        if (data.selected) {
          setCurrentPooja("")
          setSelectedPoojas(selectedPoojas.filter((e: any) => e.poojaCode !== item.poojaCode))
          return { ...data, selected: false };
        } else {
          setSelectedPoojaIds((e: any) => [
            ...e, item.poojaCode
          ])
          setSelectedPoojas((e: any) => [
            ...e, item
          ])
          setCurrentPooja(item)
          setBookDetailsModal(true)
          setSelectedDates(item.selectedDates ? item.selectedDates : [])
          return { ...data, selected: true };
        }
      } else {
        return data;
      }
    });
    console.log(updatedData, "updatedData");
    setPoojaOfferings(updatedData);
  };

  const openDates = () => {
    getalldates(datelimitpooja)
    setmodalIndex(1)
  }


  const handleDeselectRow = () => {
    const updatedData: any = poojaOfferings.map((data: any) => {
      if (data.poojaCode === currentPooja.poojaCode) {
        console.log("Selected:", data);
        setCurrentPooja("")
        setBookDetailsModal(false)
        return { ...data, selected: false };
      } else {
        return data;
      }
    });
    setPoojaOfferings(updatedData)
    const updatedSelectedIds = selectedPoojaIds.filter((row: any) => row != currentPooja.poojaCode);
    const updatedSelectedRows = selectedPoojas.filter((row: any) => row.poojaCode != currentPooja.poojaCode);
    console.log(updatedSelectedRows, "currentPoojaId", currentPooja)
    setCurrentPooja("")
    setSelectedPoojaIds(updatedSelectedIds);
    setSelectedPoojas(updatedSelectedRows)
    setBookDetailsModal(false)
    setFormErrors({})
  };

  const confirmModalPooja = () => {
    console.log(selectedPoojas, selectedPoojaIds, 'updatedSelectedRows')
    const errors: any = {};
    const check = month as string;
    const dontNumber = parseInt(donationamt, 10)
    const dakNumber = parseInt(dakshinaamt, 10)
    var amountCheck = dontNumber + dakNumber;
    const tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate() + 1);
    if (username.trim() === "") {
      errors.name = "Please Enter name"
    }
    if (!star) {
      errors.star = "Please Enter star"
    }
    if (radiodel == "") {
      Alert({
        header: 'Alert',
        message: 'Choose pradasam delivery',
        buttons: ['OK'],
      })
    }
    if (delivery == "" && deladd == true) {
      errors.address = "Please Enter delivery address"
    }
    if (pincode.length === "" && deladd == true) {
      errors.pincode = "Please Enter valid pincode"
    }
    else if (pincode.charAt(0) === "0" && deladd == true) {
      errors.pincode = "Pincode is invalid"
    }
    else if (pincode.length != 6 && deladd == true) {
      errors.pincode = "Please Enter valid pincode"
    }
    // if (fdate === "") {
    //   errors.fromDate = "Please Select From Date"
    // }
    // else if (tomorrow.toISOString().split('T')[0] > new Date(fdate).toISOString().split('T')[0]) {
    //   errors.fromDate = "Please select future date"
    // }
    // if (tdate === "") {
    //   errors.toDate = "Please Select To Date"
    // }
    if (selectedDates.length <= 0) {
      errors.fromDate = "Please Select Dates"

    }
    console.log(errors, "errorserrors")
    setFormErrors(errors)
    if (Object.keys(errors).length === 0) {
      var address = "";
      console.log(fdate, tdate, "date")
      // const start: any = new Date(fdate);
      // const end: any = new Date(tdate);
      // const diffInMs = end - start;
      const noOfDays = selectedDates.length;
      console.log(deliveryCharge, "diffInDays")
      var totalAmtForPooja = noOfDays * currentPooja.cost;
      console.log(totalAmtForPooja, "totalAmtForPooja")
      var prasadamRequired = "0"
      if (deladd === true) {
        prasadamRequired = "1"
        address = delivery + "," + pincode;
      }
      const data = selectedPoojas.map((item: any) => {
        if (item.poojaCode === currentPooja.poojaCode) {
          var data = {
            "poojaCode": currentPooja.poojaCode,
            "name": username,
            "star": star,
            "stPoojaFrom": fdate + " " + "00:00:00",
            "stPoojaTo": tdate + " " + "00:00:00",
            "poojaAmount": currentPooja.cost,
            "prasadamRequired": prasadamRequired,
            "prasadamDeliveryDetails": address,
            "remarks": remarks
          }
          return { ...item, noOfDays: noOfDays, totalAmtForPooja: totalAmtForPooja, bookingDetailsDtos: data, selectedDates: selectedDates }
        }
        else {
          return item;
        }
      })
      console.log(data)
      setSelectedPoojas(data)
      setBookDetailsModal(false)
    }
  }

  const confirmThePoojaBooking = () => {
    setShowLoading(true)
    var prasadamDeliveryCharge = deliveryCharge
    var bookingData: any = [];
    console.log(selectedPoojas, "selectedPoojas")
    selectedPoojas.map((item: any) => {
      const [day, month, year] = item.selectedDate.split("/").map(Number);
      const date = new Date(year, month - 1, day); // Month is zero-based
      const formattedDate = `${date.getFullYear()}-${String(month).padStart(2, "0")}-${String(day).padStart(2, "0")} 00:00:00`;
      var data = {
        "poojaCode": item.poojaCode,
        // "poojaCode": item.poojaOfferings.poojaCode,
        "name": item.bookingDetailsDtos.name,
        "star": item.bookingDetailsDtos.star,
        "stPoojaFrom": formattedDate,
        "stPoojaTo": null,
        "poojaAmount": item.cost,
        "prasadamRequired": item.bookingDetailsDtos.prasadamRequired,
        "prasadamDeliveryDetails": item.bookingDetailsDtos.prasadamDeliveryDetails,
        "remarks": item.bookingDetailsDtos.remarks
      }
      bookingData.push(data)
    })
    // const delChrgCheck = bookingData.every((item : any) => )
    var data = {
      "templeCode": selectedPoojas[0].templeCode,
      "templeDonation": templeAmt,
      "tantriAmount": tantriAmt,
      "poojariAmount": poojari,
      "poojaAmount": totalPoojaAmt,
      "charges": facilitationCharge,
      "totalAmount": totalOrderAmt + (selectedPoojas.filter((e: any) => e.bookingDetailsDtos.prasadamRequired == "1").length * deliveryCharge),
      "bookingDetailsDtos": bookingData,
      "prasadamDeliveryCharge": selectedPoojas.some((e: any) => e.bookingDetailsDtos.prasadamRequired == "1") ? prasadamDeliveryCharge : 0,
      "orderNo": bookingId ? bookingId : null,
      "id": orderId
    }
    Authservice.poojaBookingDetails(token[0], userID[0], "", data)
      .then((response: any) => {
        console.log(response, "response")
        if (response.data !== "" && response.data !== null) {
          const dataMap = response.data.bookingDetailsDtos.map((item: any, i: any) => {
            return { ...item, serial: i + 1 }
          })
          // .filter((e: any) => e.poojaOfferingsDto)

          setConfirmPoojaOfferings(dataMap)
          setBookingId(response.data.orderNo)
          setOrderId(response.data.id)
          console.log(":in")
          response.data.bookingDetailsDtos.forEach((item: any) => {
            if (item.poojaCode === 'VDC') {
              setFacilitationCharge(item.poojaAmount);
              setActualFacilitationCharge(item.poojaAmount)
            } else if (item.poojaCode === 'TEMPLE') {
              setConTempleAmt(item.poojaAmount);
            }
            else if (item.poojaCode === 'TANTRI') {
              setConTantriAmt(item.poojaAmount);
            }
          });
          console.log(":out")
          setConTotalOrderAmt(response.data.totalAmount)
          var date: any = moment(new Date(response.data.orderDate)).format("DD-MM-YYYY")
          setBookingDate(date)
          setConTotalPoojaAmt(response.data.poojaAmount)
          setIndex1(3);
          setShowLoading(false)
        }
      })
      .catch((error) => {
        setShowLoading(false)
        return error;
      });
  }

  const confirmThePoojaDetails = () => {
    var amt = 0;

    const transformedData = selectedPoojas.flatMap((pooja: any) => {
      return (
        pooja.selectedDates.length > 0 && !pooja.selectedDate
          ? pooja.selectedDates.map((date: any) => ({
            ...pooja,
            selectedDate: date.date,
            uniqueId: `id-${Date.now()}-${Math.random()}`, // Generates a unique identifier
          }))
          : [{ ...pooja, uniqueId: `id-${Date.now()}-${Math.random()}` }]
      );
    });
    const data = transformedData.map((item: any) => {
      amt = amt + item.cost;
      return { ...item, selected: true }
    })
    setSelectedPoojas(data)
    console.log(data, "data", data.some((item: any) => item.bookingDetailsDtos.prasadamRequired == "1"))
    setShowDeleviryChargeText(data.some((item: any) => item.bookingDetailsDtos.prasadamRequired == "1"))
    setTotalOrderAmt(amt + facilitationCharge)
    setTotalPoojaAmt(amt)
    setIndex1(2)
  }

  const conProceedtopayment = () => {
    setShowLoading(true);
    Authservice.conPayinitoffering(bookingId, usertoken, userID[0]).then((response: any) => {
      console.log(response.data, "dataa")
      if (response.data.errors === null) {

        var options: any = {
          key: response.data.pgOption.key,
          key_secret: response.data.secretKey,
          amount: response.data.pgOption.amount,
          currency: response.data.pgOption.currency,
          name: response.data.pgOption.name,
          order_id: response.data.pgOption.order_id,
          description: response.data.pgOption.description,
          modal: {
            escape: false, ondismiss: function () {
              Alert("Payment Failed")
            }
          },
          handler: function (res: any) {
            // alert(res.razorpay_payment_id + "/" + res.razorpay_order_id + res.razorpay_signature);
            var resData: any = {
              "amount": response.data.pgOption.amount,
              "orderId": res.razorpay_order_id,
              "bookingRefNo": response.data.bookingRefNo,
              "signature": res.razorpay_signature,
              "paymentId": res.razorpay_payment_id
            }
            console.log("resData", resData);
            Authservice.conPayresoffering(resData, usertoken, userID[0])
              .then((response) => {
                console.log("payresponse" + response);
                // Alert({
                //   header: "Atmagram",
                //   message: "Order placed successfully",
                //   backdropDismiss: false,
                //   buttons: [
                //     {
                //       text: "ok",
                //       handler: () => {
                //         window.location.reload()
                //       }
                //     }
                //   ]
                // })
                if (response) {
                  setCommonModalMesg("Order placed successfully  - Order Id : " + bookingId)
                  setCommonmodaltitle("Information")
                  setCommonmodal(true)
                }
              })
              .catch((error) => {
                Alert({
                  header: "Atmagram",
                  message: "Something went wrong, please try again later.",
                  backdropDismiss: false,
                  buttons: [
                    {
                      text: "ok",
                    }
                  ]
                })
              });
          },
          prefill: {
            name: response.data.pgOption.name,
            email: response.data.email,
            contact: response.data.contact

          },

          theme: {
            color: response.data.theme
          }
        };
        var pay: any = new Razorpay(options)
        pay.on("payment.failed", function (data: any) {
          Alert({
            header: "Atmagram",
            message: data.error.reason,
            backdropDismiss: false,
            buttons: [
              {
                text: "ok",
              }
            ]
          })

        });
        pay.on("payment.cancelled", function (data: any) {
          Alert(data.error.reason);
          // Additional handling or redirection logic can be added here if needed.
        });
        pay.open()
        setShowLoading(false);
      }
      else {
        setShowLoading(false);
        Alert({
          header: "Atmagram",
          message: response.data.errors[0],
          backdropDismiss: false,
          buttons: ["Ok"]
        })
      }
    })
      .catch((error) => {
        Alert(error)
        setShowLoading(true);

      });
  }

  const Getdatausingsearch = (value: any) => {
    Authservice.offerPoojaTemple(token[0], value, userID[0])
      .then((response: any) => {
        console.log(response);
        setShowLoading(false);
        const data = response.data.map((e: any, i: any) => {
          return { ...e, serial: i + 1 }
        })
        settempleList(data);
      })
      .catch((error: any) => {
        console.error("Error fetching data:", error);
        setShowLoading(false);
      });
  }

  const selecteddatesvalue = (dates: any) => {
    const selected = dates
      .map((i: any) => i.date)
      .sort((a: any, b: any) => {
        const [dayA, monthA, yearA] = a.split('/').map(Number);
        const [dayB, monthB, yearB] = b.split('/').map(Number);
        return new Date(yearA, monthA - 1, dayA).getTime() - new Date(yearB, monthB - 1, dayB).getTime();
      });
    return selected.join(", ");
  }

  // const subHeaderComponent = useMemo(() => {
  //   const handleClear = () => {
  //     if (filterText) {
  //       setResetPaginationToggle(!resetPaginationToggle);
  //       setFilterText("");
  //     }
  //   };
  //   return (
  //     <IonRow className="table_btn_container">
  //       <div>
  //       </div>
  //       <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
  //         <IonSearchbar
  //           value={filterText}
  //           onIonChange={(e: any) => handleSearch(e)}
  //           placeholder="Search"
  //           className="table_search"
  //         />
  //         <div className='cart-badge-parent' onClick={() => {
  //           history.push("/mycart")

  //         }} >
  //           <IonIcon src={cart} className="grammerce_main_cart_btn_mobile" />
  //           <IonBadge className='cart-badge'>{cartCount}</IonBadge>
  //         </div>
  //       </div>
  //     </IonRow>
  //   );
  // }, [filterText, resetPaginationToggle]);

  const [filterTextDate, setFilterTextDate] = useState("")

  const dateTableData = datearray.filter(
    (item: any) =>
      JSON.stringify(item)
        .toLowerCase()
        .indexOf(filterTextDate.toLowerCase()) !== -1
  );

  const subHeaderComponentdate = useMemo(() => {

    return (
      <IonRow className="table_btn_container">
        <div>
        </div>
        <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
          <IonSearchbar
            value={filterTextDate}
            onIonChange={(e: any) => setFilterTextDate(e.target.value)}
            placeholder="Search"
            className="table_search"
          />

        </div>
      </IonRow>
    );
  }, [filterTextDate, resetPaginationToggle]);

  const handleSearch = (e: any) => {
    const value = e.detail.value || '';
    const regex = /[&';=]|--/;
    if (regex.test(value)) {
      console.warn('Invalid input detected');
      if (inputRef.current) {
        inputRef.current.value = searchTerm;
      }
      return;
    }
    setSearchTerm(value);
    if (value.length > 3 || value.length === 0) {
      const searchValue = `[${districtOption.label},${panchayatoption.label},${additionalOption.label}] ${value}`;
      clearTimeout(debounceTimeout);
      debounceTimeout = setTimeout(() => {
        Getdatausingsearch(searchValue);
      }, 1000);
    }
  };

  const handleSearchPooja = (e: any) => {
    const value = e.detail.value || '';
    const regex = /[&';=]|--/;
    if (regex.test(value)) {
      console.warn('Invalid input detected');
      if (inputRefPooja.current) {
        inputRefPooja.current.value = poojaSearchText;
      }
      return;
    }
    clearTimeout(debounceTimeout);
    debounceTimeout = setTimeout(() => {
      const value = e.target.value;
      setpoojaSearchText(value)
      offeringDetails(tempCode, value, 1)
    }, 1000);

  }

  const paginationComponent = () => {

    console.log("paginationComponent", facilitationCharge)
    return (
      <div style={{ backgroundColor: "white" }}>
        {facilitationCharge > 0 && <IonRow style={{ alignItems: "center", justifyContent: "end" }}>
          <IonCol size-lg="2.5" size-md="2.5" size-sm="4.5" size-xs="4.5">
            <IonText className="con_book_details_label">Vedic Dharmic Contribution</IonText>
          </IonCol>
          <IonCol size-lg="0.5" size-md="0.5" size-sm="0.5" size-xs="1">
            <IonText className="con_book_details_label off_colon_display">
              :
            </IonText>
          </IonCol>
          <IonCol size-lg="1" size-md="1" size-sm="1" size-xs="1">
            <IonText className="con_book_details_text">
              {facilitationCharge}
            </IonText>
          </IonCol>
        </IonRow>}
        {conTantriAmt > 0 && <IonRow style={{ alignItems: "center", justifyContent: "end" }}>
          <IonCol size-lg="2.5" size-md="2.5" size-sm="4.5" size-xs="4.5">
            <IonLabel className="con_book_details_label">Tantri (₹)</IonLabel>
          </IonCol>
          <IonCol size-lg="0.5" size-md="0.5" size-sm="0.5" size-xs="1">
            <IonText className="con_book_details_label off_colon_display">
              :
            </IonText>
          </IonCol>
          <IonCol size-lg="1" size-md="1" size-sm="1" size-xs="1">
            <IonText className="con_book_details_text">
              {conTantriAmt}
            </IonText>
          </IonCol>
        </IonRow>}
        {conTotalPoojaAmt > 0 && <IonRow style={{ alignItems: "center", justifyContent: "end" }}>
          <IonCol size-lg="2.5" size-md="2.5" size-sm="4.5" size-xs="4.5">
            <IonText className="con_book_details_label">Pooja Amount (₹)</IonText>
          </IonCol>
          <IonCol size-lg="0.5" size-md="0.5" size-sm="0.5" size-xs="1">
            <IonText className="con_book_details_label off_colon_display">
              :
            </IonText>
          </IonCol>
          <IonCol size-lg="1" size-md="1" size-sm="1" size-xs="1">
            <IonText className="con_book_details_text">
              {conTotalPoojaAmt}
            </IonText>
          </IonCol>
        </IonRow>}
        {conTempleAmt > 0 && <IonRow style={{ alignItems: "center", justifyContent: "end" }}>
          <IonCol size-lg="2.5" size-md="2.5" size-sm="4.5" size-xs="4.5">
            <IonLabel className="con_book_details_label">Temple (₹)</IonLabel>
          </IonCol>
          <IonCol size-lg="0.5" size-md="0.5" size-sm="0.5" size-xs="1">
            <IonText className="con_book_details_label off_colon_display">
              :
            </IonText>
          </IonCol>
          <IonCol size-lg="1" size-md="1" size-sm="1" size-xs="1">
            <IonText className="con_book_details_text">
              {conTempleAmt}
            </IonText>
          </IonCol>
        </IonRow>}
        <IonRow style={{ alignItems: "center", justifyContent: "end" }}>
          <IonCol size-lg="2.5" size-md="2.5" size-sm="4.5" size-xs="4.5">
            <IonLabel className="con_book_details_label">Total (₹)</IonLabel>
          </IonCol>
          <IonCol size-lg="0.5" size-md="0.5" size-sm="0.5" size-xs="1">
            <IonText className="con_book_details_label off_colon_display">
              :
            </IonText>
          </IonCol>
          <IonCol size-lg="1" size-md="1" size-sm="1" size-xs="1">
            <IonText className="con_book_details_text">
              {conTotalOrderAmt}
            </IonText>
          </IonCol>
        </IonRow>
      </div>
    )
  }

  const pageNames = ['Temple', 'Offering', 'Selected Pooja`s', 'confirm Pooja', 'payment'];

  return (
    <IonPage>
      <IonAlert
        cssClass={"custom_alert"}
        isOpen={showAlert}
        backdropDismiss={false}
        onDidDismiss={() => setShowAlert(false)}
        header="Confirmation"

        message="Are you sure you want to leave? If you proceed, your order details will be lost. Please click Cancel to continue with your order."
        buttons={[
          {
            text: 'Cancel',
            role: 'cancel',

            handler: () => {
              setActionFlag(false)
              setShowAlert(false); // Update the flag to indicate that the action was clicked
            },
          },
          {
            text: 'Leave',
            handler: handleLeave,
          },
        ]}

      />
      <Modal show={bookDetailsModal} centered className="poojaBooking-modal">
        <Modal.Header>
          <h5 className="profileModal-title">Booking Details</h5>
          <div>
            <IonIcon src={close} className="close_icon" onClick={() => handleDeselectRow()} />
          </div>
        </Modal.Header>
        <Modal.Body style={{ padding: "0px 20px" }}>
          {modalIndex === 0 ? <IonGrid>
            <IonRow className="label_row_container">
              <IonCol size-lg="2" size-md="4.5" size-sm="4.5" size-xs="11">
                <IonText className="book_details_label_infoget">
                  Name <span style={{ color: "red" }}>*</span>
                </IonText>
              </IonCol>
              <IonCol size-lg="0.5" size-md="0.5" size-sm="0.5" size-xs="1">
                <IonText className="book_details_label_infoget off_colon_display">
                  :
                </IonText>
              </IonCol>
              <IonCol size-lg="9.5" size-md="6.5" size-sm="6.5" size-xs="11">
                <IonInput className="off_input" value={username} onIonChange={(e: any) => setUsername(e.detail.value)} />
                {formErrors.name && <span className="alert_input_bottom ">{formErrors.name}</span>}
              </IonCol>
            </IonRow>
            <IonRow className="label_row_container">
              <IonCol size-lg="2" size-md="4.5" size-sm="4.5" size-xs="11">
                <IonText className="book_details_label_infoget">
                  Star <span style={{ color: "red" }}>*</span>
                </IonText>
              </IonCol>
              <IonCol size-lg="0.5" size-md="0.5" size-sm="0.5" size-xs="1">
                <IonText className="book_details_label_infoget off_colon_display">
                  :
                </IonText>
              </IonCol>
              <IonCol size-lg="9.5" size-md="6.5" size-sm="6.5" size-xs="11">
                <Select className="select-stars off_input" options={starOptions} value={starOptions.filter((item: any) => item.label === star)} onChange={(e: any) => setStar(e.label)} />
                {formErrors.star && <span className="alert_input_bottom ">{formErrors.star}</span>}
              </IonCol>
            </IonRow>
            <IonRow className={formErrors.fromDate ? "fromdate_row_container_height" : "fromdate_row_container"}>
              <IonCol size-lg="2" size-md="4.5" size-sm="4.5" size-xs="11">
                <IonText className="book_details_label_infoget">Pooja Dates <span style={{ color: "red" }}>*</span></IonText>
              </IonCol>
              <IonCol size-lg="0.5" size-md="0.5" size-sm="0.5" size-xs="1">
                <IonText className="book_details_label_infoget off_colon_display">
                  :
                </IonText>
              </IonCol>
              <IonCol size-lg="9.5" size-md="6.5" size-sm="6.5" size-xs="11" className=" date_font">
                <IonButton className="btn_v2" onClick={() => openDates()}>
                  <span className="button_padding">Select Dates</span>
                </IonButton>
                {formErrors.fromDate && <span className="alert_input_bottom ">{formErrors.fromDate}</span>}
                {selectedDates.length > 0 &&
                  <IonTextarea className="off_textarea" style={{ marginTop: "10px" }} value={selecteddatesvalue(selectedDates)} readonly autoGrow />
                }
              </IonCol>
            </IonRow>
            {prasadamAvailable && <IonRow className="label_row_container">
              <IonCol size-lg="2" size-md="4.5" size-sm="4.5" size-xs="11">
                <IonText className="book_details_label_infoget">
                  Prashadam <br /> Delivery
                </IonText>
              </IonCol>
              <IonCol size-lg="0.5" size-md="0.5" size-sm="0.5" size-xs="1">
                <IonText className="book_details_label_infoget off_colon_display">
                  :
                </IonText>
              </IonCol>
              <IonCol size-lg="9.5" size-md="6.5" size-sm="6.5" size-xs="11">
                <IonRadioGroup className={Capacitor.getPlatform() === "ios" ? "ios_radio_btn" : ""} value={radiodel} onIonChange={(e: any) => Radio(e)}>
                  <IonCol>
                    <IonRadio value="true" className="custom-tabIndex" />
                  </IonCol>
                  <IonCol>
                    <IonLabel className="book_details_label_infoget">Yes</IonLabel>
                  </IonCol>
                  <IonCol>
                    <IonRadio value="false" className="custom-tabIndex" />
                  </IonCol>
                  <IonCol>
                    <IonLabel className="book_details_label_infoget">No</IonLabel>
                  </IonCol>
                </IonRadioGroup>
              </IonCol>
            </IonRow>}
            {deladd && <div>
              <IonRow className="label_row_container">
                <IonCol size-lg="2" size-md="4.5" size-sm="4.5" size-xs="11">
                  <IonText className="book_details_label_infoget">
                    Address <span style={{ color: "red" }}>*</span>
                  </IonText>
                </IonCol>
                <IonCol size-lg="0.5" size-md="0.5" size-sm="0.5" size-xs="1" >
                  <IonText className="book_details_label_infoget off_colon_display">
                    :
                  </IonText>
                </IonCol>
                <IonCol size-lg="9.5" size-md="6.5" size-sm="6.5" size-xs="11">
                  <IonTextarea className="off_textarea" value={delivery} onBlur={() => Validation("address")} onIonChange={(e: any) => { setDelivery(e.detail.value); ChangeValidation("address", e) }} autoGrow />
                  {formErrors.address && <span className="alert_input_bottom ">{formErrors.address}</span>}
                </IonCol>
              </IonRow>
              <IonRow className="label_row_container">
                <IonCol size-lg="2" size-md="4.5" size-sm="4.5" size-xs="11">
                  <IonText className="book_details_label_infoget">
                    Pincode <span style={{ color: "red" }}>*</span>
                  </IonText>
                </IonCol>
                <IonCol size-lg="0.5" size-md="0.5" size-sm="0.5" size-xs="1">
                  <IonText className="book_details_label_infoget off_colon_display">
                    :
                  </IonText>
                </IonCol>
                <IonCol size-lg="9.5" size-md="6.5" size-sm="6.5" size-xs="11">
                  <IonInput className="off_input" value={pincode} type="number" onFocus={preventdata} onBlur={() => Validation("pincode")} onIonChange={(e: any) => { setPin(e.detail.value); ChangeValidation("pincode", e) }} />
                  {formErrors.pincode && <span className="alert_input_bottom ">{formErrors.pincode}</span>}
                </IonCol>
              </IonRow>
              {/* {usertoken === null ? <></> : <IonRow className="label_row_container">
                  <IonCol size="0.5">
                    <IonCheckbox ref={checkboxRef} onIonChange={(e: any) => sameas(e)} checked={che} className="custom-tabIndex" />
                  </IonCol>
                  <IonCol size="0.5">
                  </IonCol>
                  <IonCol size="6.5">
                    <IonText className="book_details_label_infoget">
                      Same As profile
                    </IonText>
                  </IonCol>
                </IonRow>} */}
            </div>}
            <IonRow className="label_row_container">
              <IonCol size-lg="2" size-md="4.5" size-sm="4.5" size-xs="11">
                <IonText className="book_details_label_infoget">
                  Remarks
                </IonText>
              </IonCol>
              <IonCol size-lg="0.5" size-md="0.5" size-sm="0.5" size-xs="1" >
                <IonText className="book_details_label_infoget off_colon_display">
                  :
                </IonText>
              </IonCol>
              <IonCol size-lg="9.5" size-md="6.5" size-sm="6.5" size-xs="11">
                <IonTextarea className="off_textarea" value={remarks} onIonChange={(e: any) => setRemarks(e.detail.value)} autoGrow />
              </IonCol>
            </IonRow>
            <IonRow className="padding_pay" >
              <IonCol size="12" style={{ display: "flex", alignItems: "center", justifyContent: "center", width: "100%", margin: "2%" }}>
                <IonButton className="btn_v2" onClick={() => confirmModalPooja()}> <span className="button_padding">Save</span></IonButton>
                <IonButton className="btn_v2" onClick={() => handleDeselectRow()}> <span className="button_padding">Cancel</span></IonButton>
              </IonCol>
            </IonRow>
          </IonGrid> :
            <IonGrid>
              <IonRow>
                <CommonDataTable
                  data={dateTableData}
                  columns={dateColumn}
                  isSubHeader={true}
                  subHeaderComponent={subHeaderComponentdate}
                  pagination={true}
                />
              </IonRow>
              <IonRow className="padding_pay" >
                <IonCol size="12" style={{ display: "flex", alignItems: "center", justifyContent: "center", width: "100%", margin: "2%" }}>
                  <IonButton className="btn_v2" onClick={() => confirmDates()}> <span className="button_padding">Confirm</span></IonButton>
                </IonCol>
              </IonRow>
            </IonGrid>}
        </Modal.Body>
      </Modal>
      <HeadFoot User={"login"} Role="Role" Name='Home,Offer Pooja' />
      <IonContent>
        <div className="page_content_padding horizontal_padding_v2">
          <Breadcrumb pageNames={pageNames}
            currentPage={index1}
            onBreadcrumbClick={handleBreadcrumbClick} />
          <IonLoading message="Loading..." spinner={null}
            isOpen={showLoading}
          />
          <IonRow style={{ position: "absolute" }}>
            <IonCol>
              <IonAlert
                isOpen={iserror}
                onDidDismiss={() => setIserror(false)}
                cssClass="my-custom-class"
                header={"Error!"}
                message={message}
                buttons={["Dismiss"]}
              />
            </IonCol>
          </IonRow>
          {index1 === 0 ? <>
            <IonRow className='deity_container' style={{ paddingTop: "10px" }}>
              <div className="toolbar-container" style={{ justifyContent: "flex-end", width: "100%", position: "relative" }}>
                <div className="select-search-container">
                  <div className="radio-search-container" style={{ alignItems: "end" }}>
                    {/* District Select with Label */}
                    <div className="select-group">
                      <label className="select-label">District</label>
                      <Select
                        options={districtListOptions}
                        value={districtOption}
                        onChange={handleDistrictChange}
                        placeholder="Select District"
                        className="search-box"
                      />
                    </div>

                    {/* Panchayat Select with Label */}
                    <div className="select-group">
                      <label className="select-label">Panchayat</label>
                      <Select
                        options={panchayatListOptions}
                        value={panchayatoption}
                        onChange={hanldePanchayatChange}
                        placeholder="Select Panchayat"
                        className="search-box"
                        isDisabled={!districtOption || districtOption.value === "ALL"}
                      />
                    </div>
                    <div className="select-group">
                      <label className="select-label">Search on</label>
                      <Select
                        options={additionalOptions}
                        value={additionalOption}
                        placeholder="Select Option"
                        className="search-box"
                        onChange={hanldeAdditionalChange}
                      />
                    </div>
                    <IonInput
                      placeholder="Search..."
                      value={searchTerm}
                      clearInput
                      onIonChange={handleSearch}
                      className="search-textbox"
                      ref={inputRef}
                    />
                  </div>
                </div>
                <div className='cart-badge-parent' onClick={() => {
                  history.push("/mycart")
                }} >
                  <IonIcon src={cart} className="grammerce_main_cart_btn_mobile" />
                  <IonBadge className='cart-badge'>{cartCount}</IonBadge>
                </div>
              </div>
              {usertoken ? <div style={{ width: "100%" }}>
                <CommonDataTable
                  columns={columns}
                  data={templeList}
                  loading={showLoading}
                  noDataMessage="Sorry, No data available"
                  onRowClick={(item: any) => offeringDetails(item, poojaSearchText, 0)}
                  isSubHeader={false}
                // subHeaderComponent={subHeaderComponent}
                />
              </div> : <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%', width: "100%", alignSelf: "center" }}>
                <IonText style={{ fontWeight: 'bold', textAlign: 'center' }}>
                  Login is required to access the details.
                </IonText>
              </div>
              }
            </IonRow> </> : index1 === 1 ? <div >
              <IonGrid>
                <div>
                  <div>
                    <div style={{ display: "flex", gap: "10px", alignItems: "center" }}>
                      <IonIcon onClick={() => backtotemple()} src={arrowBack} className="point back" size="large"></IonIcon>
                      <IonText className="offering_temple_name">
                        {tname} {`[${tempCode}]`} - Booking of Pooja
                      </IonText>
                    </div>
                  </div>
                  <div className="searchBox-element">
                    <IonInput placeholder="Search..." ref={inputRefPooja} onIonChange={handleSearchPooja} value={poojaSearchText} className="search-textbox search-textbox-pooja"></IonInput>
                  </div>
                </div>
                {mobile ? <>
                  {poojaOfferings.map((item: any, i: any) => {
                    var dates
                    var flag1 = false
                    if (selectedPoojas.length > 0) {
                      dates = selectedPoojas.find((e: any) => e.poojaCode === item.poojaCode)
                    }
                    if (poojaSearchText && !item.poojaName.toLowerCase().includes(poojaSearchText.toLowerCase())) {
                      flag1 = true
                    }
                    return (
                      <IonCol sizeLg='6' sizeXl='4' size-md='6' size-xs='12' size-sm='12' style={{ display: flag1 && "none" }}>
                        <IonCard className='deity_cards'>
                          <IonRow>
                            <IonCol size='12'>
                              <IonRow style={{ justifyContent: "end" }}>
                                <div >
                                  <IonCheckbox checked={item.selected} onIonChange={() => handleSelectedRowsChange(item, i)} />
                                </div>
                              </IonRow>
                              <IonRow style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                <IonCol size='12'>
                                  <IonText className='deity_name'>{item.templeName}</IonText>
                                  <IonText className='deity_description MainDeity'>
                                    <span>Pooja Name</span> : {item.poojaName}
                                  </IonText>
                                  <IonText className='deity_description'>
                                    <span> Pooja Description </span>: {item.poojaOfferings?.description || "N/A"}
                                  </IonText>
                                  <IonText className='deity_description'>
                                    <span> Pooja Amount </span>: {item.cost}
                                  </IonText>
                                  <IonText className='deity_description MainDeity'>
                                    <span> Time Of Pooja </span>: {item.timeOfPooja}
                                  </IonText>
                                  <IonText className='deity_description MainDeity'>
                                    <span> prasadam Available </span>: {item.prasadamAvailable ? "Yes" : "No"}
                                  </IonText>
                                  {dates && dates.selectedDates && <IonText className='deity_description MainDeity'>
                                    <span> Selected Dates </span>: {selecteddatesvalue(dates.selectedDates)}
                                  </IonText>
                                  }
                                </IonCol>
                              </IonRow>
                            </IonCol>
                          </IonRow>
                        </IonCard>
                      </IonCol>
                    )
                  }
                  )
                  }</> : <CommonDataTable
                  columns={offeringsColumn}
                  data={poojaOfferings}
                  loading={showLoading}
                  onRowClick={null}
                  noDataMessage="Sorry, No data available"
                  selectableRows={false}
                  pagination={false}
                />
                }
                <IonRow style={!mobile ? { justifyContent: "end", marginTop: "10px" } : {
                  justifyContent: "end", marginTop: "10px", position: "fixed",
                  bottom: "10px", right: "10px", zIndex: "1000"
                }}>
                  <IonButton
                    className="btn_v2"
                    onClick={() => confirmThePoojaDetails()}
                    disabled={selectedPoojas.length === 0}>
                    <span className="button_padding">Confirm</span>
                  </IonButton>
                </IonRow>
              </IonGrid>
            </div>
            : index1 === 2 ? <div >
              <IonGrid>
                <IonRow style={{ display: "flex", alignItems: "center" }}>
                  <IonCol size-lg="7" size-md="7" size-xs="11" size-sm="11">
                    <div style={{ gap: "10px", alignItems: "center", display: "flex" }}>
                      <IonIcon onClick={() => goBackToPoojas()} src={arrowBack} className="point back" size="large"></IonIcon>
                      <IonText className="offering_temple_name">
                        {tname} {`[${tempCode}]`}
                      </IonText>
                    </div>
                  </IonCol>
                </IonRow>
                <IonRow className="off_chrg_container">
                  <IonCol size-lg="4" size-md="4" size-xs="12" size-sm="12">
                    <IonRow style={{ alignItems: "center" }}>
                      <IonCol size-lg="9.5" size-md="9" size-sm="7" size-xs="10" >
                        <IonText className="book_details_label_infoget">Vedic Dharmic Contribution</IonText>
                      </IonCol>
                      <IonCol size-lg="2.5" size-md="3" size-sm="5" >
                        <IonInput value={facilitationCharge} onKeyPress={(event) => numberOnlyValidation(event)} className="off_input off_amt_input" onIonChange={(e: any) => hanldeFacilation(e)} />
                      </IonCol>
                      {disableCnfrmbtn && <IonCol size="12">
                        <span className="alert_input_bottom ">Vedic Dharmic Contribution Should be greather than {actualfacilitationCharge - 1}</span>
                      </IonCol>}
                    </IonRow>
                  </IonCol>
                  <IonCol size-lg="4" size-md="4" size-xs="12" size-sm="12">
                    <IonRow style={{ alignItems: "center" }}>
                      <IonCol size-lg="9.5" size-md="9" size-sm="7" size-xs="10" >
                        <IonLabel className="book_details_label_infoget">Tantri Dhakshina </IonLabel>
                      </IonCol>
                      <IonCol size-lg="2.5" size-md="3" size-sm="5" >
                        <IonInput value={tantriAmt} min={0} onFocus={preventdata} autofocus={true} type="number" onKeyPress={(event) => numberOnlyValidation(event)} className="off_input off_amt_input" onIonChange={(e: any) => {
                          var num = e.detail.value;
                          if (num === "" || Number.isNaN(num)) {
                            num = 0
                          }
                          settantriAmt(parseInt(num)); setTotalOrderAmt(totalPoojaAmt + facilitationCharge + templeAmt + parseInt(num))
                        }} />
                      </IonCol>
                    </IonRow>
                  </IonCol>
                  <IonCol size-lg="4" size-md="4" size-xs="12" size-sm="12">
                    <IonRow style={{ alignItems: "center" }}>
                      <IonCol size-lg="9.5" size-md="9" size-sm="7" size-xs="10" >
                        <IonLabel className="book_details_label_infoget">Temple Donation </IonLabel>
                      </IonCol>
                      <IonCol size-lg="2.5" size-md="3" size-sm="5" >
                        <IonInput value={templeAmt} min={0} onFocus={preventdata} autofocus={true} type="number" onKeyPress={(event) => numberOnlyValidation(event)} className="off_input off_amt_input" onIonChange={(e: any) => {
                          var num = e.detail.value;
                          if (num === "" || Number.isNaN(num)) {
                            num = 0
                          }
                          setTempleAmt(parseInt(num)); setTotalOrderAmt(totalPoojaAmt + facilitationCharge + tantriAmt + parseInt(num))
                        }} />
                      </IonCol>
                    </IonRow>
                  </IonCol>
                  <IonCol size-lg="4" size-md="4" size-xs="12" size-sm="12">
                    <IonRow style={{ alignItems: "center" }}>
                      <IonCol size-lg="9.5" size-md="9" size-sm="7" size-xs="10" >
                        <IonLabel className="book_details_label_infoget">Poojari Dhakshina</IonLabel>
                      </IonCol>

                      <IonCol size-lg="2.5" size-md="3" size-sm="5" >
                        <IonInput value={poojari} min={0} onFocus={preventdata} autofocus={true} type="number" onKeyPress={(event) => numberOnlyValidation(event)} className="off_input off_amt_input" onIonChange={(e: any) => {
                          var num = e.detail.value;
                          if (num === "" || Number.isNaN(num)) {
                            num = 0
                          }
                          setPoojaRi(parseInt(num)); setTotalOrderAmt(totalPoojaAmt + facilitationCharge + tantriAmt + templeAmt + parseInt(num))
                        }} />
                      </IonCol>
                    </IonRow>
                  </IonCol>
                  {showDeleviryChargeText && <IonCol size-lg="4" size-md="6" size-xs="12" size-sm="12">
                    <IonRow style={{ alignItems: "center" }}>
                      <IonCol size-lg="9.5" size-md="9" size-sm="7" size-xs="10" >
                        <IonLabel className="book_details_label_infoget">Delivery Charge / Day </IonLabel>
                      </IonCol>
                      <IonCol size-lg="2.5" size-md="3" size-sm="5" >
                        <div>
                          {deliveryCharge}
                        </div>
                      </IonCol>
                    </IonRow>
                  </IonCol>}
                </IonRow>
                <CommonDataTable
                  columns={selectedOfferingsColumn}
                  data={selectedPoojas}
                  loading={showLoading}
                  keyfield="uniqueId"
                  noDataMessage="Sorry, No data available"
                />
                <IonRow style={{ justifyContent: "end", alignItems: "center" }}>
                  <IonCol size="4">
                    <IonRow style={{ alignItems: "center", justifyContent: "end", }}>
                      <IonCol size-lg="4.5" size-md="4.5" size-sm="4.5" size-xs="7">
                        <IonText className="book_details_label_infoget">Total</IonText>
                      </IonCol>
                      <IonCol size-lg="6.5" size-md="6.5" size-sm="6.5" size-xs="4.5">
                        <IonText className="card_head">
                          {totalOrderAmt + (selectedPoojas.filter((e: any) => e.bookingDetailsDtos.prasadamRequired == "1").length * deliveryCharge)}
                        </IonText>
                      </IonCol>
                    </IonRow>
                  </IonCol>
                </IonRow>
                <IonRow style={{ justifyContent: "end", alignItems: "center" }}>
                  <IonCol style={{ display: "flex", justifyContent: "end", textAlign: "end" }}>
                    <div>
                      <IonButton
                        className="btn_v2"
                        onClick={() => confirmThePoojaBooking()}
                        disabled={disableCnfrmbtn}>
                        <span className="button_padding">Confirm</span>
                      </IonButton>
                    </div>
                  </IonCol>
                </IonRow>
              </IonGrid>
            </div> : index1 === 3 ? <div >
              <IonGrid className="off_grid_container">
                <IonRow style={{ display: "flex", alignItems: "center" }}>
                  <IonCol size-lg="7" size-md="7" size-xs="11" size-sm="11">
                    <IonRow style={{ gap: "10px" }}>
                      <IonIcon onClick={() => setIndex1(2)} src={arrowBack} className="point back" size="large"></IonIcon>
                      <IonText className="offering_temple_name">
                        {tname} {`[${tempCode}]`}
                      </IonText>
                    </IonRow>
                  </IonCol>
                </IonRow>
                <IonRow style={{ display: "flex", justifyContent: "space-between", alignItems: "center", padding: "10px 0px" }}>
                  <div>
                    <IonText className="book_details_label_infoget">Charges for Booking No :   {bookingId}</IonText>
                  </div>
                  <div>
                    <IonText className="book_details_label_infoget">Date :   {bookingDate}</IonText>
                  </div>
                  {/* <IonCol size-lg="6" size-md="6" size-xs="12" size-sm="12">
                  <IonRow style={{ alignItems: "center" }}>
                    <IonCol size-lg="4.5" size-md="4.5" size-sm="4.5" size-xs="11">
                      <IonText className="book_details_label_infoget">Date</IonText>
                    </IonCol>
                    <IonCol size-lg="0.5" size-md="0.5" size-sm="0.5" size-xs="1">
                      <IonText className="book_details_label_infoget off_colon_display">
                        :
                      </IonText>
                    </IonCol>
                    <IonCol size-lg="6.5" size-md="6.5" size-sm="6.5" size-xs="11">
                      <IonText className="card_head">
                        {bookingDate}
                      </IonText>
                    </IonCol>
                  </IonRow>
                </IonCol>
                <IonCol size-lg="6" size-md="6" size-xs="12" size-sm="12">
                  <IonRow style={{ alignItems: "center" }}>
                    <IonCol size-lg="4.5" size-md="4.5" size-sm="4.5" size-xs="4.5">
                      <IonLabel className="book_details_label_infoget">Date</IonLabel>
                    </IonCol>
                    <IonCol size-lg="0.5" size-md="0.5" size-sm="0.5" size-xs="1">
                      <IonText className="book_details_label_infoget off_colon_display">
                        :
                      </IonText>
                    </IonCol>
                    <IonCol size-lg="2.5" size-md="3" size-sm="5" >
                      <IonText className="card_head">
                        {bookingDate}
                      </IonText>
                    </IonCol>
                  </IonRow>
                </IonCol> */}
                </IonRow>
                <CommonDataTable
                  columns={conOfferingsColumn}
                  data={confirmPoojaOffering}
                  onRowClick={null}
                  selectableRows={false}
                  pagination={false}
                  // paginationComponent={() => paginationComponent()}
                  loading={showLoading}
                  noDataMessage="Sorry, No data available"
                />
                <div style={{ display: "flex", justifyContent: "end", alignItems: "center", gap: "20px" }}>
                  <div>
                    <IonText className="con_book_details_label">Total Amount :{totalOrderAmt + (selectedPoojas.filter((e: any) => e.bookingDetailsDtos.prasadamRequired == "1").length * deliveryCharge)}
                    </IonText>
                  </div>
                  <IonButton
                    onClick={() => conProceedtopayment()}
                    className="btn_v2">
                    <span className="button_padding">Proceed to payment</span>
                  </IonButton>
                </div>
              </IonGrid>
            </div>
              : <></>
          }
        </div>
        <Foot />
      </IonContent >
      <Modal show={modalopen} className="donation-modal" centered >
        <Modal.Body ref={donationmodal}>
          <div className="donation-modal-header project-modal-header">
            <h5 className="donation-modal-title">Atmagram</h5>
            <div>
              <IonIcon src={close} className="donation-modal-close-icon close_icon" onClick={() => setModalopen(false)} />
            </div>
          </div>
          <div className='donation-modal-content'>
            <IonText>The Amount will be paid to the priest who performs the pooja</IonText>
          </div>
        </Modal.Body>
      </Modal>
      <Modal show={modalopen1} className="donation-modal" centered >
        <Modal.Body ref={templedonationmodal}>
          <div className="donation-modal-header project-modal-header">
            <h5 className="donation-modal-title">Atmagram</h5>
            <div>
              <IonIcon src={close} className="donation-modal-close-icon close_icon" onClick={() => setModalopen1(false)} />
            </div>
          </div>
          <div className='donation-modal-content'>
            <IonText>The Amount donated will be paid to the temple</IonText>
          </div>
        </Modal.Body>
      </Modal>
      <Modal show={isShow} className="custom-modal-wrapper">
        <Modal.Body ref={deitymodal} >
          <div className="modal-header" style={{ padding: "15px 0px" }}>
            <h5 className="modal-title">Atmagram</h5>
            <button type="button" className="close" data-bs-dismiss="modal" aria-label="Close" onClick={() => setIsshow(false)}>
              <span aria-hidden="true">x</span>
            </button>
          </div>
          <div className="custom-modal-deity">
            <IonRow style={{ display: "flex", alignItem: "center", justifyContent: "center" }}>
              <IonImg src={image} style={{ width: "250px", height: "300px" }}></IonImg>
            </IonRow>
            <IonRow style={{ justifyContent: "center" }}>
              <IonText style={{ fontWeight: "bold", fontSize: "20px" }}>{maindeityname}</IonText>
            </IonRow>
            <IonRow style={{ textAlign: "justify" }}>
              <IonText style={{ fontSize: "17px", padding: "30px" }}>{description}</IonText>
            </IonRow>

          </div>
        </Modal.Body>
      </Modal>

      <Modal show={infomodal} className="off_info_modal" centered>
        <Modal.Body>
          <IonRow className="off_info_modal_header">
            <IonCol size-lg="11" size-md="11" size-xs="11" size-sm="11">
              <IonText className="info_modal_offname">
                {offerName}
              </IonText>
            </IonCol>
            <IonCol size-lg="1" size-md="1" size-xs="1" size-sm="1">
              <IonIcon src={close} onClick={() => setInfomodal(false)} className="modal_close_icon close_icon" />
            </IonCol>
          </IonRow>
          <IonRow className="row_container">
            <IonCol size-lg="0.5" size-md="0.5" size-xs="1" size-sm="1">
              <IonIcon src={checkmarkCircleSharp} className="tick" />
            </IonCol>
            <IonCol size-lg="2" size-md="3.5" size-xs="3.5" size-sm="3.5" className="label_container_info">
              <IonText className="off_modal_label">
                Timing
              </IonText>
            </IonCol>
            <IonCol size-lg="0.5" size-md="0.5" size-xs="0.5" size-sm="0.5">
              <IonText className="off_modal_label">
                :
              </IonText>
            </IonCol>
            <IonCol size-lg="8.5" size-md="7.5" size-xs="7" size-sm="7">
              <IonText className="off_modal_label">
                {offtiming}
              </IonText>
            </IonCol>
          </IonRow>
          <IonRow className="row_container">
            <IonCol size-lg="0.5" size-md="0.5" size-xs="1" size-sm="1">
              <IonIcon src={checkmarkCircleSharp} className="tick" />
            </IonCol>
            <IonCol size-lg="2" size-md="3.5" size-xs="3.5" size-sm="3.5" className="label_container_info">
              <IonText className="off_modal_label">
                Description
              </IonText>
            </IonCol>
            <IonCol size-lg="0.5" size-md="0.5" size-xs="0.5" size-sm="0.5">
              <IonText className="off_modal_label">
                :
              </IonText>
            </IonCol>
            <IonCol size-lg="8.5" size-md="7.5" size-xs="7" size-sm="7">
              <IonText className="off_modal_label">
                {offdescription}
              </IonText>
            </IonCol>
          </IonRow>
        </Modal.Body>
      </Modal>
      <CommonModal showModal={Commonmodal} closeModal={modalClose} modaltitle={Commmonmodaltitle} AlertModalMesg={CommonModalMesg} />

    </IonPage >
  );
};
export default Poojaofferings;
