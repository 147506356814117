import {
  IonContent,
  IonPage,
  IonButton,
  IonItemDivider,
  IonRow,
  IonInput,
  IonLabel,
  IonItem,
  useIonRouter,
  IonCol,
  useIonAlert,
  IonLoading,
  IonImg,
  IonText,
  IonIcon
} from "@ionic/react";
import { useForm } from "react-hook-form";
import { Link, useHistory, useLocation, useParams } from "react-router-dom";
import { useState, useEffect, useContext, useRef } from "react";
import "./Home.css";
import UserHead from "./UserHead";
import Foot from "./Foot";
import AuthService from "../Services/auth.service";
import "./Login.css";
import { Modal, ModalHeader } from "react-bootstrap";

// import "@codetrix-studio/capacitor-google-auth";
// import { Plugins } from '@capacitor/core';

import { Capacitor } from "@capacitor/core"
import validator from 'validator'
import Authservice from "../Services/user.service";
import CustomSpin from "../components/customSpin";
import { AppContext } from "../components/AppContext";
import { auth, provider, signInWithPopup } from "../Services/firebase-config";

import { PhoneAuthProvider, RecaptchaVerifier, getAuth, signInWithPhoneNumber } from "firebase/auth";
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css'
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import { closeicon, logo } from "../theme/imagePath";
import { close, eye, eyeOff, home, logoGoogle } from "ionicons/icons";
import HeadFoot from "./Head";
import { fetchUserDetails } from "../components/userInformation";


const Tab2: React.FC = () => {
  const history = useHistory();
  const params: any = useParams()
  const { handleSubmit } = useForm({
    mode: "onTouched",
    reValidateMode: "onChange",
  });
  const [email, setEmail] = useState("");
  const [passemail, setPassemail] = useState("");

  const router = useIonRouter();
  const [userid] = useState(sessionStorage.getItem("UserId"));
  const [usertoken] = useState(sessionStorage.getItem("token"));
  // var userdata: any = sessionStorage.getItem("UserData");
  const { userdata } = useContext(AppContext)!;
  const [showLoading, setShowLoading] = useState(false);
  const [forpassmodal, setforpassmodal] = useState(false)
  const inputRef = useRef<HTMLIonInputElement>(null);
  const errorMessageRef = useRef<HTMLIonTextElement>(null);
  const [errorMessage, setErrorMessage]: any = useState<string>('');
  const [msg, setMsg] = useState("");

  const [password, setPassword] = useState("");
  const mailpat = /[a-zA-Z0-9]+[\.]?([a-zA-Z0-9]+)?[\@][a-z]{3,9}[\.][a-z]{2,5}/g;
  const local: any = useLocation()
  const [path, setPath] = useState(false)
  var token: any = params.token;
  console.log("logintkn" + token)
  const { setUFlag } = useContext(AppContext)!;
  const { setToken } = useContext(AppContext)!;
  const { setUserData } = useContext(AppContext)!;
  const [phone, setPhone] = useState("");
  const [otp, setOtp] = useState("");
  const [confirmationResult, setconfirmationResult]: any = useState();
  const [index, setIndex]: any = useState(0);
  const [showOtp, setShowOtp]: any = useState(false);
  const widthSize: any = window.innerWidth;
  const [isValidPhoneNumber, setIsValidPhoneNumber] = useState(false);
  var load: any = false;

  const [logInfo, setLogInfo]: any = useState("")
  const [showPassword, setShowPassword] = useState(false);




  const [formErrors, setFormErrors] = useState({
    email: '',
    password: '',
    phone: "",
    otp: ""
  });
  const [alert] = useIonAlert();
  const phoneInputRef = useRef(null)
  const moveCursorToEnd = (value: any) => {
    const input: any = phoneInputRef.current;
    if (widthSize <= 500) {
      if (input) {
        setTimeout(() => {
          input.focus();
          input.setSelectionRange(input.value.length, input.value.length);
        }, 0);
      }
    }
    try {
      const phoneNumberObject: any = parsePhoneNumberFromString(value);
      setIsValidPhoneNumber(phoneNumberObject ? phoneNumberObject.isValid() : false);
    } catch (error) {
      setIsValidPhoneNumber(false);
      console.log(error)
    }
    setPhone(value)
  };
  const verifyOTP = () => {
    // var credential = PhoneAuthProvider.credential(confirmationResult.verificationId, otp);
    // console.log(credential);
    const errors: any = {};
    if (otp === undefined || otp === "" || otp == null) {
      errors.otp = "Enter OTP"
    }
    setFormErrors(errors);
    console.log(formErrors)
    if (Object.keys(errors).length === 0) {
      confirmationResult.confirm(otp).then((result: any) => {
        // User signed in successfully.
        const user = result.user;
        console.log(result);
        setShowLoading(true);
        AuthService.mobileLogin(phone)
          .then((response) => {
            if (response.data.responseText.startsWith("SUCCESS")) {
              if (response.data.userModel.uflag === "false") {
                alert("Your account is not active, Please contact Atmagram")
              }
              else {
                console.log(response.data)
                sessionStorage.setItem("token", response.data.userToken);
                sessionStorage.setItem("UserId", response.data.userModel.userId);
                sessionStorage.setItem("uFlag", response.data.userModel.uflag);
                sessionStorage.setItem("UserData", response.data.userModel.role);
                sessionStorage.setItem("Email", response.data.userModel.email);
                sessionStorage.setItem("Phone", response.data.userModel.mobileNumber);
                setUFlag(response.data.userModel.uflag)
                setToken(response.data.userToken)
                setUserData(response.data.userModel.role)
                if (response.data.userModel.address !== null) {
                  sessionStorage.setItem("Address", response.data.userModel.address);
                }
                if (response.data.userModel.firstName !== null) {
                  sessionStorage.setItem("Name", response.data.userModel.firstName);
                }
                if (response.data.userModel.lastName !== null) {
                  sessionStorage.setItem("lastName", response.data.userModel.lastName);
                }
                var usrRole = response.data.userModel.role;
                history.replace("/")

              }
              var usrToken = response.data.userToken;
              if (token != null && token != undefined && token != "") {
                Authservice.VerifyMobileChange(token, usrToken).then((response) => {
                  if (response.data.startsWith("SUCCESS")) {
                    alert(response.data.split('SUCCESS:')[1]);
                    token = null;
                    history.push("/profile")
                    return;
                  } else {
                    alert(response.data.split('ERROR:')[1]);
                    token = null;
                    history.replace("/")
                    return;
                  }
                  console.log(response + "mobRes")
                })
                  .catch((error) => {
                    console.log(error + "mobErr")
                  });
              } else {
                // if (Capacitor.getPlatform() === "web") {
                //   window.location.reload();
                // } else {
                //   history.goBack();

                // }


              }


            } else {
              alert(response.data.responseText.split("ERROR:")[1]);
            }
            setShowLoading(false);
          })
          .catch((error) => {
            console.log(error);
            setShowLoading(false);
          });

        // ...
      }).catch((err: any) => {
        // User couldn't sign in (bad verification code?)
        console.log(err.error);
        alert("Not a valid verification code or Code expired, please try again")


        // if(error.error.message=='SESSION_EXPIRED'){
        //   alert("OTP expired");
        // }else{

        // }
        // ...
      });
    }


  }
  const checkMobile = () => {
    const errors: any = {};
    if (phone === undefined || phone === "") {
      errors.phone = "Mobile Number is required"
    } else if (!isValidPhoneNumber) {
      errors.phone = "Enter valid Mobile Number"
    }
    setFormErrors(errors);
    console.log(formErrors)
    if (Object.keys(errors).length === 0) {
      setShowLoading(true);
      AuthService.validMob(phone).then((res) => {
        setShowLoading(false);
        if (res.data) {
          getOTP();
        } else {
          alert("Please complete registration. Then only you can login with Atmagram")
        }

      }).catch((error) => {
        console.log(error);
        setShowLoading(false);
      });
    }
  }

  const getOTP = () => {
    let verify = new RecaptchaVerifier('recaptcha-container', {
      'size': 'normal',
      'callback': (response: any) => {
        // reCAPTCHA solved, allow signInWithPhoneNumber.
        // ...
      },
      'expired-callback': () => {
        // Response expired. Ask user to solve reCAPTCHA again.
        // ...
        alert("Response expired. Please solve reCAPTCHA again")
      }
    }, auth);

    signInWithPhoneNumber(auth, phone, verify)
      .then((confirmationResult) => {
        // SMS sent. Prompt user to type the code from the message, then sign the
        // user in with confirmationResult.confirm(code).
        console.log(confirmationResult);
        setShowOtp(true)
        setconfirmationResult(confirmationResult)
        // ...
      }).catch((error) => {
        // Error; SMS not sent
        console.log("mob error" + error)
        alert("Something went wrong, please try again later.")
        // ...
      });
  }

  const fetchDetails = async () => {
    const details = await fetchUserDetails();
    console.log(JSON.stringify(details));
    setLogInfo(JSON.stringify(details));
  };

  useEffect(() => {
    fetchDetails();
  }, []);

  useEffect(() => {
    if (!load) {
      if (userdata != null) {
        //history.goForward();
        // history.replace("/")
        sessionStorage.clear()
      }
      load = true;
    }

    console.log(local.state)
  }, []);

  const [alertmodal, setAlertmodal] = useState(false)
  const [alertdata, setAlertdata] = useState("")
  const ForgetSubmit = () => {
    setShowLoading(true);
    AuthService.Forgotpassword(passemail).then((response) => {

      if (response.data.startsWith("SUCCESS")) {
        console.log(response.data)
        var res = response.data.split('SUCCESS:')[1];
        alertmodalaction(true, "Reset password link sent to your Email Id!")

        setShowLoading(false);

      } else {
        setShowLoading(false);
        alertmodalaction(true, "Account Not Found")
      }

    })
      .catch((error) => {
        console.log(error);
        setShowLoading(false);
      });
  };

  const alertmodalaction = (e: any, data: any) => {
    setAlertmodal(e)
    setAlertdata(data)
  }

  const alertmodalclose = () => {
    if (alertdata === "Reset password link sent to your Email Id!") {
      window.location.reload()
    }
    else {
      setAlertmodal(false)
      setAlertdata("")
    }
  }

  const handleBlur = () => {
    const inputValue: any = inputRef.current?.value;
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (inputValue === "") {
      setErrorMessage('Please enter a email address');
      errorMessageRef.current?.setAttribute('aria-errormessage', 'email-error');
    }
    else if (!emailPattern.test(inputValue!)) {
      setErrorMessage('Please enter a valid email address');
      errorMessageRef.current?.setAttribute('aria-errormessage', 'email-error');
    } else {
      setErrorMessage('');
      errorMessageRef.current?.removeAttribute('aria-errormessage');
    }
  };
  const Submit = () => {
    const errors: any = {};

    if (email.trim() === '') {
      errors.email = 'Email is required';
    }
    else if (validator.isEmail(email) === false) {
      errors.email = "Enter Valid Email Address";
    }

    if (password.trim() === '') {
      errors.password = 'Password is required';
    }

    setFormErrors(errors);

    if (Object.keys(errors).length === 0) {
      setShowLoading(true);
      AuthService.Login(email, password, logInfo)
        .then((response) => {
          console.log(response)
          if (response.data.responseText.startsWith("SUCCESS")) {
            if (response.data.userModel.uflag === "false") {
              alert("Your account is not active, Please contact Atmagram")
            }
            else {
              var usrRole = response.data.userModel.role;

              if (usrRole === "ROLE_ADMIN") {
                history.push("/")
                // history.push("/admin_page")
              }
              // else if (usrRole === "ROLE_TEMPADMIN") {
              //   history.push("/tempadmin")
              // }
              else if (usrRole === "ROLE_VENDOR") {
                history.push("/")
              }
              // else if (usrRole === "ROLE_PROJECTADMIN") {
              //   history.push("/projectadmin")
              // }
              else if (usrRole === "ROLE_USER" || usrRole === "ROLE_VOLUNTEER" || usrRole === "ROLE_FINANCE") {
                history.replace("/")
              }
              if (Capacitor.getPlatform() === "web") {
                console.log(response.data)
                setUFlag(response.data.userModel.uflag)
                setToken(response.data.userToken)
                setUserData(response.data.userModel.role)
                sessionStorage.setItem("token", response.data.userToken);
                // sessionStorage.setItem("UserId", response.data.userModel.id);
                sessionStorage.setItem("UserId", response.data.userModel.userId);
                sessionStorage.setItem("uFlag", response.data.userModel.uflag);
                sessionStorage.setItem("UserData", response.data.userModel.role);
                sessionStorage.setItem("Email", response.data.userModel.email);
                sessionStorage.setItem("Phone", response.data.userModel.mobileNumber);
                if (response.data.userModel.address !== null) {
                  sessionStorage.setItem("Address", response.data.userModel.address);
                }
                if (response.data.userModel.firstName !== null) {
                  sessionStorage.setItem("Name", response.data.userModel.firstName);
                }
                if (response.data.userModel.lastName !== null) {
                  sessionStorage.setItem("lastName", response.data.userModel.lastName);
                }
              }
              else {
                if (response.data.userModel.role === "ROLE_TEMPADMIN" || response.data.userModel.role === "ROLE_VENDOR" || response.data.userModel.role === "ROLE_USER") {
                  sessionStorage.setItem("token", response.data.userToken);
                  sessionStorage.setItem("UserId", response.data.userModel.id);
                  sessionStorage.setItem("UserData", response.data.userModel.role);
                  sessionStorage.setItem("uFlag", response.data.userModel.uflag);
                  sessionStorage.setItem("Email", response.data.userModel.email);
                  setUFlag(response.data.userModel.uflag)
                  setToken(response.data.userToken)
                  setUserData(response.data.userModel.role)
                  sessionStorage.setItem("Phone", response.data.userModel.mobileNumber);
                  if (response.data.userModel.address !== null) {
                    sessionStorage.setItem("Address", response.data.userModel.address);
                  }
                  if (response.data.userModel.firstName !== null) {
                    sessionStorage.setItem("Name", response.data.userModel.firstName);
                  }
                  if (response.data.userModel.lastName !== null) {
                    sessionStorage.setItem("lastName", response.data.userModel.lastName);
                  }
                  // alert("Login Successful");
                  console.log(response.data.userModel.role);
                  //history.push("/home");
                  //window.location.reload();
                } else {
                  alert("User Not Authorized For Mobile App. Please visit Web Application")
                }
              }
              var usrToken = response.data.userToken;

              if (token != null && token != undefined && token != "") {
                Authservice.VerifyMobileChange(token, usrToken).then((response) => {
                  if (response.data.startsWith("SUCCESS")) {
                    alert(response.data.split('SUCCESS:')[1]);
                    token = null;

                    history.push("/profile")
                    return;

                  } else {
                    alert(response.data.split('ERROR:')[1]);
                    token = null;

                    history.replace("/")
                    return;


                  }
                  console.log(response + "mobRes")
                })
                  .catch((error) => {
                    console.log(error + "mobErr")
                  });
              } else {
                // if (Capacitor.getPlatform() === "web") {
                // window.location.reload();
                // } else {
                //   history.goBack();

                // }

              }
            }
          } else {
            alert(response.data.responseText.split("ERROR:")[1]);
          }
          setShowLoading(false);
        })
        .catch((error) => {
          console.log(error);
          setShowLoading(false);
          // alert("Something went wrong, please try again later.");
        });
    }
  };

  const Validation = (label: any) => {

    if (label === "email") {
      if (email.trim() === '') {
        setFormErrors((prev: any) => {
          return { ...prev, email: 'Email is required' }
        })
      }
      else if (validator.isEmail(email) === false) {
        setFormErrors((prev: any) => {
          return { ...prev, email: "Enter Valid Email Address" }
        })
      }
      else {
        setFormErrors((prev: any) => {
          return { ...prev, email: "" }
        })
      }
    }

    if (label === "password") {
      if (password.trim() === '') {
        setFormErrors((prev: any) => {
          return { ...prev, password: 'Password is required' }
        })
        // errors.password = 'Password is required';
      }
      else {
        setFormErrors((prev: any) => {
          return { ...prev, password: "" }
        })
      }
    }

    if (label === "phone") {
      if (phone === undefined || phone === "") {
        setFormErrors((prev: any) => {
          return { ...prev, phone: "Mobile Number is required" }
        })
      } else if (!isValidPhoneNumber) {
        setFormErrors((prev: any) => {
          return { ...prev, phone: "Enter valid Mobile Number" }
        })
      }
      else {
        setFormErrors((prev: any) => {
          return { ...prev, phone: "" }
        })
      }
    }

    if (label === "otp") {
      if (otp === undefined || otp === "" || otp == null) {
        setFormErrors((prev: any) => {
          return { ...prev, otp: "Enter OTP" }
        })
      }
      else {
        setFormErrors((prev: any) => {
          return { ...prev, otp: "" }
        })
      }
    }

    // const error = Validation(label,value);
    // setFormErrors((prevErrors) => ({ ...prevErrors, [label.toLowerCase()]: error }));
  }
  const ChangeValidation = (label: any, e: any) => {

    if (label === "email") {
      const email = e.target.value;
      if (email.trim() === '') {
        setFormErrors((prev: any) => {
          return { ...prev, email: 'Email is required' }
        })
      }
      else if (validator.isEmail(email) === false) {
        setFormErrors((prev: any) => {
          return { ...prev, email: "Enter Valid Email Address" }
        })
      }
      else {
        setFormErrors((prev: any) => {
          return { ...prev, email: "" }
        })
      }
    }

    if (label === "password") {
      const password = e.target.value;
      if (password.trim() === '') {
        setFormErrors((prev: any) => {
          return { ...prev, password: 'Password is required' }
        })
        // errors.password = 'Password is required';
      }
      else {
        setFormErrors((prev: any) => {
          return { ...prev, password: "" }
        })
      }
    }

    if (label === "phone") {
      const phone = e;

      let valid: any = false
      try {
        const phoneNumberObject: any = parsePhoneNumberFromString(e);
        valid = phoneNumberObject ? phoneNumberObject.isValid() : false;
      } catch (error) {
        valid = false;
        console.log(error)
      }

      if (phone === undefined || phone === "") {
        setFormErrors((prev: any) => {
          return { ...prev, phone: "Mobile Number is required" }
        })
      } else if (!valid) {
        setFormErrors((prev: any) => {
          return { ...prev, phone: "Enter valid Mobile Number" }
        })
      }
      else {
        setFormErrors((prev: any) => {
          return { ...prev, phone: "" }
        })
      }
    }

    if (label === "otp") {
      const otp = e.target.value;
      if (otp === undefined || otp === "" || otp == null) {
        setFormErrors((prev: any) => {
          return { ...prev, otp: "Enter OTP" }
        })
      }
      else {
        setFormErrors((prev: any) => {
          return { ...prev, otp: "" }
        })
      }
    }

    // const error = Validation(label,value);
    // setFormErrors((prevErrors) => ({ ...prevErrors, [label.toLowerCase()]: error }));
  }

  const SearchF = (value: any) => {
    if (value == "Enter") {
      Submit()
    }
  }
  const phoneLogin = () => {
    setIndex(1)

  }
  const emailLogin = () => {
    setIndex(0)
  }
  const goBack = () => {
    setShowOtp(false)
  }
  // }
  //   const signIn  = ()=> {

  //     const result =  Plugins.GoogleAuth.signIn();
  //     console.info('result', result);
  //     if (result) {
  //       // history.push({
  //       //   pathname: '/home',
  //       //   state: { name: result.name || result.displayName, image: result.imageUrl, email: result.email }
  //       // });
  //       console.log("name"+ result.name+"displayName"+result.displayName)
  //     }

  // googleLogin();

  //   }

  const handleGoogleSignUp = async () => {
    try {
      const result = await signInWithPopup(auth, provider);
      console.log("User Info:", result.user);
      alert(`Welcome, ${result.user.displayName}`);
    } catch (error) {
      console.error("Google Sign-In Error:", error);
    }
  };


  return (
    <IonPage>
      <IonContent>
        <div className="head_login">
          <HeadFoot User={"login"} Role="Role" Name="Home,Login" />
        </div>


        <IonLoading message="Loading..." spinner={null}
          isOpen={showLoading}
        // onDidDismiss={() => setShowLoading(false)}
        // duration={5000}
        />
        {/* <UserHead Role={userdata[0]} User="login" /> */}
        <Modal show={forpassmodal} className="profile-modal">
          <ModalHeader className="profilenodal-Header">
            <h2 className="profileModal-title">Forgot Password</h2>
            <div>

              <IonIcon src={close} className="close_icon" onClick={() => setforpassmodal(false)} />
            </div>
          </ModalHeader>
          <Modal.Body>

            <IonRow>
              <IonCol size="12">
                <IonLabel className="form-labels">
                  Email
                </IonLabel>
                <IonInput
                  type="email"
                  value={passemail}
                  required
                  placeholder="Enter registered email-id"
                  onIonChange={(e) => setPassemail(e.detail.value!)}
                  ref={inputRef}
                  onBlur={handleBlur}
                  className="profile-inputs"
                  aria-errormessage="email-error"
                ></IonInput>
                {errorMessage && <span className="alert_input_bottom " ref={errorMessageRef} id="email-error" color="danger">{errorMessage}</span>}
              </IonCol>
            </IonRow>
            <div className={passemail.length == 0 || validator.isEmail(passemail) === false ? "disableCursor" : ""} style={{ width: "fit-content" }}>
              <IonButton disabled={passemail.length == 0 || validator.isEmail(passemail) === false} onClick={() => ForgetSubmit()}>
                <span className="button_padding">Submit</span>
              </IonButton>
            </div>
          </Modal.Body>
        </Modal>
        <Modal show={alertmodal} className="login_alert_modal" centered>
          <Modal.Body>
            <div style={{ textAlign: "center" }}>
              <h5 className="login_alert_title">Atmagram</h5>
            </div>
            <div style={{ textAlign: "center" }}>
              <IonText>{alertdata}</IonText>
            </div>
            <div style={{ textAlign: "right", paddingTop: "15px" }}>
              <IonText style={{ cursor: "pointer" }} onClick={() => alertmodalclose()}>OK</IonText>
            </div>
          </Modal.Body>
        </Modal>
        <IonRow className="page_container">
          <IonCol className="img_container" sizeLg="7.5" sizeMd="0" sizeXl="7.5" sizeSm="0" size="7.5" sizeXs="0" >
            <div className="tool_img" >
              <div className="logo_login">
                <Link to="/" style={{ textDecoration: "none" }}>
                  <IonImg className="logo_class" src={logo} ></IonImg>
                </Link></div>
            </div>
          </IonCol>
          <IonCol sizeLg="4.5" sizeMd="12" sizeXl="4.5" sizeSm="12" size="4.5" sizeXs="12">
            <div className="register_login_title">
              <Link to="/" >
                <IonIcon src={home} className="home_icon">
                </IonIcon>
              </Link>
            </div>
            <div className="login_top">
              {index == 0 ?
                <div className="login_container">
                  <form onSubmit={handleSubmit(Submit)} onKeyPress={e => SearchF(e.key)}>
                    <div>
                      <span className="login_head">Login</span>
                      <IonRow  >

                        <IonCol size="11" size-lg="7" className="email_container">
                          <div>

                            <IonLabel className="label_align">
                              E-mail <span style={{ color: "red" }}>*</span>
                            </IonLabel>

                            <IonInput className="select_user"
                              type="text"
                              style={{ textTransform: "none" }}
                              value={email} placeholder="Enter Email-id"
                              onIonChange={(e) => {
                                setEmail(e.detail.value!);
                                ChangeValidation("email", e)
                              }}
                              onBlur={(e) => Validation("email")}
                            // required
                            ></IonInput>
                            {/* <span slot="error">Name is required</span> */}

                          </div>
                          {formErrors.email && <span className="alert_input_bottom ">{formErrors.email}</span>}
                        </IonCol>
                      </IonRow>
                      <IonRow >

                        <IonCol size="11" className="pass_container" style={{ position: "relative" }}>
                          <div style={{ width: "100%" }}>
                            <IonLabel className="label_align">
                              Password <span style={{ color: "red" }}>*</span>
                            </IonLabel>
                            <IonInput
                              type={showPassword ? "text" : "password"}
                              style={{ textTransform: "none" }}
                              clearOnEdit={false} className="select_user" placeholder="Enter password"
                              onIonChange={(e) => { setPassword(e.detail.value!); ChangeValidation("password", e) }}
                              // required
                              onBlur={(e) => Validation("password")}
                            ></IonInput>
                            <div
                              className="eye_icon"
                              onClick={() => setShowPassword(!showPassword)}
                              style={{
                                position: "absolute",
                                right: "0px",
                                top: "50%",
                                cursor: "pointer",
                                zIndex: 1,
                              }}
                            >
                              <IonIcon icon={showPassword ? eyeOff : eye} />
                            </div>
                          </div>
                          {formErrors.password && <span className="alert_input_bottom ">{formErrors.password}</span>}
                        </IonCol>
                      </IonRow>
                      <IonRow>
                        <IonCol size="6" size-lg="6" className="pass_container" >
                          <div>
                            <Link to="/signup" className="forgot_pass_css">Don't have account?</Link>
                          </div>
                        </IonCol>
                        <IonCol size="6" size-lg="6" className="pass_container" >
                          <div style={{ textAlign: "right" }}>
                            <IonText onClick={() => setforpassmodal(true)} className="forgot_pass_css">Forgot Password?</IonText>
                          </div>
                        </IonCol>
                      </IonRow>
                      <IonRow className="login_btn_container">
                        <div className={email.length === 0 || password.length === 0 ? "disableCursor" : ""}>
                          <IonButton
                            type="submit"
                            disabled={email.length === 0 || password.length === 0}
                          >
                            <span className="button_padding"> Login</span>

                          </IonButton>
                        </div>
                      </IonRow>
                      <div className="center or_text_container">
                        <p>
                          or
                        </p>
                      </div>
                    </div>
                  </form>
                  <IonRow className="login_btn_container">
                    <IonButton onClick={phoneLogin}>
                      <span className="button_padding">  Login With Phone</span>
                    </IonButton>
                  </IonRow>
                  {/* <div style={{ width: "100%", justifyContent: "center", display: "flex", marginTop: "20px" }}>
                    <div className="google-signup" onClick={handleGoogleSignUp}>
                      <IonIcon icon={logoGoogle} className="google-icon" />
                      <span>Sign Up with Google</span>
                    </div>
                  </div> */}

                </div>
                : <div className="login_withmobile_container">
                  <span className="login_head">Login</span>
                  {!showOtp ? <>
                    <IonRow>
                      <IonCol size="11" size-lg="7" className="email_container">
                        <div>
                          <IonLabel className="label_align">
                            Mobile Number <span style={{ color: "red" }}>*</span>
                          </IonLabel>
                          <PhoneInput
                            international
                            defaultCountry="IN"
                            value={phone}
                            className="select_user"
                            ref={phoneInputRef}
                            // flags=false
                            onBlur={(e) => Validation("phone")}
                            onChange={(value: any) => { moveCursorToEnd(value); ChangeValidation("phone", value) }}
                          />

                        </div>
                        {formErrors.phone && <span className="alert_input_bottom ">{formErrors.phone}</span>}
                      </IonCol>
                    </IonRow>

                    <IonRow>
                      <IonCol size="11" size-lg="7" className="pass_container">
                        <div id="recaptcha-container"></div>
                      </IonCol>
                    </IonRow>
                    <IonRow className="login_btn_container">
                      <div className={phone === undefined || phone === "" || !isValidPhoneNumber ? "cursor_block" : ""}>
                        <IonButton
                          onClick={checkMobile}
                          disabled={phone === undefined || phone === "" || !isValidPhoneNumber}
                        >
                          <span className="button_padding"> Get OTP</span>
                        </IonButton>
                      </div>
                    </IonRow>
                  </> : <></>}

                  {showOtp ?
                    <>
                      <IonRow>
                        <IonCol size="11" size-lg="7" className="email_container">

                          <IonLabel className="label_align">
                            Enter OTP <span style={{ color: "red" }}>*</span>
                          </IonLabel>
                          <IonInput className="select_user"
                            onBlur={(e) => Validation("otp")}
                            onIonChange={(e) => { setOtp(e.detail.value!); ChangeValidation("otp", e) }}
                            required
                          ></IonInput>

                          {formErrors.otp && <span className="alert_input_bottom ">{formErrors.otp}</span>}
                        </IonCol>
                      </IonRow>

                      <IonRow>
                        <IonCol size="11" size-lg="7" className="pass_container" >
                          <span className="go_back_style"
                            onClick={goBack}
                          >go back
                          </span>
                        </IonCol>
                      </IonRow>

                      <IonRow className="login_btn_container">
                        <IonButton onClick={verifyOTP}>
                          <span className="button_padding">Verify OTP</span>
                        </IonButton>
                      </IonRow>
                    </> : <></>
                  }

                  <div className="center or_text_container"><p>
                    or</p></div>
                  <IonRow className="login_btn_container">
                    <IonButton onClick={emailLogin}>
                      <span className="button_padding"> Login With Email</span>
                    </IonButton>
                  </IonRow>
                </div>

              }


            </div>

            {/* {Capacitor.getPlatform() === "web"? */}

            {/* :<></>}  */}
          </IonCol>
        </IonRow>
        {/* <Foot /> */}
      </IonContent>
    </IonPage>
  );
};

export default Tab2;