export const logo = process.env.PUBLIC_URL + '/images/newlogo.png';
export const login_image = process.env.PUBLIC_URL + '/images/shankaracharya.png';
export const home_banner = process.env.PUBLIC_URL + '/assets/logo.png';
export const home_image = process.env.PUBLIC_URL + '/assets/Homepage.png';
export const spinner = process.env.PUBLIC_URL + '/assets/spinner.png';
export const splash = process.env.PUBLIC_URL + '/assets/splash.png';
export const splash_dark = process.env.PUBLIC_URL + '/assets/splash-dark.png';
export const headerAfterLogin = process.env.PUBLIC_URL + '/images/subheader.png';
export const BudhaImg = process.env.PUBLIC_URL + '/images/budha.png';
export const temple = process.env.PUBLIC_URL + '/images/temple.jpeg';
export const donation = process.env.PUBLIC_URL + '/images/temple.png';
export const offeringlogo = process.env.PUBLIC_URL + '/images/offeringlogo.png';
export const materialslogo = process.env.PUBLIC_URL + '/images/materialslogo.png';
export const grammercelogo = process.env.PUBLIC_URL + '/images/grammercelogo.png';
export const projectlogo = process.env.PUBLIC_URL + '/images/projectlogo.png';
export const infoicon = process.env.PUBLIC_URL + '/images/infoicon.png';
export const closeicon = process.env.PUBLIC_URL + '/images/closeicon.png';
export const tick = process.env.PUBLIC_URL + '/images/tick.png';
export const temp_login = process.env.PUBLIC_URL + '/images/temp_login.png';
export const shareIcon = process.env.PUBLIC_URL + '/images/share.png';
export const joinhand = process.env.PUBLIC_URL + '/images/joinhands.png';
export const heart1 = process.env.PUBLIC_URL + '/images/heart-1.png';
export const leftArrow = process.env.PUBLIC_URL + '/images/left-direction-1.png';
export const rightArrow = process.env.PUBLIC_URL + '/images/right-angles-1.png';
export const heart2 = process.env.PUBLIC_URL + '/images/heart-2.png';
export const clock1 = process.env.PUBLIC_URL + '/images/clock-1.png';
export const profile_icon = process.env.PUBLIC_URL + '/images/profile.png';
export const aboutusimg1 = process.env.PUBLIC_URL + '/images/aboutusimg1.png';
export const aboutusimg2 = process.env.PUBLIC_URL + '/images/aboutusimg2.png';
export const edit_icon = process.env.PUBLIC_URL + "/images/edit.png";
export const location1 = process.env.PUBLIC_URL + '/images/location-1.png';
export const sankara = process.env.PUBLIC_URL + '/images/sankara.jpeg';
export const temple_home_card = process.env.PUBLIC_URL + '/assets/temple_home_card.jpg';
export const pooja_home_card = process.env.PUBLIC_URL + '/assets/pooja_home_card.jpg';
export const donation_home_card = process.env.PUBLIC_URL + '/assets/donation_home_card.jpg';
export const newBgImg = process.env.PUBLIC_URL + '/images/atmagram_new.png';
export const usermanualPdf = process.env.PUBLIC_URL + '/images/Responsibilities_of_CoOrdinators.pdf';
export const termsandconditions = process.env.PUBLIC_URL + '/images/termsandconditions.pdf';
export const privacypolicy = process.env.PUBLIC_URL + '/images/privacypolicy.pdf';



export const imagePathForRecipt = `https://www.atmagram.com/vedaresources`;

